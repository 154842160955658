import React, { useCallback, useEffect } from 'react';
import HowToApplyPage from './HowToApplyPage';
import ContactDetails from './ContactDetails';
import SubmitPage from './SubmitPage';
import FinancePage from './FinancePage';
import Employment from './Employment';
import AccommodationPage from './AccommodationPage';
import PurposeOfLoanPage from './PurposeOfLoanPage';
import CoApplicantPage from './CoApplicantPage';
import VerifyIncomePage from './VerifyIncomePage';
import Authenticator from '../../components/Authenticator/Authenticator';
import CoApplicantLivingConditionPage from './CoApplicantLivingConditionPage';
import CoApplicantAccommodationPage from './CoApplicantAccommodationPage';
import CoApplicantEmployment from './CoApplicantEmployment';
import LivingConditionPage from './LivingConditionPage';
import LoanAmountPage from './LoanAmountPage';
import { StatusProcessingPage } from '@resursbank/react-form-service';
import ApplicationStyleWrapper from '../components/ApplicationStyleWrapper/ApplicationStyleWrapper';
import useTranslation from '../hooks/useTranslation';
import useFieldState from '../hooks/useFieldState';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectPageIndex, setSectionCount, setSelectedPage } from '../../store/wizard/wizard';
import WizardHeader from '../components/Wizard/WizardHeader';
import WizardFooter from '../components/Wizard/WizardFooter';
import PepPage from './PepPage';
import Wizard from '../components/Wizard/Wizard';

const ApplicationPage = () => {
    const dispatch = useAppDispatch();
    const translate = useTranslation();
    const withCoApplicant = useFieldState('coapplicantToggle')?.value === 'true';
    const startPageIndex = useAppSelector(selectPageIndex);

    useEffect(() => {
        dispatch(setSectionCount(6));
    }, [dispatch]);

    const onStepChange = useCallback((stepIndex: number) => dispatch(setSelectedPage(stepIndex)), [dispatch]);

    return (
        <ApplicationStyleWrapper>
            <Wizard
                header={<WizardHeader />}
                footer={<WizardFooter />}
                onStepChange={onStepChange}
                startIndex={startPageIndex}>
                <HowToApplyPage />
                <ContactDetails />
                <LivingConditionPage />
                <AccommodationPage />
                <Employment />
                <VerifyIncomePage />
                <FinancePage />
                <Authenticator autoLogin={true} fetchLoginForm={false}>
                    <PepPage />
                </Authenticator>
                <PurposeOfLoanPage />
                <CoApplicantPage />
                {withCoApplicant && <CoApplicantLivingConditionPage />}
                {withCoApplicant && <CoApplicantEmployment />}
                {withCoApplicant && <CoApplicantAccommodationPage />}
                <Authenticator autoLogin={true} fetchLoginForm={false}>
                    <LoanAmountPage />
                </Authenticator>
                <Authenticator autoLogin={true} fetchLoginForm={false}>
                    <SubmitPage />
                </Authenticator>
                <StatusProcessingPage
                    title={translate(`page.status.processing.title`)}
                    body={translate(`page.status.processing.description`)}
                />
            </Wizard>
        </ApplicationStyleWrapper>
    );
};

export default ApplicationPage;
