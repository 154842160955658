import styled from 'styled-components';

export const Footer = styled.div`
    position: absolute;
    bottom: -236px;
    width: 100%;
`;

export const HiddenVersion = styled.span`
    font-size: 10px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: transparent;
`;
