import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext, useState, useEffect } from 'react';
import { ReactFormServiceContext } from '@resursbank/react-form-service';
import { useMediaQuery, useTheme } from '@mui/material';
import { DialogActionStyles, DialogButton, Link, smallScreenSX } from './OngoingApplicationDialog.styles';

type Props = {
    url: string | undefined;
};
const OngoingApplicationDialog = ({ url }: Props) => {
    const [open, setOpen] = useState(false);
    const { translate } = useContext(ReactFormServiceContext);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (url) {
            setOpen(true);
        }
    }, [url]);

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                sx={isSmallScreen ? smallScreenSX : undefined}>
                <DialogTitle id="alert-dialog-title">{translate(`page.application.dialog.title`)}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {translate(`page.application.dialog.content`)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={DialogActionStyles}>
                    <Button onClick={handleClose} autoFocus sx={DialogButton}>
                        <Link href={url}> {translate(`page.application.dialog.button`)}</Link>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default OngoingApplicationDialog;
