import { SxProps, Theme } from '@mui/material';

interface TypographyStyleProps {
    currentPage: number;
    index: number;
}

export const TypographyStyle: SxProps<Theme> = (theme) => ({
    flex: 1,
    whiteSpace: 'nowrap',
    fontSize: '14px',
    color: theme.palette.primary.main,
    fontWeight: '700',
    textAlign: 'center',
});

export const ProgressBarStyles =
    ({ currentPage, index }: TypographyStyleProps): SxProps<Theme> =>
    (theme) => ({
        flex: 1,
        height: 8,
        backgroundColor: currentPage >= index ? theme.palette.primary.main : theme.palette.grey['300'],
        [':first-of-type']: {
            borderTopLeftRadius: '30px',
            borderBottomLeftRadius: '30px',
        },
        [':last-of-type']: {
            borderTopRightRadius: '30px',
            borderBottomRightRadius: '30px',
        },
    });
