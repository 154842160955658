import React from 'react';
import { Box, Link, useTheme } from '@mui/material';
import styles from './Calculator.styles';
import useTranslation from '../../hooks/useTranslation';

interface DesktopActionsProps {
    onClose: () => void;
    onUpdate: () => void;
    block: boolean;
}

const DesktopActions: React.FC<DesktopActionsProps> = ({ onClose, onUpdate, block }) => {
    const theme = useTheme();
    const translate = useTranslation();

    return (
        <Box>
            <hr style={{ margin: '0' }} />
            <Box sx={styles(theme).desktopBox}>
                <Link
                    component="button"
                    variant="body2"
                    underline="none"
                    onClick={onClose}
                    sx={styles(theme).desktopCancelLink}>
                    {translate('page.calculator.button.cancel')}
                </Link>
                <Link
                    component="button"
                    variant="body2"
                    underline="none"
                    onClick={onUpdate}
                    disabled={block}
                    sx={styles(theme).desktopUpdateLink}>
                    {translate('page.calculator.button.update')}
                </Link>
            </Box>
        </Box>
    );
};

export default DesktopActions;
