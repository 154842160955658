import React from 'react';
import TextInputField from '../TextInputField/TextInputField';

type MonetaryFieldProps = {
    name: string;
    label?: string;
    required?: boolean;
    disabled?: boolean;
};

const formatMonetaryByThousands = (monetary: string | undefined): string | undefined => {
    return monetary?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const cleanValue = (value?: string): string => {
    const amountValue = value?.replace(/^(-)|[^0-9]+/g, '$1');
    if (!amountValue || amountValue === '') {
        return '';
    }
    const maxLength = 12;
    const sign = amountValue.startsWith('-') ? '-' : '';
    let amount = amountValue.replace(sign, '');

    if (amount.length > 0) {
        amount = '' + +amount;
    }
    if (amount.length > maxLength) {
        amount = amount.substring(0, maxLength);
    }

    return sign + amount;
};

const MonetaryField = (props: MonetaryFieldProps) => {
    return (
        <TextInputField
            type="tel"
            label={props.label}
            disabled={props.disabled}
            validation={{ required: props.required, requiredErrorKey: 'validation.error.monetary.required' }}
            formatting={{ format: formatMonetaryByThousands, clean: cleanValue }}
            {...props}
        />
    );
};

export default MonetaryField;
