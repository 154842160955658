import React from 'react';
import TextInputField from '../TextInputField/TextInputField';
import useSettings from '../../../hooks/useSettings';
import { FieldValidationProps, ValidationResultType } from '../../hooks/useFieldValidation';

type PhoneFieldProps = {
    name: string;
    label?: string;
    required?: boolean;
};

const formatMap = new Map([
    ['SE', '^\\d{10}$'],
    ['NO', '^\\d{8}$'],
    ['DK', '^\\d{8}$'],
    ['FI', '^\\d{10}$'],
]);

const formatErrorKeyMap = new Map([
    ['SE', 'validation.error.phone.format.se'],
    ['NO', 'validation.error.phone.format.no'],
    ['DK', 'validation.error.phone.format.dk'],
    ['FI', 'validation.error.phone.format.fi'],
]);

const PhoneField = (props: PhoneFieldProps) => {
    const { countryCode } = useSettings();

    const cleanPhoneNumber = (phoneNumber: string | undefined): string | undefined => {
        return phoneNumber?.replace(/[^0-9\-\+]/g, '');
    };

    const formatPhoneNumber = (phoneNumber: string | undefined): string | undefined => {
        if (!phoneNumber) return undefined;

        switch (countryCode) {
            case 'SE':
                return phoneNumber.length === 10
                    ? phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')
                    : phoneNumber;
            case 'NO':
                return phoneNumber.length === 8
                    ? phoneNumber.replace(/(\d)(\d{3})(\d{4})/, '+47 $1 $2 $3')
                    : phoneNumber;
            case 'DK':
                return phoneNumber.length === 8
                    ? phoneNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, '+45 $1 $2 $3 $4')
                    : phoneNumber;
            case 'FI':
                return phoneNumber.length === 10
                    ? phoneNumber.replace(/(\d{2})(\d{3})(\d{5})/, '+358 $1 $2 $3')
                    : phoneNumber;
            default:
                return phoneNumber;
        }
    };

    const phoneValidationService = (value?: string): Promise<ValidationResultType> => {
        const formattedValue = formatPhoneNumber(value);
        const isValid = formattedValue !== undefined;

        return Promise.resolve({
            valid: isValid,
            errorMessageKey: isValid ? undefined : formatErrorKeyMap.get(countryCode.toUpperCase()),
        });
    };

    const validationProps = (): FieldValidationProps => ({
        required: props.required,
        requiredErrorKey: 'validation.error.phone.required',
        format: formatMap.get(countryCode.toUpperCase()),
        formatErrorKey: formatErrorKeyMap.get(countryCode.toUpperCase()),
        customValidation: phoneValidationService,
    });

    return (
        <TextInputField
            type="tel"
            validation={validationProps()}
            formatting={{
                format: formatPhoneNumber,
                clean: cleanPhoneNumber,
            }}
            {...props}
        />
    );
};

export default PhoneField;
