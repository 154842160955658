import React, { useLayoutEffect, useRef, useState } from 'react';
import { StyledTextField } from '@resursbank/mui';
import { StyledTextFieldProps } from '@resursbank/mui/components/StyledTextField/StyledTextField';
import { ChangeEvent } from 'react';
import { FieldValidationProps } from '../../hooks/useFieldValidation';
import useFieldFormatting, { FieldFormattingProps } from '../../hooks/useFieldFormatting';
import ValidationError from '../ValidationError/ValidationError';
import { selectFieldStateByName } from '../../../store/applicationform/applicationFormSelectors';
import InputField from '../InputField/InputField';
import { useAppSelector } from '../../../hooks';

export type TextInputFieldProps = {
    /**
     * The name of the input field which will be used to for storing the value in the store
     */
    name: string;

    /**
     * An optional label which will also be displayed as a placeholder
     */
    label?: string;

    /**
     * When the text is changed this function will be invoked after the value has been updated in the store.
     */
    onChange?: (event: ChangeEvent) => void;

    /**
     * Validation properties
     */
    validation?: FieldValidationProps;

    /**
     * Formatting properties
     */
    formatting?: FieldFormattingProps;

    disabled?: boolean;
} & StyledTextFieldProps;

const TextInputField = (props: TextInputFieldProps) => {
    const position = useRef({
        beforeStart: 0,
        beforeEnd: 0,
    });

    const [isEditing, setIsEditing] = useState(false);
    const { formatValue, cleanValue } = useFieldFormatting(props.name, props.formatting);
    const fieldState = useAppSelector(selectFieldStateByName)(props.name);
    const isGrouped = !(props.outline === 'single' || !props.outline);
    const inputElement: HTMLElement = document.getElementsByName(props.name)[0];

    useLayoutEffect(() => {
        if (inputElement instanceof HTMLInputElement && inputElement.type !== 'email') {
            inputElement.setSelectionRange(position.current.beforeStart, position.current.beforeEnd);
        }
    }, [fieldState]);

    const setPosition = (event: any): void => {
        const cleanedValue = cleanValue(event.target.value);
        const formattedValue = formatValue(cleanedValue);
        const initLength = event.target.value.length;
        const delta = (formattedValue?.length ?? 0) - initLength;

        position.current = {
            beforeStart: event.target.selectionStart + delta,
            beforeEnd: event.target.selectionEnd + delta,
        };
    };
    return (
        <>
            <InputField postOnChange={setPosition} {...props}>
                <StyledTextField
                    sx={{ overflow: 'hidden' }}
                    onBlur={() => setIsEditing(false)}
                    onFocus={() => setIsEditing(true)}
                    error={fieldState?.hasError && !isEditing}
                    {...props}
                />
            </InputField>
            {!isGrouped && !isEditing && <ValidationError fieldName={props.name} />}
        </>
    );
};

export default TextInputField;
