import React, { useContext, useEffect } from 'react';
import { IFieldState, ReactFormServiceContext, addOrUpdateFormField } from '@resursbank/react-form-service';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchIntervals, fetchPrice } from '../../store/price/price';
import MiniCalculatorV2 from '../../components/MiniCalculatorV2/MiniCalculatorV2';
import MiniCalculator from '../../components/MiniCalculator/MiniCalculator';
import { RootState } from '../../store';
import { ConsumerLoanTargetPath } from '../../domain/types';
import WarningMessage from '../WarningMessage';
import { useNavigate } from 'react-router-dom';
import { getPath } from '../../helpers/url';
import { getEnvironmentConfig } from '../../config';
import useSettings from '../../hooks/useSettings';

export const selectFieldValueSelector = (state: RootState) => (fieldName: string) => {
    return (
        (state.form.formFieldStates.find((field: IFieldState) => field.fieldName === fieldName)?.value as string) || ''
    );
};

export const LoanInformation = () => {
    const dispatch = useAppDispatch();
    const { translate } = useContext(ReactFormServiceContext);
    const { proxyBaseUrl, countryCode, apiKey, languageCode } = useSettings();
    const loanAmount = Number.parseInt(useAppSelector(selectFieldValueSelector)('amount') || '0');
    const tenorInMonths = Number.parseInt(useAppSelector(selectFieldValueSelector)('tenor') || '0');
    const monthlyAmount = useAppSelector((state) => state.price.information?.monthlyAmount);
    const counter = useAppSelector((state) => state.counter);
    const intervals = useAppSelector((state) => state.price.intervals);
    const { information } = useAppSelector((state) => state.price);
    const navigate = useNavigate();
    const config = getEnvironmentConfig();

    useEffect(() => {
        if (loanAmount === 0 || tenorInMonths === 0) {
            return;
        }

        const payload = {
            apiProxyPath: proxyBaseUrl,
            countryCode,
            languageCode,
            partner: counter.partner,
            productType: counter.productType,
            loanAmount,
            currencyUnit: counter.currencyUnit,
            tenorInMonths,
            apiKey,
        };

        dispatch(fetchPrice(payload));
    }, [dispatch, loanAmount, tenorInMonths]);

    useEffect(() => {
        dispatch(
            fetchIntervals({
                apiProxyPath: proxyBaseUrl,
                countryCode,
                languageCode,
                partner: counter.partner,
                productType: counter.productType,
                apiKey,
            }),
        )
            .unwrap()
            .catch((error) => {
                navigate(getPath(ConsumerLoanTargetPath.error, languageCode, countryCode));
                return error;
            });
    }, [navigate]);

    useEffect(() => {
        dispatch(
            addOrUpdateFormField({
                fieldName: 'tenor',
                value: tenorInMonths,
                hasError: information?.block,
            }),
        );
    }, [information]);

    const onLoanAmountChange = (loanAmountChange: any) => {
        dispatch(
            addOrUpdateFormField({
                fieldName: 'amount',
                value: Number.parseInt(loanAmountChange),
            }),
        );
    };

    const onTenorYearsChange = (tenorChange: any) => {
        dispatch(
            addOrUpdateFormField({
                fieldName: 'tenor',
                value: Number.parseInt(tenorChange),
            }),
        );
    };

    return (
        <>
            {!config.applyFormVersion ? (
                <>
                    <MiniCalculator
                        loanAmount={loanAmount}
                        tenorInMonths={tenorInMonths}
                        monthlyAmount={monthlyAmount}
                        amountStep={intervals?.amountStep.step || 1}
                        minAmount={intervals?.amountRange.min.value}
                        maxAmount={intervals?.amountRange.max.value}
                        minTenor={intervals?.tenorRange.min.value}
                        maxTenor={intervals?.tenorRange.max.value}
                        onAmountChange={onLoanAmountChange}
                        onTenorChange={onTenorYearsChange}
                    />
                    {information?.message?.key && (
                        <WarningMessage hasError={information?.block}>
                            {translate(information.message.key)}
                        </WarningMessage>
                    )}
                </>
            ) : (
                <MiniCalculatorV2
                    loanAmount={loanAmount}
                    tenorInMonths={tenorInMonths}
                    monthlyAmount={monthlyAmount}
                    amountStep={intervals?.amountStep.step || 1}
                    minAmount={intervals?.amountRange.min.value}
                    maxAmount={intervals?.amountRange.max.value}
                    minTenor={intervals?.tenorRange.min.value}
                    maxTenor={intervals?.tenorRange.max.value}
                    onAmountChange={onLoanAmountChange}
                    onTenorChange={onTenorYearsChange}
                />
            )}
        </>
    );
};
