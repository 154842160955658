import React, { useContext, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { addOrUpdateFormField, selectAllFields, ReactFormServiceContext } from '@resursbank/react-form-service';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { ConsumerLoanParameter } from '../../domain/types/ConsumerLoanParameter';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { getPath } from '../../helpers/url';
import useSettings from '../../hooks/useSettings';

const ConsentPage = () => {
    const dispatch = useAppDispatch();
    const { translate } = useContext(ReactFormServiceContext);
    const { origin } = useAppSelector((state) => state.consent);
    const settings = useSettings();
    const navigate = useNavigate();
    const [params] = useSearchParams();

    const externalConsentCorrelationId = params.get(ConsumerLoanParameter.externalConsentCorrelationId);
    const callbackStatus = params.get(ConsumerLoanParameter.callbackStatus);
    const allFields = useAppSelector(selectAllFields);

    const handleExternalConsent = (checked: boolean, warningMessage?: string) => {
        const externalConsentField = allFields.find((field) => field.link?.content === externalConsentCorrelationId);

        if (externalConsentField) {
            dispatch(
                addOrUpdateFormField({
                    fieldName: externalConsentField.name,
                    checked,
                    warningMessage: warningMessage,
                }),
            );
        }
    };

    useEffect(() => {
        if (externalConsentCorrelationId) {
            if (callbackStatus === 'success') {
                handleExternalConsent(true);
            }
            if (callbackStatus === 'failure') {
                handleExternalConsent(false, translate(`page.consent.failure`));
            }
            navigate(getPath(origin!, settings.languageCode, settings.countryCode));
        }
    }, [dispatch, externalConsentCorrelationId, translate]);

    return (
        <Box display="flex" justifyContent="center" marginBottom={2} marginTop={2}>
            <CircularProgress />
        </Box>
    );
};

export default ConsentPage;
