import { useAppDispatch, useAppSelector } from './hooks';
import { useEffect } from 'react';
import { getSettings } from '../domain/settings/Settings';
import { Settings, setSettings, toCountryCode, toLanguageCode } from '@resursbank/react-form-service';
import { useLocalePath } from './useLocalePath';

const useSettings = (): Settings => {
    const dispatch = useAppDispatch();
    const settings = useAppSelector((state) => state.settings);
    const { language, countryCode } = useLocalePath();

    // Refresh the settings based on country code and language
    useEffect(() => {
        const s = getSettings(toCountryCode(countryCode), toLanguageCode(language.toUpperCase()));
        dispatch(setSettings({ ...settings, ...s }));
    }, [countryCode, dispatch, language]);

    return settings;
};

export default useSettings;
