import React from 'react';
import { LoanInformation } from '../../components/LoanInformation/LoanInformation';
import { ReactComponentProps } from '@resursbank/react-form-service';
import NextPageOnEmpty from '../../components/NextPageOnEmpty/NextPageOnEmpty';
import NextPageOnAuthorized from '../../components/NextPageOnAuthorized/NextPageOnAuthorized';
import AuthenticationErrorMessage from '../../components/AuthenticationErrorMessage/AuthenticationErrorMessage';

const ReactComponent = ({ field }: ReactComponentProps) => {
    switch (field.name) {
        case 'loanInformationComponent':
            return <LoanInformation />;
        case 'goToNextPageOnAuthorized':
            return <NextPageOnAuthorized />;
        case 'goToNextPageOnEmpty':
            return <NextPageOnEmpty />;
        case 'authErrorMessage':
            return <AuthenticationErrorMessage />;
        default:
            return <></>;
    }
};

export default ReactComponent;
