import React from 'react';
import Title from '../components/Title/Title';
import Description from '../components/Description/Description';
import useTranslation from '../hooks/useTranslation';
import LivingConditionField from '../components/LivingConditionField/LivingConditionField';
import useWizard from '../hooks/useWizard';
import useWizardValidation from '../hooks/useWizardValidation';

const CoApplicantLivingConditionPage = () => {
    const translate = useTranslation();
    const { setSection } = useWizard();
    setSection(1, translate('page.how-to-apply.subtitle.about-the-loan'), ['next']);
    useWizardValidation(['coapplicantMaritalStatus']);

    return (
        <>
            <Title text={translate('page.co-applicant-living-condition.title')} />
            <Description text={translate('page.co-applicant-living-condition.description')} />
            <LivingConditionField name="coapplicantMaritalStatus" />
        </>
    );
};

export default CoApplicantLivingConditionPage;
