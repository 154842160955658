import React, { useEffect, useState, useContext } from 'react';
import { PARAM_ERROR, ReactFormServiceContext } from '@resursbank/react-form-service';
import { useSearchParams } from 'react-router-dom';
import WarningMessage from '../WarningMessage';

const AuthenticationErrorMessage = () => {
    const [params] = useSearchParams();
    const [message, setMessage] = useState();
    const { translate } = useContext(ReactFormServiceContext);

    useEffect(() => {
        setMessage(params.get(PARAM_ERROR));
    }, [params.get(PARAM_ERROR)]);

    return message && <WarningMessage hasError={true}>{translate('login.failed.message')}</WarningMessage>;
};

export default AuthenticationErrorMessage;
