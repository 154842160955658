import React, { useEffect, useContext } from 'react';
import { useAppSelector } from '../../hooks';
import {
    ReactFormServiceContext,
    StatusCompletePage,
    StatusDeclinedPage,
    StatusErrorPage,
    StatusInspectionPage,
    StatusProcessingPage,
} from '@resursbank/react-form-service';
import { ApplicationStatus, applicationStatusFromFormName } from '../../domain/types';
import useInjectSurveyScript from '../../hooks/useInjectSurveryScript';
import { sendGTMStatusEvent } from '../../helpers/gtm';
import { selectFieldValueSelector } from '../../components/LoanInformation/LoanInformation';

const OngoingStaticPage = () => {
    const { useStaticPage, formName } = useAppSelector((state) => state.form);
    const { applicationId } = useAppSelector((state) => state.application);
    const { translate } = useContext(ReactFormServiceContext);
    const counter = useAppSelector((state) => state.counter);
    const loanAmount = Number.parseInt(useAppSelector(selectFieldValueSelector)('amount'));
    const scriptContainerRef = useInjectSurveyScript();

    const applicationStatus = applicationStatusFromFormName(formName);

    useEffect(() => {
        sendGTMStatusEvent(applicationStatus, counter.productType, loanAmount, applicationId);
    }, [applicationStatus]);

    const staticPage = () => {
        switch (applicationStatus) {
            case ApplicationStatus.complete:
                return (
                    <>
                        <StatusCompletePage
                            title={translate(`page.status.complete.title`)}
                            body={translate(`page.status.complete.description`)}
                            footer={translate(`page.status.complete.footer`)}
                        />
                        <div ref={scriptContainerRef}></div>
                    </>
                );
            case ApplicationStatus.declined:
                return (
                    <StatusDeclinedPage
                        title={translate(`page.status.declined.title`)}
                        body={translate(`page.status.declined.description`)}
                        footer={translate(`page.status.declined.footer`)}
                    />
                );
            case ApplicationStatus.inspection:
                return (
                    <StatusInspectionPage
                        title={translate(`page.status.inspection.title`)}
                        body={translate(`page.status.inspection.description`)}
                    />
                );
            case ApplicationStatus.waiting:
                return (
                    <StatusProcessingPage
                        title={translate(`page.status.waiting.title`)}
                        body={translate(`page.status.waiting.description`)}
                    />
                );
            case ApplicationStatus.error:
                return (
                    <StatusErrorPage
                        title={translate(`page.status.error.title`)}
                        body={translate(`page.status.error.description`)}
                    />
                );
            case ApplicationStatus.processing:
            default:
                return (
                    <StatusProcessingPage
                        title={translate(`page.status.processing.title`)}
                        body={translate(`page.status.processing.description`)}
                    />
                );
        }
    };

    if (!useStaticPage) {
        return <></>;
    }

    return <>{staticPage()}</>;
};

export default OngoingStaticPage;
