import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import styles from './PriceInformation.styles';
import useTranslation from '../../hooks/useTranslation';

type Props = {
    priceData?: PriceData;
    loanAmount: number;
};

type PriceData = {
    yearlyInterest: { percent: number };
    tenor: { months: number };
    effectiveInterest: { percent: number };
    setupFee: { name: string; amount: number; currency: string };
    adminFee: { name: string; amount: number; currency: string };
    monthlyAmount: { amount: number };
    totalRepaymentAmount: { amount: number };
};

type PriceInfo = {
    loanAmount: string;
    todaysDate: string;
    yearlyInterest: number;
    tenorInMonths: number;
    effectiveInterest: number;
    monthlyAmount: number;
    totalRepaymentAmount: number;
    feesInformation: string;
};

const formatNumberByThousands = (num: number): string => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const PriceInformation = ({ priceData, loanAmount }: Props) => {
    const theme = useTheme();
    const translate = useTranslation();
    const todaysDate = new Date().toLocaleDateString();
    const [priceInfo, setPriceInfo] = useState<PriceInfo | undefined>();

    useEffect(() => {
        if (!priceData) {
            return;
        }
        const yearlyInterest = priceData.yearlyInterest.percent || 0;
        const tenorInMonths = priceData.tenor.months || 0;
        const effectiveInterest = priceData.effectiveInterest.percent || 0;
        const monthlyAmount = priceData.monthlyAmount.amount || 0;
        const totalRepaymentAmount = priceData.totalRepaymentAmount.amount || 0;
        const feesInformation = `${priceData.setupFee.name}: ${priceData.setupFee.amount} ${priceData.setupFee.currency}, ${priceData.adminFee.name}: ${priceData.adminFee.amount} ${priceData.adminFee.currency}`;
        setPriceInfo({
            loanAmount: formatNumberByThousands(loanAmount),
            todaysDate,
            yearlyInterest,
            tenorInMonths,
            effectiveInterest,
            monthlyAmount,
            totalRepaymentAmount,
            feesInformation,
        });
    }, [priceData, setPriceInfo]);

    return (
        priceInfo && (
            <Box sx={styles(theme).container}>
                <Box sx={styles(theme).header}>{translate('page.calculator.repex.title')}</Box>
                <Box
                    sx={styles(theme).content}
                    dangerouslySetInnerHTML={{
                        __html: translate('page.calculator.repex', priceInfo),
                    }}
                />
                <Box sx={styles(theme).monthlyCostContainer}>
                    <Box sx={styles(theme).monthlyCostTitle}>
                        {translate('page.calculator.repex.subtitle.monthly-cost')}
                    </Box>
                    <Box sx={styles(theme).monthlyCost}>{priceInfo.monthlyAmount}</Box>
                </Box>
            </Box>
        )
    );
};

export default PriceInformation;
