import React from 'react';
import Title from '../components/Title/Title';
import useTranslation from '../hooks/useTranslation';
import Subtitle from '../components/Subtitle/Subtitle';
import OccupationField from '../components/OccupationField/OccupationField';
import ShowDetailsModal from '../components/ShowDetailsModal/ShowDetailsModal';
import useWizard from '../hooks/useWizard';
import useWizardValidation from '../hooks/useWizardValidation';
import SectorField from '../components/SectorField/SectorField';
import { useAppSelector } from '../../hooks';
import { selectFieldValue } from '@resursbank/react-form-service';

const Employment = () => {
    const translate = useTranslation();
    const { setSection } = useWizard();
    setSection(0, translate('page.how-to-apply.subtitle.about-you'), ['next']);
    useWizardValidation(['applicantEmploymentType', 'applicantOccupationMonth', 'applicantOccupationYear']);
    const employmentType = useAppSelector(selectFieldValue)('applicantEmploymentType');

    const renderSubtitle = () => {
        switch (employmentType) {
            case 'EMPLOYEE':
                return <Subtitle text={translate('page.employment.subtitle.employee')} />;
            case 'SUBSTITUTE':
                return <Subtitle text={translate('page.employment.subtitle.employee')} />;
            case 'SELF_EMPLOYED':
                return <Subtitle text={translate('page.employment.subtitle.self-employed')} />;
            case 'PENSIONER':
                return <Subtitle text={translate('page.employment.subtitle.pensioner')} />;
            case 'UNEMPLOYED':
                return <Subtitle text={translate('page.employment.subtitle.unemployed')} />;
            default:
                return <></>;
        }
    };

    return (
        <>
            <Title text={translate('page.employment.title')} />
            <ShowDetailsModal
                label={translate('dialog.dont-understand.label')}
                dialogTitle={translate('dialog.dont-understand.label')}
                dialogContent={translate('page.employment.dialog.content')}
            />
            <OccupationField
                label={translate('field.applicant-employment-type.label')}
                name={'applicantEmploymentType'}
            />
            {employmentType === 'EMPLOYEE' || employmentType === 'SUBSTITUTE' || employmentType === 'SELF_EMPLOYED' ? (
                <SectorField label={translate('field.applicant-sector-type.label')} name={'applicantSectorType'} />
            ) : null}

            {renderSubtitle()}
        </>
    );
};
export default Employment;
