import { SxProps } from '@mui/material';
import { styled } from '@mui/system';

export const ShowDetailsModalStyles = styled('div')`
    padding-bottom: 16px;
`;

export const Label = styled('div')`
    display: flex;
    align-items: center;
    gap: 6px;
    color: #00807b;
`;

export const HeaderStyles = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    h2 {
        padding: 0px 0 16px 0;
    }
`;

export const IconStyles: SxProps = {
    padding: '0 0 16px 0',
    color: '#212529',
};
