import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { patchExternalConsentService, submitApplicationService } from '../../api/application';
import { IFieldState, selectAllPageFieldStates } from '@resursbank/react-form-service';
import { v4 as uuid } from 'uuid';
import { IApplicationState } from './applicationTypes';
import { ICreateApplicationResponse, ISubmitApplicationResponse } from '../../domain/types';

type IPatchConsentRequest = {
    applicationReferenceNumber: string;
    formFieldStates: IFieldState[];
};
export const patchExternalConsentData = createAsyncThunk<void, IPatchConsentRequest, { state: RootState }>(
    'application/patchExternalConsentData',
    async (request, { getState }) => {
        const state = getState();
        return patchExternalConsentService({
            countryCode: state.settings.countryCode,
            languageCode: state.settings.languageCode,
            apiKey: state.settings.apiKey,
            proxyBaseUrl: state.settings.proxyBaseUrl,
            formFieldStates: request.formFieldStates,
            applicationReferenceNumber: request.applicationReferenceNumber,
            authenticationData: state.authentication?.authenticationData,
        }).then();
    },
);

export const submitApplication = createAsyncThunk<ISubmitApplicationResponse, void, { state: RootState }>(
    'application/submitApplication',
    async (_, { getState }) => {
        const state = getState();
        const apiKey = state.settings.apiKey;

        // Get all form fields and append the channel
        const formFieldStates: IFieldState[] = [
            ...selectAllPageFieldStates(state)(),
            {
                fieldName: 'channel',
                value: state.application?.channel ?? 'WEB',
                hasError: false,
            },

            // Add all consents
            ...state.form.formFieldStates.filter((formFieldState) => formFieldState.fieldName.startsWith('consent_')),
        ];

        return submitApplicationService(
            state.settings.proxyBaseUrl,
            state.settings.languageCode,
            state.settings.countryCode,
            state.counter.partner,
            state.application.applicationId!,
            formFieldStates,
            apiKey,
            state.authentication?.authenticationData,
        );
    },
);

export const createNewApplication = createAsyncThunk<ICreateApplicationResponse, string, { state: RootState }>(
    'application/createNewApplicationId',
    async (channel) => {
        return { applicationId: uuid(), channel };
    },
);

export const initialApplicationState = {
    submitted: false,
    applicationId: undefined,
    channel: 'WEB',
    submitErrorOccurred: false,
    initiatedBy: undefined,
} as IApplicationState;

export const applicationSlice = createSlice({
    name: 'application',
    initialState: initialApplicationState,
    reducers: {
        invalidateApplication: () => initialApplicationState,
        prepareForReSubmit: (state: IApplicationState) => {
            state.submitted = false;
            state.submitErrorOccurred = false;
        },
        authenticationFailed: (state: IApplicationState) => {
            state.submitErrorOccurred = true;
        },
        initiatedBy: (state: IApplicationState, action: PayloadAction<{ initiatedBy?: string }>) => {
            state.initiatedBy = action.payload.initiatedBy;
        },
    },
    extraReducers(builder) {
        builder.addCase(createNewApplication.fulfilled, (state, action) => {
            state.submitted = false;
            state.applicationId = action.payload.applicationId;
            state.channel = action.payload.channel;
            state.submitErrorOccurred = false;
        });
        builder.addCase(submitApplication.fulfilled, (state, action) => {
            state.submitted = true;
            state.applicationId = undefined;
            state.submitErrorOccurred = false;
        });
        builder.addCase(submitApplication.pending, (state) => {
            state.submitted = true;
        });
        builder.addCase(submitApplication.rejected, (state, action) => {
            state.submitted = true;
            console.error('Failed', action);
            state.submitErrorOccurred = true;
        });
    },
});

export const { invalidateApplication, prepareForReSubmit, authenticationFailed, initiatedBy } =
    applicationSlice.actions;

export default applicationSlice.reducer;
