import React from 'react';
import Description from '../components/Description/Description';
import useTranslation from '../hooks/useTranslation';
import Title from '../components/Title/Title';
import ShowDetailsModal from '../components/ShowDetailsModal/ShowDetailsModal';
import GovernmentIdField from '../components/GovernmentIdField/GovernmentIdField';
import useSettings from '../../hooks/useSettings';
import useWizard from '../hooks/useWizard';
import useWizardValidation from '../hooks/useWizardValidation';

const VerifyIncomePage = () => {
    const { countryCode } = useSettings();
    const translate = useTranslation();
    const { setSection, handleStep } = useWizard();
    setSection(0, translate('page.how-to-apply.subtitle.about-you'), ['collect-details']);
    useWizardValidation(['applicantGovernmentId']);

    return (
        <>
            <Title text={translate('page.verify-income.title')} />
            <Description text={translate('page.verify-income.description')} />
            <ShowDetailsModal
                label={translate('dialog.collect-details.label')}
                dialogTitle={translate('dialog.collect-details.label')}
                dialogContent={translate('page.collect-details.content')}
            />
            <GovernmentIdField
                name="applicantGovernmentId"
                countryCode={countryCode}
                label={translate('field.applicant-government-id.label')}
                required={true}
            />
        </>
    );
};

export default VerifyIncomePage;
