import { AuthenticationData } from '@resursbank/react-form-service';

export const config = (apiKey: string, authenticationData?: AuthenticationData) => {
    const headers = {
        'Content-Type': 'application/json',
        apikey: apiKey,
    } as any;

    if (authenticationData) {
        headers['Authorization'] = `${authenticationData.scope === 'LDAP' ? 'Bearer' : authenticationData.token_type} ${
            authenticationData.access_token
        }`;
    }

    return headers;
};
