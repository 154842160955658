import { SxProps } from '@mui/material';

export const SelectFieldStyles: SxProps = {
    color: '#000000',
    backgroundColor: '#FFFFFF',
    border: '1px solid #ADB5BD',
    height: '56px',
    textAlign: 'right',
    '& .MuiSelect-select': {
        padding: '8px 38px 8px 16px !important',
    },
    '& .MuiSelect-select:focus': {
        backgroundColor: '#FFFFFF',
    },
};
