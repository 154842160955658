import React from 'react';
import { SelectRadioField } from '@resursbank/mui';
import { FieldValidationProps } from '../../hooks/useFieldValidation';
import { SelectFieldOption } from '@resursbank/mui/components/SelectField/SelectFieldOption';
import InputField from '../InputField/InputField';

type RadioFieldProps = {
    name: string;
    options: SelectFieldOption[];
    validation?: FieldValidationProps;
};

const RadioField = (props: RadioFieldProps) => {
    return (
        <InputField {...props} radioFieldoOptions={props.options}>
            <SelectRadioField
                options={props.options?.map((field) => ({
                    id: field.id,
                    label: field.label,
                }))}
            />
        </InputField>
    );
};

export default RadioField;
