import React, { useContext, useEffect, useRef, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Grid, CircularProgress, Typography, Box } from '@mui/material';
import TenorYearList from '../TenorYearList';
import LoanAmountInput from '../LoanAmountInput/LoanAmountInput';
import { FormattedAmount, ReactFormServiceContext, SimpleForm } from '@resursbank/react-form-service';
import { IAmount, ITenor } from '../../store/price/priceTypes';
import {
    InfoCard,
    CardBody,
    GridBoxStyles,
    LoanAmountGridStyles,
    GridStyles,
    TypographyStyles,
    TenorGridStyles,
    MonthlyAmountGridStyles,
    MonthlyAmountStyles,
    SliderStyles,
    FoldedCalculator,
    ArrowBox,
} from '../MiniCalculatorV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { useAppSelector } from '../../hooks';
import WarningMessage from '../WarningMessage';

type Props = {
    loanAmount: number;
    onAmountChange: (amount: number) => void;
    tenorInMonths: number;
    onTenorChange: (tenorInMonths: number) => void;
    monthlyAmount?: IAmount;
    amountStep: number;
    minAmount?: IAmount;
    maxAmount?: IAmount;
    minTenor?: ITenor;
    maxTenor?: ITenor;
};

const MiniCalculatorV2 = ({
    loanAmount,
    onAmountChange,
    tenorInMonths,
    onTenorChange,
    monthlyAmount,
    amountStep,
    minAmount,
    maxAmount,
    minTenor,
    maxTenor,
}: Props) => {
    const debouncedAmountChange = useRef<number | undefined>();
    const debouncedTenorChange = useRef<number | undefined>();
    const [open, setOpen] = useState(false);
    const [transitionDone, setTransitionDone] = useState(false);
    const { information } = useAppSelector((state) => state.price);
    const { translate } = useContext(ReactFormServiceContext);
    const { currentPage: currentPageNumber, totalPages } = useAppSelector((state) => state.form);

    const handleOpen = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (currentPageNumber === totalPages) {
            setOpen(true);
        }
    }, []);

    useEffect(() => {
        if (open) {
            setTransitionDone(false);
        } else {
            const timer = setTimeout(() => {
                setTransitionDone(true);
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [open]);

    if (!minAmount || !maxAmount || !minTenor || !maxTenor) {
        return <></>;
    }

    const handleAmountChange = (value: number | number[]) => {
        if (typeof value === 'number') {
            clearTimeout(debouncedAmountChange.current);
            debouncedAmountChange.current = setTimeout(() => {
                onAmountChange(value);
            }, 200) as any;
        }
    };

    const handleTenorChange = (value: number | number[]) => {
        if (typeof value === 'number') {
            const months = value * 12;
            clearTimeout(debouncedTenorChange.current);
            debouncedTenorChange.current = setTimeout(() => {
                onTenorChange(months);
            }, 200) as any;
        }
    };

    const marksAmount = {
        [minAmount.amount]: minAmount.amount,
        [maxAmount.amount]: maxAmount.amount,
    };

    const marksTenor = {
        [minTenor.years]: minTenor.years * 12,
        [maxTenor.years]: maxTenor.years * 12,
    };

    return (
        <>
            <InfoCard marginBottom={16} open={open} padding={!transitionDone}>
                <FoldedCalculator open={!transitionDone}>
                    <Typography sx={TypographyStyles}>
                        <label>{translate(`mini-calculator.loan-amount`)}</label>
                    </Typography>
                    <div style={{ fontWeight: '700' }}>
                        <FormattedAmount amount={loanAmount} currency={minAmount.currency} />
                    </div>
                    <Typography sx={TypographyStyles}>
                        <label>{translate(`mini-calculator.tenor`)}</label>
                    </Typography>
                    <Typography sx={{ ...TypographyStyles, fontWeight: '700' }}>
                        {tenorInMonths / 12 + ' '}
                        {translate(`mini-calculator.year`)}
                    </Typography>
                </FoldedCalculator>
                <CardBody>
                    <Grid container direction="row">
                        <Grid item xs={7} sx={GridBoxStyles}>
                            <Typography sx={TypographyStyles}>
                                <label>{translate(`mini-calculator.loan-amount`)}</label>
                            </Typography>
                        </Grid>
                        <Grid item xs={5} sx={LoanAmountGridStyles}>
                            <LoanAmountInput
                                value={loanAmount > maxAmount.amount ? maxAmount.amount : loanAmount}
                                onChange={onAmountChange}
                                step={amountStep}
                                minValue={minAmount.amount}
                                maxValue={maxAmount.amount}
                                currency={minAmount.currency}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SliderStyles>
                                <Slider
                                    value={loanAmount}
                                    min={minAmount.amount}
                                    max={maxAmount.amount}
                                    marks={marksAmount}
                                    step={amountStep}
                                    onChange={handleAmountChange}
                                />
                            </SliderStyles>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" sx={GridStyles}>
                        <Grid item xs={7} sx={GridBoxStyles}>
                            <Typography sx={TypographyStyles}>
                                <label>{translate(`mini-calculator.tenor`)}</label>
                            </Typography>
                        </Grid>
                        <Grid item xs={5} sx={TenorGridStyles}>
                            <TenorYearList
                                value={tenorInMonths}
                                onChange={onTenorChange}
                                minTenorYears={minTenor.years}
                                maxTenorYears={maxTenor.years}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SliderStyles>
                                <Slider
                                    value={tenorInMonths / 12}
                                    min={minTenor.years}
                                    max={maxTenor.years}
                                    marks={marksTenor}
                                    onChange={handleTenorChange}
                                />
                            </SliderStyles>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row">
                        <Grid item xs={7} sx={GridBoxStyles}>
                            <Typography sx={TypographyStyles}>
                                <label>{translate(`mini-calculator.monthly-cost`)}</label>
                            </Typography>
                        </Grid>
                        <Grid item xs={5} sx={MonthlyAmountGridStyles}>
                            <Typography sx={MonthlyAmountStyles}>
                                {monthlyAmount && (
                                    <FormattedAmount amount={monthlyAmount.amount} currency={monthlyAmount.currency} />
                                )}
                                {!monthlyAmount && <CircularProgress size="xs" color="inherit" />}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardBody>
            </InfoCard>
            {information?.message?.key && (
                <WarningMessage hasError={information?.block}>{translate(information.message.key)}</WarningMessage>
            )}
            {!transitionDone && (
                <Box marginBottom={'16px'}>
                    <SimpleForm form={{ formName: 'consumerloan-consolidations', elementType: 'ISimpleForm' }} />
                </Box>
            )}
            <ArrowBox>
                {transitionDone ? (
                    <FontAwesomeIcon
                        onClick={handleOpen}
                        icon={faCaretDown}
                        style={{ cursor: 'pointer', color: '#00807B' }}
                    />
                ) : (
                    <FontAwesomeIcon
                        onClick={handleOpen}
                        icon={faCaretUp}
                        style={{ cursor: 'pointer', color: '#00807B' }}
                    />
                )}
            </ArrowBox>
        </>
    );
};

export default MiniCalculatorV2;
