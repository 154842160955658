import React, { useEffect } from 'react';
import { ConsumerLoanTargetPath, GetFormPageRequest } from '../../domain/types';
import { useAppDispatch } from '../../hooks';
import { goToPage, initAuthentication } from '@resursbank/react-form-service';
import { getFormPageService } from '../../api/applicationForm';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { ConsumerLoanParameter } from '../../domain/types/ConsumerLoanParameter';
import { useNavigate } from 'react-router-dom';
import { getPath } from '../../helpers/url';
import { useSearchParams } from 'react-router-dom';
import { decryptInitPayloadService } from '../../api/encryption';
import { createNewApplication, initiatedBy } from '../../store/application/application';
import { invalidateStore } from '../../store/store';
import { getEnvironmentConfig } from '../../config';
import useSettings from '../../hooks/useSettings';
import { callbackUrl } from '../../api/util';
import { setSelectedPage } from '../../store/wizard/wizard';

const InitPage = () => {
    const dispatch = useAppDispatch();
    const settings = useSettings();
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const config = getEnvironmentConfig();

    const initializeAuthentication = (username: string, redirectUrl: string) => {
        dispatch(initiatedBy({ initiatedBy: username }));
        dispatch(
            initAuthentication(undefined, {
                scope: 'LDAP',
                redirectUrl,
            }),
        );
    };

    const initApplication = async (payload: string) => {
        await invalidateStore(dispatch);
        const init = await decryptInitPayloadService(settings.proxyBaseUrl, settings.apiKey, payload);

        const { applicationId, channel } = await dispatch(createNewApplication(init.channel ?? 'WEB')).unwrap();
        if (init.applicationReferenceNumber && init.username) {
            // Init authentication with redirect url to ongoing
            const redirectUrl = callbackUrl(
                ConsumerLoanTargetPath.callback,
                settings.languageCode,
                settings.countryCode,
                init.applicationReferenceNumber,
            );

            initializeAuthentication(init.username, redirectUrl);
        } else if (init.tenor && init.amount) {
            dispatch(setSelectedPage(0));
            dispatch(
                goToPage({
                    service: getFormPageService,
                    payload: {
                        page: 1,
                        version: config.applyFormVersion,
                        governmentId: init.governmentId,
                        applicationId,
                        tenor: init.tenor,
                        amount: init.amount,
                        languageCode: settings.languageCode,
                        countryCode: settings.countryCode,
                        channel,
                        projectId: init.projectId,
                    } as GetFormPageRequest,
                    action: '',
                }),
            )
                .unwrap()
                .then(() => {
                    if (init.username) {
                        const redirectUrl = callbackUrl(
                            ConsumerLoanTargetPath.application,
                            settings.languageCode,
                            settings.countryCode,
                            '',
                        );
                        initializeAuthentication(init.username, redirectUrl);
                    } else {
                        navigate(
                            getPath(ConsumerLoanTargetPath.application, settings.languageCode, settings.countryCode),
                        );
                    }
                })
                .catch(() =>
                    navigate(getPath(ConsumerLoanTargetPath.error, settings.languageCode, settings.countryCode)),
                );
        } else {
            navigate(getPath(ConsumerLoanTargetPath.calculator, settings.languageCode, settings.countryCode));
        }
    };

    useEffect(() => {
        if (!settings.proxyBaseUrl) {
            return;
        }

        const initPayloadQueryParam = params.get(ConsumerLoanParameter.initPayload);

        if (initPayloadQueryParam) {
            initApplication(initPayloadQueryParam);
        } else {
            navigate(getPath(ConsumerLoanTargetPath.calculator, settings.languageCode, settings.countryCode));
        }
    }, [params, settings, dispatch]);

    return (
        <Box display="flex" justifyContent="center" marginBottom={2} marginTop={2}>
            <CircularProgress />
        </Box>
    );
};

export default InitPage;
