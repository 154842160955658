import React from 'react';
import { IField, selectCurrentPageFields } from '@resursbank/react-form-service';
import { useEffect } from 'react';
import { useAppSelector } from '../../hooks';
import useGoToInitPage from '../../hooks/useGoToInitPage';

const isInputField = (field: IField): boolean => {
    return (
        field.elementType === 'IRadioField' ||
        field.elementType === 'ITextField' ||
        field.elementType === 'ICheckboxField'
    );
};

/**
 * This compontent will check if the current form contains any input fields.
 * If not it will dispatch to the next page
 */
const NextPageOnEmpty = () => {
    const goToPage = useGoToInitPage();
    const { currentPage: currentPageNumber, action, isLoading } = useAppSelector((state) => state.form);
    const formFields = useAppSelector(selectCurrentPageFields);

    useEffect(() => {
        // Do not dispatch to new page before we have properly loaded the current page
        if (isLoading) {
            return;
        }

        if (!formFields?.find((field) => isInputField(field))) {
            if (action === 'previous') {
                goToPage(currentPageNumber - 1, action);
            } else {
                goToPage(currentPageNumber + 1, action);
            }
        }
    }, [formFields, isLoading]);

    return <></>;
};

export default NextPageOnEmpty;
