import { ReactFormServiceContext, StatusErrorPage } from '@resursbank/react-form-service';
import React, { useContext, useEffect } from 'react';
import { sendGTMErrorEvent } from '../../helpers/gtm';
import { useAppSelector } from '../../hooks';

const ErrorPage = () => {
    const { translate } = useContext(ReactFormServiceContext);
    const { applicationId } = useAppSelector((state) => state.application);

    useEffect(() => {
        sendGTMErrorEvent(applicationId, 'error');
    }, [applicationId]);

    return <StatusErrorPage title={translate(`page.error.title`)} body={translate(`page.error.description`)} />;
};

export default ErrorPage;
