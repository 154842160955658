import React, { useCallback, useEffect, useState } from 'react';
import { StyledTextField } from '@resursbank/mui';
import { FormattedAmount } from '@resursbank/react-form-service';
import { TextFieldStyles } from './LoanAmountInput.styles';

type Props = {
    value: number;
    onChange: (value: number) => void;
    step: number;
    minValue: number;
    maxValue: number;
    currency: string;
};

const LoanAmountInput = ({ value, onChange, step, minValue, maxValue, currency }: Props) => {
    const [currentValue, setCurrentValue] = useState(value);
    const [isEditing, setIsEditing] = useState(false);

    const roundAmount = useCallback(
        (val: number) => {
            const result = Math.ceil(val / step) * step;
            if (result <= minValue) {
                return minValue;
            }

            if (result >= maxValue) {
                return maxValue;
            }

            return result;
        },
        [step, minValue, maxValue],
    );

    const onLoanAmountChange = useCallback(
        (val: string | number) => {
            const reg = /^[0-9]*$/;
            if (typeof val === 'number' || val === '' || reg.test(val as string)) {
                setCurrentValue(val as number);
            } else {
                return false;
            }
        },
        [setCurrentValue],
    );

    const onLoanAmountBlur = useCallback(
        (val: string | number) => {
            const roundedValue = roundAmount(val as number);
            setCurrentValue(roundedValue);
            return onChange(roundedValue);
        },
        [setCurrentValue, onChange],
    );

    // if the value is changed and we are not currently editing
    // update the current value
    useEffect(() => {
        if (!isEditing) {
            setCurrentValue(value);
        }
    }, [value, isEditing]);

    return (
        <>
            {isEditing ? (
                <StyledTextField
                    sx={TextFieldStyles}
                    type="tel"
                    value={currentValue}
                    onChange={(event) => onLoanAmountChange(event.target.value)}
                    onBlur={(event) => {
                        setIsEditing(false);
                        onLoanAmountBlur(event.target.value);
                    }}
                />
            ) : (
                <FormattedAmount
                    textField
                    sx={TextFieldStyles}
                    amount={currentValue}
                    currency={currency}
                    onFocus={() => setIsEditing(true)}
                />
            )}
        </>
    );
};

export default LoanAmountInput;
