import { useEffect, useRef } from 'react';
import { getEnvironmentConfig } from '../config';

const useInjectSurveyScript = () => {
    const scriptContainerRef = useRef<HTMLDivElement>(null);
    const config = getEnvironmentConfig();

    useEffect(() => {
        const surveyId = config.surveyId;
        const pdicustomerNr = '9999999';

        const scriptContent = `
      const dialogSurveyId = "${surveyId}";
      const dialogLinkConfig = {
        "data": {
          "pdicustomerNr": "${pdicustomerNr}"
        }
      };
      const webSurveyConfig = {
        "Width": 600,
        "Height": 600,
        "Percentage": 50,
        "Header": true,
        "Cookie": false,
        "CookieName": "MySurvey",
        "CookieDays": 0,
        "Delay": 2,
        "CloseDelay": 5,
        "onButtonClose": function() {
          console.log("Clicked close-icon");
        },
        "onClosed": function() {
          console.log("Closed");
        },
        "onFinished": function() {
          console.log("Finished!");
        }
      };
      Quicksearch.WebSurvey(Quicksearch.DialogLink(dialogSurveyId, dialogLinkConfig), webSurveyConfig);
    `;

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = scriptContent;

        if (scriptContainerRef.current) {
            scriptContainerRef.current.appendChild(script);
        }

        return () => {
            if (scriptContainerRef.current) {
                scriptContainerRef.current.removeChild(script);
            }
        };
    }, []);

    return scriptContainerRef;
};

export default useInjectSurveyScript;
