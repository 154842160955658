import React, { ReactNode } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ResursLogo } from '@resursbank/mui';
import VisibleOnDesktop from '../VisibleOnDesktop/VisibleOnDesktop';
import PageFooter from '../../../components/PageFooter/PageFooter';
import { RootBoxStyles } from '../../../pages/RootPage/RootPage.styles';
import VisibleOnMobile from '../VisibleOnMobile/VisibleOnMobile';
import BannerField from '../BannerField/BannerField';
import image from '../../../assets/aboutYouBanner.png';

type Props = {
    children: ReactNode;
};

const desktopStyle = RootBoxStyles();
const mobileStyle = { paddingLeft: '24px', paddingRight: '24px', paddingBottom: '100px' };

const ApplicationStyleWrapper = ({ children }: Props) => {
    const theme = useTheme();
    const greaterThanMid = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <>
            <VisibleOnMobile>
                <BannerField image={image} />
            </VisibleOnMobile>
            <Box sx={greaterThanMid ? desktopStyle : mobileStyle}>
                <VisibleOnDesktop>
                    <ResursLogo variant={'row_black'} height={24} />
                </VisibleOnDesktop>
                {children}
            </Box>
            <VisibleOnDesktop>
                <PageFooter />
            </VisibleOnDesktop>
        </>
    );
};

export default ApplicationStyleWrapper;
