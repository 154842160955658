import { ConsumerLoanTargetPath } from '../domain/types';
import { ConsumerLoanParameter } from '../domain/types/ConsumerLoanParameter';

export const getPath = (
    path: ConsumerLoanTargetPath,
    languageCode: string,
    countryCode: string,
    applicationReferenceNumber?: string,
    pageNumber?: string,
) => {
    let result = path.replaceAll(':' + ConsumerLoanParameter.locale, languageCode.toLowerCase() + '_' + countryCode);
    if (applicationReferenceNumber) {
        result = result.replaceAll(':' + ConsumerLoanParameter.applicationReferenceNumber, applicationReferenceNumber);
    }
    if (pageNumber) {
        result = result.replaceAll(':' + ConsumerLoanParameter.pageNumber, pageNumber);
    }
    return result;
};
