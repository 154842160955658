import React, { useEffect, useMemo } from 'react';
import {
    AuthenticationWrapper,
    PARAM_CODE,
    PARAM_STATE,
    getAccessToken,
    initAuthentication,
    selectValidAccessToken,
} from '@resursbank/react-form-service';
import { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import LoginForm from '../LoginForm/LoginForm';
import { ConsumerLoanParameter } from '../../domain/types/ConsumerLoanParameter';
import { useParams } from 'react-router';
import { patchExternalConsentData } from '../../store/application/application';

type Props = {
    children: ReactNode;
    autoLogin: boolean;

    /**
     * Set to true to fetch the login form from backend. If set to false it will use the built in login form.
     */
    fetchLoginForm: boolean;
};

/**
 * This component will make sure that there is a proper authentication before rendering its children.
 * If the user has not authenticated they will be presented with a login button.
 */
const Authenticator = ({ children, autoLogin, fetchLoginForm }: Props) => {
    const dispatch = useAppDispatch();
    const [params] = useSearchParams();
    const routerParams = useParams();
    const accessToken = useAppSelector(selectValidAccessToken);
    const fieldStates = useAppSelector((state) => state.form.formFieldStates);
    const applicationReferenceNumber = useMemo(
        () => routerParams[ConsumerLoanParameter.applicationReferenceNumber],
        [routerParams],
    );

    const dispatchGetAccessToken = () =>
        dispatch(
            getAccessToken({
                state: params.get(PARAM_STATE)!,
                code: params.get(PARAM_CODE)!,
            }),
        );
    const dispatchInitAuthentication = () => {
        dispatch(initAuthentication());
    };

    // If we got authentication parameters, try and use them to get a new access token
    useEffect(() => {
        // We have access token, no need to continue
        if (accessToken) return;

        // If we got the code and we are an administrator, do not continue
        if (params.get(PARAM_CODE) && params.get(PARAM_CODE)?.startsWith('ldap:')) return;

        if (params.get(PARAM_CODE) && params.get(PARAM_STATE)) {
            dispatchGetAccessToken().then(() =>
                // Special handling for SBL consents where we need to patch consent parameters to backend
                dispatch(
                    patchExternalConsentData({
                        applicationReferenceNumber: applicationReferenceNumber!,
                        formFieldStates: fieldStates,
                    }),
                ),
            );
        }
    }, [accessToken, params]);

    if (!accessToken && fetchLoginForm) {
        return <LoginForm />;
    }

    return (
        <AuthenticationWrapper
            accessToken={accessToken}
            autoLogin={autoLogin}
            dispatchInitAuthentication={dispatchInitAuthentication}
            dispatchGetAccessToken={dispatchGetAccessToken}>
            {children}
        </AuthenticationWrapper>
    );
};

export default Authenticator;
