import React from 'react';
import Title from '../components/Title/Title';
import Description from '../components/Description/Description';
import useTranslation from '../hooks/useTranslation';
import Subtitle from '../components/Subtitle/Subtitle';
import OccupationField from '../components/OccupationField/OccupationField';
import MonthField from '../components/MonthField/MonthField';
import YearField from '../components/YearField/YearField';
import useWizard from '../hooks/useWizard';
import useWizardValidation from '../hooks/useWizardValidation';

const CoApplicantEmployment = () => {
    const translate = useTranslation();
    const { setSection } = useWizard();
    setSection(1, translate('page.how-to-apply.subtitle.about-the-loan'), ['next']);
    useWizardValidation(['coapplicantEmploymentType', 'coapplicantOccupationMonth', 'coapplicantOccupationYear']);

    return (
        <>
            <Title text={translate('page.co-applicant-employment.title')} />
            <Description text={translate('page.co-applicant-employment.description')} />
            <OccupationField
                label={translate('field.co-applicant-employment-type.label')}
                name={'coapplicantEmploymentType'}
            />

            <Subtitle text={translate('page.co-applicant-employment.since')} />
            <MonthField
                name={'coapplicantOccupationMonth'}
                label={translate('field.co-applicant-occupation-month.label')}
            />
            <YearField
                name={'coapplicantOccupationYear'}
                label={translate('field.co-applicant-occupation-year.label')}
            />
        </>
    );
};
export default CoApplicantEmployment;
