import { SxProps } from '@mui/material';

export const FooterStyles: SxProps = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: '#f8f9fa',
};

export const PaddingStyles: SxProps = {
    padding: '10px 24px 24px 24px',
};

export const MarginStyles: SxProps = {
    padding: '10px 24px 24px 24px',
};
