import React from 'react';
import Title from '../components/Title/Title';
import Description from '../components/Description/Description';
import useTranslation from '../hooks/useTranslation';
import AccommodationRadios from '../components/RadioField/AccommodationRadios';
import useWizard from '../hooks/useWizard';
import useWizardValidation from '../hooks/useWizardValidation';

const AccommodationPage = () => {
    const translate = useTranslation();
    const { setSection, handleStep } = useWizard();
    setSection(0, translate('page.how-to-apply.subtitle.about-you'), ['next']);
    useWizardValidation(['applicantHabitationForm']);

    return (
        <>
            <Title text={translate('page.accommodation.label')} />
            <Description text={translate('page.accommodation.description')} />
            <AccommodationRadios name={'applicantHabitationForm'} />
        </>
    );
};

export default AccommodationPage;
