import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import { rootBaseReducer, invalidateForm, invalidateAuthentication } from '@resursbank/react-form-service';
import { persistReducer } from 'redux-persist';
import applicationReducer from './application/application';
import priceReducer from './price/price';
import counterReducer from './counter/counter';
import consentReducer from './consent/consent';
import wizardReducer from './wizard/wizard';

const persistConfig = (key: string) => ({
    key,
    storage,
});

const persistedApplicationReducer = persistReducer(persistConfig('rootApplication'), applicationReducer);
const persistedPriceReducer = persistReducer(persistConfig('rootPrice'), priceReducer);
const persistedCounterReducer = persistReducer(persistConfig('rootCounter'), counterReducer);
const persistedConsentReducer = persistReducer(persistConfig('rootConsent'), consentReducer);
const persistedWizardReducer = persistReducer(persistConfig('rootWizard'), wizardReducer);

const consumerloanReducer = {
    application: persistedApplicationReducer,
    price: persistedPriceReducer,
    counter: persistedCounterReducer,
    consent: persistedConsentReducer,
    wizard: persistedWizardReducer,
};

export const rootReducer = combineReducers({
    ...rootBaseReducer,
    ...consumerloanReducer,
});

export const createStore = (preloadedState?: Partial<ReturnType<typeof rootReducer>>) =>
    configureStore({
        reducer: rootReducer,
        devTools: process.env.NODE_ENV !== 'production',
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
        preloadedState,
    });

export const store = createStore();

export const invalidateStore = async (dispatch: AppDispatch) => {
    await dispatch(invalidateAuthentication());
    await dispatch(invalidateForm());
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof createStore;
export type AppDispatch = typeof store.dispatch;
