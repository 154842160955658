import React, { useMemo } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { ProgressBarStyles, TypographyStyle } from './ProgressBar.styles';

type ProgressBarProps = {
    currentPage: number;
    numberOfPages: number;
    label: string;
};

const ProgressBar = (props: ProgressBarProps) => {
    const listPages: string[] = useMemo(() => {
        const listPages = [];
        for (let i = 1; i < props.numberOfPages + 1; i++) {
            listPages.push(i.toString());
        }
        return listPages;
    }, [props.numberOfPages]);

    return (
        <>
            <Stack spacing={2}>
                <Stack direction={'row'} spacing={2}>
                    {listPages.map((page, index) => (
                        <Typography key={index} sx={TypographyStyle}>
                            {props.currentPage === index && props.label}
                        </Typography>
                    ))}
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    {listPages.map((page, index) => (
                        <Box key={index} sx={ProgressBarStyles({ currentPage: props.currentPage, index })} />
                    ))}
                </Stack>
                <Divider />
            </Stack>
        </>
    );
};

export default ProgressBar;
