import { SxProps, Theme } from '@mui/material';

const styles = (theme: Theme): { [key: string]: SxProps } => ({
    calculatorBox: {
        position: 'relative',
        padding: '16px',
    },
    dialogTitle: {
        fontSize: '22px !important',
        lineHeight: '32px !important',
        textAlign: 'center',
    },
    dialogContentText: {
        padding: '16px',
        textAlign: 'center',
        fontSize: '16px',
        lineHeight: '24px',
    },
    mobileBox: {
        textAlign: 'center',
        padding: '16px',
    },
    mobileCancelLink: {
        display: 'block',
        marginBottom: '16px',
        marginTop: '16px',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#495057',
        '&:hover': {
            color: '#000',
        },
    },
    mobileUpdateLink: {
        display: 'block',
        marginTop: '16px',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '16px',
        '&:hover': {
            color: '#007b73',
        },
        '&[disabled]': {
            color: '#1A1E2142',
            pointerEvents: 'none',
        },
    },
    desktopBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '16px 36px 16px 16px',
        gap: '16px',
    },
    dialogContainer: {
        '& .MuiDialog-container': {
            alignItems: 'flex-start',
            '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '654px',
            },
        },
    },
    dialogPaper: {
        mt: '30px',
    },
    desktopCancelLink: {
        padding: '0 18px',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#495057',
        '&:hover': {
            color: '#000',
        },
    },
    desktopUpdateLink: {
        padding: '0 8px',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#00807B',
        '&:hover': {
            color: '#007b73',
        },
        '&[disabled]': {
            color: '#1A1E2142',
            pointerEvents: 'none',
        },
    },
    warningMessage: {
        paddingBottom: '16px',
        color: '#785900',
        fontSize: '14px',
        lineHeight: '16px',
    },
});

export default styles;
