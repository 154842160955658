import React from 'react';
import TextInputField from '../TextInputField/TextInputField';
import { validateGovernmentIdService } from '../../../api/governmentId';
import { FieldValidationProps, ValidationResultType } from '../../hooks/useFieldValidation';
import { getSettings } from '../../../domain/settings/Settings';
import { CountryCode, toCountryCode, toLanguageCode } from '@resursbank/react-form-service';

type GovernmentIdFieldProps = {
    name: string;
    label?: string;
    required?: boolean;
    countryCode: 'NO' | 'SE' | 'DK' | 'FI' | CountryCode;
};

const formatMap = new Map([
    ['NO', '^([0][1-9]|[1-2][0-9]|3[0-1])(0[1-9]|1[0-2])([0-9]{2})([\\d]{5})$'],
    ['SE', '^([0-9]{2})(0[1-9]|1[0-2])([0][1-9]|[1-2][0-9]|3[0-1])(-|\\+)?([\\d]{4})$'],
    ['DK', '^((3[0-1])|([1-2][0-9])|(0[1-9]))((1[0-2])|(0[1-9]))(d{2})(-)?([\\d]{4})$'],
    ['FI', '^([\\d]{6})[+-ABCDEFUVWXY]([\\d]{3})([0123456789ABCDEFHJKLMNPRSTUVWXY])$'],
]);

const formatErrorKeyMap = new Map([
    ['NO', 'validation.error.government.id.format.no'],
    ['SE', 'validation.error.government.id.format.se'],
    ['DK', 'validation.error.government.id.format.dk'],
    ['FI', 'validation.error.government.id.format.fi'],
]);

const GovernmentIdField = (props: GovernmentIdFieldProps) => {
    const { proxyBaseUrl } = getSettings(toCountryCode(props.countryCode), toLanguageCode(props.countryCode));

    const governmentIdValidationService = (value?: string): Promise<ValidationResultType> =>
        validateGovernmentIdService(proxyBaseUrl, value).then((valid) => ({
            valid,
            errorMessageKey: valid ? undefined : formatErrorKeyMap.get(props.countryCode),
        }));

    const validationProps = (): FieldValidationProps => ({
        required: props.required,
        requiredErrorKey: 'validation.error.government.id.required',
        format: formatMap.get(props.countryCode),
        formatErrorKey: formatErrorKeyMap.get(props.countryCode),
        customValidation: governmentIdValidationService,
    });

    return (
        <TextInputField
            type="text"
            validation={validationProps()}
            name={props.name}
            label={props.label}
            required={props.required}
        />
    );
};

export default GovernmentIdField;
