import React from 'react';
import { useWizard } from 'react-use-wizard';
import { useAppSelector } from '../../../hooks';
import { selectPageActions } from '../../../store/wizard/wizard';
import VisibleOnMobile from '../VisibleOnMobile/VisibleOnMobile';
import { Box, Paper, Stack } from '@mui/material';
import ProgressBar from '../ProgressBar/ProgressBar';
import VisibleOnDesktop from '../VisibleOnDesktop/VisibleOnDesktop';
import WizardPageAction from './WizardPageAction';
import { FooterStyles, MarginStyles, PaddingStyles } from './WizardFooter.styles';

const WizardFooter = () => {
    const pageActions = useAppSelector(selectPageActions);
    const sectionCount = useAppSelector((state) => state.wizard.sectionCount);
    const { selectedSection, sectionLabel } = useAppSelector((state) => state.wizard);

    return (
        <>
            <VisibleOnMobile>
                <Paper sx={FooterStyles} square variant="outlined">
                    <Box sx={PaddingStyles}>
                        <ProgressBar currentPage={selectedSection} numberOfPages={sectionCount} label={sectionLabel} />

                        <Stack direction={'row'} spacing={2}>
                            {pageActions.map((action) => (
                                <WizardPageAction key={action} action={action} />
                            ))}
                        </Stack>
                    </Box>
                </Paper>
            </VisibleOnMobile>

            <VisibleOnDesktop>
                <Box sx={MarginStyles}>
                    <Stack direction={'row'} spacing={2}>
                        {pageActions.map((action) => (
                            <WizardPageAction key={action} action={action} />
                        ))}
                    </Stack>
                </Box>
            </VisibleOnDesktop>
        </>
    );
};

export default WizardFooter;
