import { IFormPage, IFieldState } from '@resursbank/react-form-service';

export const simpleFormService = (
    simpleFormUrl: string,
    apiKey: string,
    simpleFormName: string,
    applicationId: any,
    governmentId: any,
    callbackUrl: string,
    payload?: IFieldState[],
): Promise<IFormPage> => {
    let headers: any = {
        'Content-Type': 'application/json;charset=UTF-8',
    };
    if (apiKey) {
        headers = { ...headers, apikey: apiKey };
    }

    const body = data(payload, applicationId, governmentId, callbackUrl);
    const requestParameters = {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
    };

    return fetch(`${simpleFormUrl}/${simpleFormName}`, requestParameters).then((response) => {
        if (response.ok) {
            return response.json();
        }
        return {} as IFormPage;
    });
};

const data = (payload?: IFieldState[], applicationId?: any, governmentId?: any, callbackUrl?: string) => {
    const values = {} as any;

    if (applicationId) {
        values.applicationId = applicationId;
    }
    if (governmentId) {
        values.governmentId = governmentId;
    }
    if (callbackUrl) {
        values.callbackUrl = callbackUrl;
    }

    const applicantGovernmentIdField = payload?.find((field) => field.fieldName === 'applicantGovernmentId');
    if (applicantGovernmentIdField) {
        values.governmentId = applicantGovernmentIdField.value;
    }

    if (payload) {
        payload.forEach((fieldState) => {
            const name = fieldState.fieldName;
            const value = fieldState.value;
            if (value) {
                values[name] = value;
            }
        });
    }

    return values;
};
