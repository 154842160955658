import React from 'react';
import { Typography } from '@mui/material';

type Props = {
    text: string;
};

const Subtitle = ({ text }: Props) => {
    return (
        <Typography variant={'subtitle1'} style={{ margin: '10px 0' }}>
            {text}
        </Typography>
    );
};

export default Subtitle;
