import React, { ReactNode } from 'react';

type TranslationProviderProps = {
    children: ReactNode;
    translate: (key: string) => string;
};

export const TranslationProvider = ({ children, translate }: TranslationProviderProps) => {
    return <TranslationContext.Provider value={{ translate }}>{children}</TranslationContext.Provider>;
};

type Provider = {
    translate: (key: string) => string;
};

const defaultContext: Provider = {
    translate: (_key: string) => {
        throw new Error('Translate not implemented');
    },
};

export const TranslationContext = React.createContext<Provider>(defaultContext);
