import { AuthenticationData } from '@resursbank/react-form-service';
import { config } from '../helpers/apiConfig';
import * as Sentry from '@sentry/react';

export const consentUrlService = async (
    apiProxyPath: string,
    applicationId: string,
    correlationId: string,
    apiKey: string,
    authenticationData?: AuthenticationData,
): Promise<string> => {
    const url = `${apiProxyPath}/consent/url/${applicationId}/${correlationId}`;
    const request = {
        method: 'GET',
        headers: config(apiKey, authenticationData),
    };

    try {
        const response = await fetch(url, request);
        if (!response.ok) {
            const error = await response.json();
            if (process.env.NODE_ENV === 'production') {
                Sentry.captureException(new Error(`Get consent url service failed: ${JSON.stringify(error)}`));
            }
            throw new Error(error);
        }
        return await response.json();
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(
                new Error(
                    `Get consent url service failed: ${error instanceof Error ? error.message : JSON.stringify(error)}`,
                ),
            );
        }
        throw error;
    }
};
