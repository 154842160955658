import React, { ReactElement } from 'react';
import useSettings from '../../../hooks/useSettings';

type Props = {
    legacyForm: ReactElement;
    newForm: ReactElement;
};

/**
 * The switcharoo component will switch between the legacy design if
 * the application is made in the Norwegian application or else the
 * new form.
 */
const Switcharoo = ({ legacyForm, newForm }: Props) => {
    const { countryCode } = useSettings();
    if (countryCode === 'NO') {
        return legacyForm;
    } else {
        return newForm;
    }
};

export default Switcharoo;
