import { SxProps } from '@mui/material';
import styled from 'styled-components';

export const smallScreenSX: SxProps = {
    '& .MuiDialog-container': {
        alignItems: 'flex-end',
    },

    '& .MuiPaper-root': {
        width: '100%',
        margin: '0px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
    },
};

export const DialogActionStyles: SxProps = {
    marginTop: '20px',
    justifyContent: 'center',
    borderWidth: '0px',
    borderTopWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#DEE2E6',
};

export const DialogButton: SxProps = {
    marginTop: '10px',
    marginBottom: '10px',
};

export const Link = styled.a`
    text-decoration: none;
    color: #fff;
`;
