import React, { useMemo } from 'react';
import { LinkFieldDocumentStyles, Download, DocumentProps } from '@resursbank/react-form-service';
import { useAppSelector } from '../../hooks';
import { getDocument } from '../../api/documentApi';
import { selectFieldValueSelector } from '../../components/LoanInformation/LoanInformation';
import { ConsumerLoanDocumentLabel } from './ConsumerloanDocument.styles';
import { GetDocumentRequest } from '../types';
import useSettings from '../../hooks/useSettings';

const ConsumerloanDocument = ({ field }: DocumentProps) => {
    const { proxyBaseUrl, countryCode, apiKey, languageCode } = useSettings();
    const tenor = Number.parseInt(useAppSelector(selectFieldValueSelector)('tenor'));
    const amount = Number.parseInt(useAppSelector(selectFieldValueSelector)('amount'));

    const link = useMemo(() => ` <a>${field?.link?.label}</a>`, [field]);
    const linkContent = field?.link?.content;

    const handleDocuments = () => {
        if (field?.link?.action === 'openDocumentInNewTab') {
            let data = undefined;
            if (linkContent === 'secci') {
                data = { values: { loanAmount: amount, tenor } };
            }
            getDocument({
                apiProxyPath: proxyBaseUrl,
                apiKey,
                countryCode,
                languageCode,
                documentType: linkContent,
                data,
            } as GetDocumentRequest);
        }
    };
    return (
        <>
            <ConsumerLoanDocumentLabel
                justify={linkContent === 'secci' || linkContent === 'commonTerms' ? 'left' : 'space-between'}
                weight={linkContent === 'secci' || linkContent === 'commonTerms' ? '300' : '700'}>
                <LinkFieldDocumentStyles
                    onClick={handleDocuments}
                    dangerouslySetInnerHTML={{ __html: field?.label?.replace('#link#', link) ?? '' }}
                />
                <div onClick={handleDocuments}>
                    <Download />
                </div>
            </ConsumerLoanDocumentLabel>
        </>
    );
};

export default ConsumerloanDocument;
