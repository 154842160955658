import React from 'react';
import { DocumentUploadProps, UploadField } from '@resursbank/react-form-service';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { uploadDocumentService } from '../../api/documentApi';
import { useAppSelector } from '../../hooks';
import { ConsumerLoanParameter } from '../types/ConsumerLoanParameter';
import useSettings from '../../hooks/useSettings';

const ComplementationUploadField = ({ field, handleChange }: DocumentUploadProps) => {
    const authentication = useAppSelector((state) => state.authentication);
    const settings = useSettings();
    const params = useParams();

    const applicationReferenceNumber = useMemo(
        () => params[ConsumerLoanParameter.applicationReferenceNumber],
        [params],
    );

    const onSelectFile = (file: File): Promise<void> => {
        const url = `${settings.proxyBaseUrl}/document/${applicationReferenceNumber}`;

        return uploadDocumentService(
            url,
            settings.apiKey,
            authentication.authenticationData!,
            field.name!,
            file,
        ).then();
    };

    return <UploadField uploadField={field} handleChange={handleChange ?? (() => {})} onSelectFile={onSelectFile} />;
};

export default ComplementationUploadField;
