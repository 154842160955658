import React, { useContext } from 'react';
import { Grid, CircularProgress, Typography } from '@mui/material';
import TenorYearList from '../TenorYearList';
import LoanAmountInput from '../LoanAmountInput/LoanAmountInput';
import { FormattedAmount, ReactFormServiceContext } from '@resursbank/react-form-service';
import { IAmount, ITenor } from '../../store/price/priceTypes';
import {
    InfoCard,
    CardBody,
    GridBoxStyles,
    LoanAmountGridStyles,
    GridStyles,
    TypographyStyles,
    TenorGridStyles,
    MonthlyAmountGridStyles,
} from '../MiniCalculator';

type Props = {
    loanAmount: number;
    onAmountChange: (amount: number) => void;
    tenorInMonths: number;
    onTenorChange: (tenorInMonths: number) => void;
    monthlyAmount?: IAmount;
    amountStep: number;
    minAmount?: IAmount;
    maxAmount?: IAmount;
    minTenor?: ITenor;
    maxTenor?: ITenor;
};

const MiniCalculator = ({
    loanAmount,
    onAmountChange,
    tenorInMonths,
    onTenorChange,
    monthlyAmount,
    amountStep,
    minAmount,
    maxAmount,
    minTenor,
    maxTenor,
}: Props) => {
    const { translate } = useContext(ReactFormServiceContext);

    if (!minAmount || !maxAmount || !minTenor || !maxTenor) {
        return <></>;
    }

    return (
        <InfoCard marginBottom={16}>
            <CardBody>
                <Grid container direction="row">
                    <Grid item xs={7} sx={GridBoxStyles}>
                        <Typography sx={TypographyStyles}>
                            <label>{translate(`mini-calculator.loan-amount`)}</label>
                        </Typography>
                    </Grid>
                    <Grid item xs={5} sx={LoanAmountGridStyles}>
                        <LoanAmountInput
                            value={loanAmount > maxAmount.amount ? maxAmount.amount : loanAmount}
                            onChange={onAmountChange}
                            step={amountStep}
                            minValue={minAmount.amount}
                            maxValue={maxAmount.amount}
                            currency={minAmount.currency}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" sx={GridStyles}>
                    <Grid item xs={7} sx={GridBoxStyles}>
                        <Typography sx={TypographyStyles}>
                            <label>{translate(`mini-calculator.tenor`)}</label>
                        </Typography>
                    </Grid>
                    <Grid item xs={5} sx={TenorGridStyles}>
                        <TenorYearList
                            value={tenorInMonths}
                            onChange={onTenorChange}
                            minTenorYears={minTenor.years}
                            maxTenorYears={maxTenor.years}
                        />
                    </Grid>
                </Grid>
                <Grid item container direction="row">
                    <Grid item xs={7} sx={GridBoxStyles}>
                        <Typography sx={TypographyStyles}>
                            <label>{translate(`mini-calculator.monthly-cost`)}</label>
                        </Typography>
                    </Grid>
                    <Grid item xs={5} sx={MonthlyAmountGridStyles}>
                        <Typography sx={TypographyStyles}>
                            {monthlyAmount && (
                                <FormattedAmount amount={monthlyAmount.amount} currency={monthlyAmount.currency} />
                            )}
                            {!monthlyAmount && <CircularProgress size="xs" color="inherit" />}
                        </Typography>
                    </Grid>
                </Grid>
            </CardBody>
        </InfoCard>
    );
};

export default MiniCalculator;
