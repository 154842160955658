import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { StyledTextField } from '@resursbank/mui';
import { useAppSelector } from '../../hooks';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { createAuthenticationCodeService } from '../../api/authorization';
import { PARAM_CODE, PARAM_STATE } from '@resursbank/react-form-service';
import useSettings from '../../hooks/useSettings';

export const LdapLoginPage = () => {
    const PARAM_REDIRECT_URI = 'redirect_uri';
    const settings = useSettings();
    const initiatedBy = useAppSelector((state) => state.application.initiatedBy);

    const [params, setParams] = useSearchParams();

    const [password, setPassword] = useState<string>();
    const [state, setState] = useState<string | undefined>();
    const [redirectUri, setRedirectUri] = useState<string | undefined>();

    useEffect(() => {
        const state = params.get(PARAM_STATE);

        if (state) {
            setState(state);
            params.delete(PARAM_STATE);
            setParams(params);
        }
        const redirectUri = params.get(PARAM_REDIRECT_URI);

        if (redirectUri) {
            setRedirectUri(redirectUri);
        }
    }, [params, setParams]);

    const onInitApplication = () => {
        createAuthenticationCodeService(settings.oAuthBaseUrl, settings.apiKey, initiatedBy ?? '', password ?? '').then(
            (code) => {
                window.location.assign(`${redirectUri}?${PARAM_STATE}=${state}&${PARAM_CODE}=${code}`);
            },
        );
    };

    return (
        <Box marginBottom={2} marginTop={2}>
            <Box marginBottom={2} marginTop={2}>
                <StyledTextField id="username" name="username" value={initiatedBy ?? ''} label="Username" disabled />
            </Box>

            <Box marginBottom={2} marginTop={2}>
                <StyledTextField
                    id="password"
                    name="password"
                    value={password ?? ''}
                    onChange={(e) => setPassword(e.target.value)}
                    label="Password"
                    type="password"
                    autoComplete="off"
                />
            </Box>

            <Button disabled={!password || password.trim().length === 0} onClick={onInitApplication}>
                Start application
            </Button>
        </Box>
    );
};
