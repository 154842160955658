import React from 'react';
import Title from '../components/Title/Title';
import Group from '../components/Group/Group';
import EmailField from '../components/EmailField/EmailField';
import PhoneField from '../components/PhoneField/PhoneField';
import Description from '../components/Description/Description';
import useTranslation from '../hooks/useTranslation';
import GovernmentIdField from '../components/GovernmentIdField/GovernmentIdField';
import useSettings from '../../hooks/useSettings';
import RadioField from '../components/RadioField/RadioField';
import { useAppSelector } from '../../hooks';
import { selectFieldValue } from '@resursbank/react-form-service';
import { selectFieldStateByName } from '../../store/applicationform/applicationFormSelectors';
import useWizard from '../hooks/useWizard';

const CoApplicantPage = () => {
    const { setSection, handleStep } = useWizard();
    const translate = useTranslation();
    setSection(1, translate('page.how-to-apply.subtitle.about-the-loan'), ['next']);

    const countryCode = useSettings().countryCode;
    const view = useAppSelector(selectFieldValue)('coapplicantToggle');

    const coapplicantGovernmentId = useAppSelector(selectFieldStateByName)('coapplicantGovernmentId');
    const coapplicantEmailAddress = useAppSelector(selectFieldStateByName)('coapplicantEmailAddress');
    const coapplicantMobileNumber = useAppSelector(selectFieldStateByName)('coapplicantMobileNumber');

    handleStep(() => {
        if (
            view === '' ||
            (view === 'true' &&
                (coapplicantGovernmentId?.hasError ||
                    coapplicantEmailAddress?.hasError ||
                    coapplicantMobileNumber?.hasError))
        ) {
            throw new Error('Validation errors');
        }
    });

    return (
        <>
            <Title text={translate('page.co-applicant.title')} />
            <Description text={translate('page.co-applicant.description')} />

            <RadioField
                name={'coapplicantToggle'}
                options={[
                    { id: 'false', label: translate('field.co-applicant-toggle.option.no.label') },
                    { id: 'true', label: translate('field.co-applicant-toggle.option.yes.label') },
                ]}
            />
            <br />

            {view === 'true' && (
                <>
                    <GovernmentIdField
                        required={true}
                        name={'coapplicantGovernmentId'}
                        label={translate('field.co-applicant-government-id.label')}
                        countryCode={countryCode}
                    />
                    <Group>
                        <EmailField
                            required={true}
                            name={'coapplicantEmailAddress'}
                            label={translate('field.co-applicant-email.label')}
                        />
                        <PhoneField
                            required={true}
                            name={'coapplicantMobileNumber'}
                            label={translate('field.co-applicant-mobile-number.label')}
                        />
                    </Group>
                </>
            )}
        </>
    );
};

export default CoApplicantPage;
