import { getCookieConsentStatus } from '@resursbank/react-form-service';
interface PageViewEventData {
    event: string;
    pageInfo?: any;
}

interface PageStepEventData {
    event: string;
    eventInfo?: any;
}

interface SubmittedLoanEventData {
    event: string;
    eventInfo?: any;
}

interface StatusEventData {
    event: string;
    eventInfo?: any;
}

interface ErrorEventData {
    event: string;
    eventInfo?: any;
}

export function sendGTMPageViewEvent(path: string, title: string): void {
    if (getCookieConsentStatus() !== 'Accepted') {
        return;
    }

    const data: PageViewEventData = {
        event: 'pageview',
        pageInfo: {
            path: path,
            title: title,
        },
    };

    if (typeof window !== 'undefined' && (window as any).dataLayer !== 'undefined') {
        (window as any).dataLayer.push(data);
    }
}

export function sendGTMPageStepEvent(productType: any, currentPage: number, applicationId: any): void {
    if (getCookieConsentStatus() !== 'Accepted') {
        return;
    }

    const data: PageStepEventData = {
        event: 'step',
        eventInfo: {
            type: productType,
            step: currentPage + 1,
            transaction_id: applicationId,
        },
    };

    if (typeof window !== 'undefined' && (window as any).dataLayer !== 'undefined') {
        (window as any).dataLayer.push(data);
    }
}

export function sendGTMSubmittedLoan(productType: any, loanAmount: number, applicationId: any): void {
    if (getCookieConsentStatus() !== 'Accepted') {
        return;
    }

    const data: SubmittedLoanEventData = {
        event: 'submitted_loan',
        eventInfo: {
            type: productType,
            value: loanAmount,
            transaction_id: applicationId,
        },
    };

    if (typeof window !== 'undefined' && (window as any).dataLayer !== 'undefined') {
        (window as any).dataLayer.push(data);
    }
}

export function sendGTMStatusEvent(
    applicationStatus: string,
    productType: any,
    loanAmount: number,
    applicationId: any,
): void {
    if (getCookieConsentStatus() !== 'Accepted') {
        return;
    }

    const data: StatusEventData = {
        event: 'status',
        eventInfo: {
            decision: applicationStatus,
            type: productType,
            value: loanAmount,
            transaction_id: applicationId,
        },
    };

    if (typeof window !== 'undefined' && (window as any).dataLayer !== 'undefined') {
        (window as any).dataLayer.push(data);
    }
}

export function sendGTMErrorEvent(applicationId: any, error: any): void {
    if (getCookieConsentStatus() !== 'Accepted') {
        return;
    }

    const data: ErrorEventData = {
        event: 'error',
        eventInfo: {
            transaction_id: applicationId,
            status: error,
        },
    };

    if (typeof window !== 'undefined' && (window as any).dataLayer !== 'undefined') {
        (window as any).dataLayer.push(data);
    }
}
