import React, { ReactNode } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Box, Divider } from '@mui/material';

type ListFieldProps = {
    name: string;
    items: Array<{ index: string; icon: ReactNode; label: ReactNode }>;
};

export default function ListField(props: ListFieldProps) {
    return (
        <List>
            {props.items.map((item, index) => (
                <Box key={index}>
                    <ListItem key={item.index}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.label} />
                    </ListItem>
                    <Divider variant="middle" component="li" />
                </Box>
            ))}
        </List>
    );
}
