import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { consentUrlService } from '../../api/consent';
import { IConsentState, RedirtUrlRequest } from './consentTypes';

export const redirectUrl = createAsyncThunk<string, RedirtUrlRequest, { state: RootState }>(
    'consent/redirectUrl',
    async (redirtUrlRequest, { getState }) => {
        const state = getState();

        return consentUrlService(
            state.settings.proxyBaseUrl,
            state.application.applicationId!,
            redirtUrlRequest.correlationId,
            state.settings.apiKey,
            state.authentication?.authenticationData,
        );
    },
);

export const initialConsentState = {
    origin: undefined,
    errorOccurred: false,
} as IConsentState;

export const consentSlice = createSlice({
    name: 'consent',
    initialState: initialConsentState,
    reducers: {
        setError: (state): void => {
            state.errorOccurred = true;
        },
    },
    extraReducers(builder) {
        builder.addCase(redirectUrl.fulfilled, (state, action) => {
            state.errorOccurred = false;
            state.origin = action.meta.arg.origin;
        });
        builder.addCase(redirectUrl.rejected, (state, action) => {
            state.errorOccurred = true;
            state.origin = undefined;
        });
    },
});

export const { setError } = consentSlice.actions;

export default consentSlice.reducer;
