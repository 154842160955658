import React, { useState } from 'react';
import { SelectField, StyledTextField } from '@resursbank/mui';
import { ConsumerLoanInitPayload } from '../../domain/types/ConsumerLoanInitPayload';
import { ConsumerLoanParameter } from '../../domain/types/ConsumerLoanParameter';
import { Box } from '@mui/system';
import { encryptInitPayloadService } from '../../api/encryption';
import { useLocalePath } from '../../hooks/useLocalePath';
import { useNavigate } from 'react-router-dom';
import { ToggleButtonGroup, ToggleButton, Button, Tabs, Tab } from '@mui/material';
import { ConsumerLoanTargetPath } from '../../domain/types';
import { getPath } from '../../helpers/url';
import { SelectFieldStyles } from './CalculatorPage.styles';
import useSettings from '../../hooks/useSettings';

const ORIGINATING_URL_OPTIONS = [
    {
        id: 'https://www.resursbank.se/',
        value: 'https://www.resursbank.se/',
        label: 'Resursbank SE',
    },
    {
        id: 'https://www.resursbank.no/',
        value: 'https://www.resursbank.no/',
        label: 'Resursbank NO',
    },
    {
        id: 'https://www.resursbank.fi/',
        value: 'https://www.resursbank.fi/',
        label: 'Resursbank FI',
    },
    {
        id: 'https://www.resursbank.dk/',
        value: 'https://www.resursbank.dk/',
        label: 'Resursbank DK',
    },
];

export const CalculatorPage = () => {
    const settings = useSettings();
    const [governmentId, setGovernmentId] = useState<string>();
    const [username, setUsername] = useState<string>();
    const [amount, setAmount] = useState<number>(10000);
    const [tenor, setTenor] = useState<number>(24);
    const [channel, setChannel] = useState<'WEB' | 'SALES' | 'PHONE'>('WEB');
    const [originatingUrl, setOriginatingUrl] = useState<string>(ORIGINATING_URL_OPTIONS[0].id);
    const { language, countryCode } = useLocalePath();
    const navigate = useNavigate();

    const onInitApplication = () => {
        const initPayload: ConsumerLoanInitPayload = {
            channel,
            governmentId,
            amount: amount,
            tenor: tenor,
            originatingUrl,
        };

        if (channel === 'SALES' || channel === 'PHONE') {
            initPayload.username = username;
        }

        encryptInitPayloadService(settings.proxyBaseUrl, settings.apiKey, initPayload).then((result) => {
            navigate({
                pathname: getPath(ConsumerLoanTargetPath.init, language, countryCode),
                search: `${ConsumerLoanParameter.initPayload}=${result}`,
            });
        });
    };

    const createOptions = () => {
        const result = [];
        for (let years = 1; years <= 15; years++) {
            result.push({
                id: '' + years * 12,
                value: '' + years * 12,
                label: years + ' years',
            });
        }
        return result;
    };

    return (
        <Box marginBottom={2} marginTop={2}>
            <Box marginBottom={2} marginTop={2}>
                <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={language + '_' + countryCode}
                    onChange={(_event: React.MouseEvent<HTMLElement>, value: string) => navigate('/' + value)}>
                    <ToggleButton value="sv_SE">SE</ToggleButton>
                    <ToggleButton value="no_NO">NO</ToggleButton>
                    <ToggleButton value="fi_FI">FI</ToggleButton>
                    <ToggleButton value="da_DK">DK</ToggleButton>
                </ToggleButtonGroup>
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={channel}>
                    <Tab label="Web" value={'WEB'} onClick={() => setChannel('WEB')} />
                    <Tab label="Sales" value={'SALES'} onClick={() => setChannel('SALES')} />
                    <Tab label="Phone" value={'PHONE'} onClick={() => setChannel('PHONE')} />
                </Tabs>
            </Box>

            {(channel === 'SALES' || channel === 'PHONE') && (
                <Box marginBottom={2} marginTop={2}>
                    <StyledTextField
                        id="username"
                        name="username"
                        value={username ?? ''}
                        onChange={(e) => setUsername(e.target.value)}
                        label="Username"
                    />
                </Box>
            )}

            <Box marginBottom={2} marginTop={2}>
                <StyledTextField
                    id="governmentId"
                    name="governmentId"
                    value={governmentId ?? ''}
                    onChange={(e) => setGovernmentId(e.target.value)}
                    label="Government Id"
                />
            </Box>

            <Box marginBottom={2} marginTop={2}>
                <StyledTextField
                    id="amount"
                    name="amount"
                    value={amount}
                    onChange={(e) => setAmount(parseInt(e.target.value))}
                    label="Loan amount"
                />
            </Box>

            <Box marginBottom={2} marginTop={2}>
                <SelectField
                    sx={SelectFieldStyles}
                    id="tenor"
                    name="tenor"
                    value={'' + tenor}
                    onChange={(event) => setTenor(parseInt('' + event.target.value))}
                    label="Tenor"
                    fullWidth
                    variant="outlined"
                    options={createOptions()}></SelectField>
            </Box>

            <Box marginBottom={4} marginTop={4}>
                <SelectField
                    sx={SelectFieldStyles}
                    id="originatingUrl"
                    name="originatingUrl"
                    value={originatingUrl}
                    onChange={(event) => setOriginatingUrl('' + event.target.value)}
                    label="OriginatingUrl"
                    fullWidth
                    variant="outlined"
                    options={ORIGINATING_URL_OPTIONS}></SelectField>
            </Box>

            <Button onClick={onInitApplication}>Start application</Button>
        </Box>
    );
};
