import React from 'react';

export type FieldValidationProps = {
    /**
     * Flag to indicate if the field is mandatory, if true then it is mandatory, otherwise it is optional.
     */
    required?: boolean;

    /**
     * An optional required error message translation key which should be used when required is set to true.
     */
    requiredErrorKey?: string;

    /**
     * An optional format which can be used to determine if the input is matching it.
     */
    format?: string;

    /**
     * An optional format error message translation key which should be used in combination with the format attribute.
     */
    formatErrorKey?: string;

    /**
     * Custom validation which should be executed once all other parts are valid, i.e. field has a value and if format
     * is defined it should comply to the defined format first.
     *
     * @returns true if field is valid, false otherwise.
     */
    customValidation?: (value?: string) => Promise<ValidationResultType>;
};

export type ValidationResultType = {
    /**
     * Flag to indicate if the field is valid or not
     */
    valid: boolean;

    /**
     * Translation key for the error to display.
     */
    errorMessageKey?: string;
};

const useFieldValidation = () => {
    const validate = async (
        name: string,
        value: string | undefined,
        props: FieldValidationProps,
    ): Promise<ValidationResultType> => {
        if (!value || value.trim().length === 0) {
            if (props.required === true) {
                return {
                    valid: false,
                    errorMessageKey: props.requiredErrorKey ?? `not.defined.required.error.key.${name}`,
                };
            }
            return { valid: true };
        }

        if (!!props.format && props.format.startsWith('^') && !value?.match(new RegExp(props.format))) {
            return {
                valid: false,
                errorMessageKey: props.formatErrorKey ?? `not.defined.format.error.key.${name}`,
            };
        }

        if (!!props.customValidation) {
            return props.customValidation(value);
        }

        return { valid: true };
    };

    return { validate };
};

export default useFieldValidation;
