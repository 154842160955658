import React, { useContext, useMemo } from 'react';
import { SelectField } from '@resursbank/mui';
import { ReactFormServiceContext } from '@resursbank/react-form-service';
import { SelectFieldStyles } from './TenorYearList.styles';

type Props = {
    value: number;
    onChange: (tenorInMonths: number) => void;
    minTenorYears: number;
    maxTenorYears: number;
};

/**
 * Displays a list of years as tenor
 */
const TenorYearList = ({ value, onChange, minTenorYears, maxTenorYears }: Props) => {
    const { translate } = useContext(ReactFormServiceContext);

    const options: any[] = useMemo(() => {
        const result: any[] = [];
        for (let year = minTenorYears; year <= maxTenorYears; year++) {
            result.push({
                id: year * 12 + '',
                label: year + ' ' + (year > 1 ? translate(`mini-calculator.years`) : translate(`mini-calculator.year`)),
            });
        }
        return result;
    }, [minTenorYears, maxTenorYears, translate]);

    return (
        <SelectField
            sx={SelectFieldStyles}
            className="TenorYearList"
            options={options}
            value={value + ''}
            onChange={(event) => onChange(event.target.value as number)}
        />
    );
};

export default TenorYearList;
