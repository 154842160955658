import { AuthenticationData, CountryCode, IFieldState, IFormData, LanguageCode } from '@resursbank/react-form-service';
import { callbackUrl, dataFormPage } from './util';
import { ConsumerLoanTargetPath, ISubmitApplicationResponse, PatchApplicationRequest } from '../domain/types';
import { config } from '../helpers/apiConfig';
import * as Sentry from '@sentry/react';

export const submitApplicationService = async (
    apiProxyPath: string,
    languageCode: LanguageCode,
    countryCode: CountryCode,
    partner: any,
    applicationId: string,
    formFieldsInput: IFieldState[],
    apiKey: string,
    authenticationData?: AuthenticationData,
): Promise<ISubmitApplicationResponse> => {
    const url = `${apiProxyPath}/application/submit/${partner}`;
    const request = {
        method: 'POST',
        headers: config(apiKey, authenticationData),
        body: JSON.stringify(dataApplication(applicationId, formFieldsInput, languageCode, countryCode)),
    };

    try {
        const response = await fetch(url, request);
        if (!response.ok) {
            const error = await response.json();
            if (process.env.NODE_ENV === 'production') {
                Sentry.captureException(new Error(`Submit Application failed: ${JSON.stringify(error)}`));
            }
            throw new Error(error);
        }
        return await response.json();
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(
                new Error(
                    `Submit Application failed: ${error instanceof Error ? error.message : JSON.stringify(error)}`,
                ),
            );
        }
        throw error;
    }
};

export const patchApplicationService = async (request: PatchApplicationRequest): Promise<IFormData> => {
    const url = `${request.proxyBaseUrl}/application/${request.applicationReferenceNumber}/${request.languageCode}`;
    const req = {
        method: 'PATCH',
        headers: config(request.apiKey, request.authenticationData),
        body: JSON.stringify(
            dataFormPage(
                {
                    applicationId: request.applicationReferenceNumber,
                    callbackUrl: callbackUrl(
                        ConsumerLoanTargetPath.callback,
                        request.languageCode,
                        request.countryCode,
                        request.applicationReferenceNumber,
                    ),
                    applicationUrl: callbackUrl(
                        ConsumerLoanTargetPath.ongoing,
                        request.languageCode,
                        request.countryCode,
                        request.applicationReferenceNumber,
                    ),
                },
                null,
                request.formFieldStates,
            ),
        ),
    };

    try {
        const response = await fetch(url, req);
        if (!response.ok) {
            const error = await response.json();
            if (process.env.NODE_ENV === 'production') {
                Sentry.captureException(new Error(`Patch Application failed: ${JSON.stringify(error)}`));
            }
            throw new Error(error);
        }
        return await response.json();
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(
                new Error(
                    `Patch Application failed: ${error instanceof Error ? error.message : JSON.stringify(error)}`,
                ),
            );
        }
        throw error;
    }
};

export const patchExternalConsentService = async (request: PatchApplicationRequest): Promise<IFormData> => {
    const url = `${request.proxyBaseUrl}/application/${request.applicationReferenceNumber}/external_consent`;
    const req = {
        method: 'PATCH',
        headers: config(request.apiKey, request.authenticationData),
        body: JSON.stringify(
            dataFormPage(
                {
                    applicationId: request.applicationReferenceNumber,
                    callbackUrl: callbackUrl(
                        ConsumerLoanTargetPath.callback,
                        request.languageCode,
                        request.countryCode,
                        request.applicationReferenceNumber,
                    ),
                    applicationUrl: callbackUrl(
                        ConsumerLoanTargetPath.ongoing,
                        request.languageCode,
                        request.countryCode,
                        request.applicationReferenceNumber,
                    ),
                },
                null,
                request.formFieldStates,
            ),
        ),
    };

    try {
        const response = await fetch(url, req);
        if (!response.ok) {
            const error = await response.json();
            if (process.env.NODE_ENV === 'production') {
                Sentry.captureException(new Error(`Patch External Consent failed: ${JSON.stringify(error)}`));
            }
            throw new Error(error);
        }
        return await response.json();
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(
                new Error(
                    `Patch External Consent failed: ${error instanceof Error ? error.message : JSON.stringify(error)}`,
                ),
            );
        }
        throw error;
    }
};

const dataApplication = (
    applicationId: string,
    formFieldsInput: IFieldState[],
    languageCode: string,
    countryCode: string,
) => {
    const applicationData: { values: any; callbackUrl?: string; applicationUrl?: string } = dataFormPage(
        { applicationId },
        null,
        formFieldsInput,
    );

    applicationData.callbackUrl = callbackUrl(ConsumerLoanTargetPath.callback, languageCode, countryCode, '');
    applicationData.applicationUrl = callbackUrl(ConsumerLoanTargetPath.ongoing, languageCode, countryCode, '');

    return applicationData;
};

/**
 * This is a temporary endpoint to trigger the next step in the orchestration process after signing the application.
 *
 * @param request
 * @returns a promise with a void
 */
export const afterSignApplication = async (request: PatchApplicationRequest): Promise<void> => {
    const url = `${request.proxyBaseUrl}/application/${request.applicationReferenceNumber}/signed`;
    const req = {
        method: 'GET',
        headers: config(request.apiKey, request.authenticationData),
    };

    try {
        await fetch(url, req);
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(
                new Error(
                    `After Sign Application failed: ${error instanceof Error ? error.message : JSON.stringify(error)}`,
                ),
            );
        }
        throw error;
    }
};
