import useTranslation from '../../hooks/useTranslation';
import RadioField from './RadioField';
import React from 'react';

interface Props {
    name: string;
}

const AccommodationRadios = ({ name }: Props) => {
    const translate = useTranslation();

    const accommodationOptions = [
        {
            id: 'APARTMENT',
            label: translate('field.accommodation.option.apartment'),
        },
        {
            id: 'CONDOMINIUM',
            label: translate('field.accommodation.option.condominium'),
        },
        {
            id: 'DETACHED_HOUSE',
            label: translate('field.accommodation.option.detached-house'),
        },
        {
            id: 'WITH_PARENTS',
            label: translate('field.accommodation.option.with-parents'),
        },
    ];

    return <RadioField name={name} options={accommodationOptions} />;
};

export default AccommodationRadios;
