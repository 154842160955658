export const enum ConsumerLoanParameter {
    /**
     * See ConsumerLoanInitPayload
     */
    initPayload = 'init',
    applicationReferenceNumber = 'applicationReferenceNumber',
    externalConsentCorrelationId = 'correlation_id',
    callbackStatus = 'callback_status',
    locale = 'locale',
    pageNumber = 'pageNumber',
}
