import React from 'react';
import { selectValidAccessToken } from '@resursbank/react-form-service';
import { useEffect } from 'react';
import { useAppSelector } from '../../hooks';
import useGoToInitPage from '../../hooks/useGoToInitPage';

/**
 * This compontent will check if the user has an authentication and will automatically
 * dispatch to the next page
 */
const NextPageOnAuthorized = () => {
    const goToPage = useGoToInitPage();
    const { currentPage: currentPageNumber, action } = useAppSelector((state) => state.form);
    const accessToken = useAppSelector(selectValidAccessToken);

    useEffect(() => {
        if (accessToken) {
            if (action === 'previous') {
                goToPage(currentPageNumber - 1, action);
            } else {
                goToPage(currentPageNumber + 1, action);
            }
        }
    }, [accessToken]);

    return <></>;
};

export default NextPageOnAuthorized;
