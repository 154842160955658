import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    ErrorMessage,
    FormData,
    ReactFormServiceContext,
    StatusProcessingPage,
    goToPage,
    useAuthentication,
} from '@resursbank/react-form-service';
import { getOngoingFormPageService } from '../../api/applicationForm';
import OngoingStaticPage from './OngoingStaticPage';
import { patchApplicationService } from '../../api/application';
import {
    ConsumerLoanTargetPath,
    GetOngoingFormPageRequest,
    PatchApplicationRequest,
    applicationStatusFromFormName,
    doPollForApplicationStatus,
} from '../../domain/types';
import { Box } from '@mui/material';
import { ConsumerLoanParameter } from '../../domain/types/ConsumerLoanParameter';
import { getPath } from '../../helpers/url';
import useSettings from '../../hooks/useSettings';

const REFRESH_STATUS_INTERVAL = 5000;

const usePollForStatus = (getOngoingFormPage: () => void) => {
    const { useStaticPage, formName } = useAppSelector((state) => state.form);

    const staticPageApplicationStatus = useStaticPage ? applicationStatusFromFormName(formName) : undefined;
    const doPollForApplication = staticPageApplicationStatus && doPollForApplicationStatus(staticPageApplicationStatus);

    useEffect(() => {
        const timer = setInterval(pollForApplication, REFRESH_STATUS_INTERVAL);
        return () => clearInterval(timer);
    }, [doPollForApplication]);

    const pollForApplication = () => {
        if (doPollForApplication) {
            getOngoingFormPage();
        }
    };
};

const OngoingPage = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { translate } = useContext(ReactFormServiceContext);
    const { languageCode, countryCode } = useSettings();
    const { useStaticPage } = useAppSelector((state) => state.form);
    const [showSpinner, setShowSpinner] = useState(true);
    const applicationReferenceNumber = useMemo(
        () => params[ConsumerLoanParameter.applicationReferenceNumber],
        [params],
    );

    const dispatchGetOngoingFormPage = useCallback(() => {
        dispatch(
            goToPage({
                service: getOngoingFormPageService,
                payload: {
                    applicationReferenceNumber,
                } as GetOngoingFormPageRequest,
                action: 'next',
            }),
        )
            .unwrap()
            .catch(() => navigate(getPath(ConsumerLoanTargetPath.error, languageCode, countryCode)))
            .finally(() => setShowSpinner(false));
    }, [applicationReferenceNumber, languageCode, countryCode]);

    useAuthentication();
    usePollForStatus(dispatchGetOngoingFormPage);

    const callbackStatus = params[ConsumerLoanParameter.callbackStatus];

    useEffect(() => {
        setShowSpinner(true);
        dispatchGetOngoingFormPage();
    }, [applicationReferenceNumber]);

    const patchApplication = () => {
        setShowSpinner(true);
        dispatch(
            goToPage({
                service: patchApplicationService,
                payload: {
                    applicationReferenceNumber,
                    languageCode,
                } as PatchApplicationRequest,
                action: 'next',
            }),
        )
            .unwrap()
            .catch(() => navigate(getPath(ConsumerLoanTargetPath.error, languageCode, countryCode)))
            .finally(() => {
                setShowSpinner(false);
                window.scrollTo({ top: 0 });
            });
    };

    const errorElement = () => {
        if (callbackStatus === 'failure') {
            return (
                <Box paddingBottom={4}>
                    <ErrorMessage
                        title={translate(`page.authentication.submit.log.in.error.title`)}
                        description={translate(`page.authentication.submit.log.in.error.description`)}
                    />
                </Box>
            );
        }
        return undefined;
    };

    if (useStaticPage) {
        return <OngoingStaticPage />;
    }

    if (showSpinner) {
        return <StatusProcessingPage title={''} body={''} />;
    }

    return (
        <>
            <FormData
                isApplicationLoading={false}
                onNextPage={() => patchApplication()}
                onPreviousPage={() => {}}
                applicationError={undefined}
                ErrorElement={errorElement()}
            />
        </>
    );
};

export default OngoingPage;
