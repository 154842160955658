import React, { useMemo } from 'react';
import { useAppSelector } from '../../hooks';
import {
    ApplicationPageFooter,
    CountryCode as ApplicationCountryCode,
} from '@resursbank/mui/components/ApplicationPage/ApplicationPageFooter';
import { packageVersion } from '@resursbank/react-form-service';
import { Footer, HiddenVersion } from './PageFooter.styles';
import useSettings from '../../hooks/useSettings';

const PageFooter = () => {
    const { countryCode } = useSettings();

    // country code types differ between react-form-service and resursbank/mui
    const country = useMemo<ApplicationCountryCode>(() => {
        if (countryCode === 'Unknown') {
            return 'SE';
        }

        return countryCode;
    }, [countryCode]);
    return (
        <Footer>
            <ApplicationPageFooter country={country} />
            <HiddenVersion>
                consumerloan-application-app {APP_VERSION}
                <br />
                react-form-service {packageVersion}
            </HiddenVersion>
        </Footer>
    );
};

export default PageFooter;
