import React, { useEffect } from 'react';
import { useAppDispatch } from '../../hooks';
import { invalidateStore } from '../../store/store';
import { Box, CircularProgress } from '@mui/material';
import useSettings from '../../hooks/useSettings';

const LogoutPage = () => {
    const dispatch = useAppDispatch();
    const { originatingUrl } = useSettings();

    useEffect(() => {
        dispatch(invalidateStore);
    }, [dispatch]);

    useEffect(() => {
        const timer = setTimeout(() => window.location.assign(originatingUrl), 2000);
        return () => clearTimeout(timer);
    }, [dispatch, originatingUrl]);

    return (
        <Box display="flex" justifyContent="center" marginBottom={2} marginTop={2}>
            <CircularProgress />
        </Box>
    );
};

export default LogoutPage;
