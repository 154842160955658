import React from 'react';
import SelectField from '../SelectField/SelectField';

interface Props {
    name: string;
    label: string;
}

const YearField = ({ name, label }: Props) => {
    const yearOptions: any[] = [];
    for (let i = new Date().getFullYear(); i > 1973; i--) {
        yearOptions.push({ id: i + '', label: i + '' });
    }

    return (
        <SelectField label={label} name={name}>
            {yearOptions}
        </SelectField>
    );
};

export default YearField;
