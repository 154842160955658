import React, { Component, ErrorInfo, ReactNode } from 'react';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import * as Sentry from '@sentry/react';
import NorwegianStyleWrapper from '../NorwegianStyleWrapper/NorwegianStyleWrapper';

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(error, {
                extra: { componentStack: errorInfo.componentStack },
            });
        }
    }

    public render() {
        if (this.state.hasError) {
            return (
                <NorwegianStyleWrapper>
                    <ErrorPage />
                </NorwegianStyleWrapper>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
