import React, { useEffect, useState } from 'react';
import Title from '../components/Title/Title';
import useTranslation from '../hooks/useTranslation';
import ShowDetailsModal from '../components/ShowDetailsModal/ShowDetailsModal';
import RadioField from '../components/RadioField/RadioField';
import TextInputField from '../components/TextInputField/TextInputField';
import Select from '../components/SelectField/SelectField';
import Subtitle from '../components/Subtitle/Subtitle';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Kyc, PepQuestion } from '../types/PepTypes';
import { getSurveyService } from '../../api/getSurvey';
import useSettings from '../../hooks/useSettings';
import { saveSurveyService } from '../../api/saveSurvey';
import useFieldState from '../hooks/useFieldState';
import { IFieldState, addOrUpdateFormField } from '@resursbank/react-form-service';
import useWizard from '../hooks/useWizard';

const shouldRenderQuestion = (question: PepQuestion, pepAnswerFieldStates: IFieldState[]) => {
    return (
        !question.displayCondition ||
        question.displayCondition.optionId.toString() ===
            pepAnswerFieldStates.find((f) => f.fieldName === 'pep_' + question.displayCondition?.questionId)?.value
    );
};

const useFieldValueClearer = (data: Kyc | undefined, pepAnswerFieldStates: IFieldState[]) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        data?.groups
            .flatMap((group) => group.questions)
            .filter((question) => !shouldRenderQuestion(question, pepAnswerFieldStates))
            .filter(
                (question) => pepAnswerFieldStates.find((s) => s.fieldName === 'pep_' + question.name)?.value !== '',
            )
            .forEach((question) => {
                dispatch(addOrUpdateFormField({ fieldName: 'pep_' + question.name, value: '' }));
            });
    }, [data, pepAnswerFieldStates, dispatch]);
};

const PepPage = () => {
    const translate = useTranslation();
    const { setSection, handleStep } = useWizard();
    setSection(0, translate('page.how-to-apply.subtitle.about-you'), ['next']);
    const [data, setData] = useState<Kyc | undefined>(undefined);
    const { authenticationData } = useAppSelector((state) => state.authentication);
    const { apiKey, proxyBaseUrl } = useSettings();
    const govId = useFieldState('applicantGovernmentId')?.value;

    const pepAnswerFieldStates = useAppSelector((state) =>
        state.form.formFieldStates.filter((fieldState) => fieldState.fieldName.startsWith('pep_')),
    );

    useFieldValueClearer(data, pepAnswerFieldStates);

    const fetchData = async () => {
        const pepData = await getSurveyService(apiKey, true, proxyBaseUrl, authenticationData);
        setData(pepData);
    };

    useEffect(() => {
        fetchData();
    }, []);

    handleStep(async () => {
        const answers = pepAnswerFieldStates
            .filter((field) => field.value !== '')
            .map((fieldState) => ({
                category: 'PEP',
                question: fieldState.fieldName.replace('pep_', ''),
                answer: fieldState.value,
            }));

        if (!answers.length) {
            throw new Error('Validation errors');
        }
        await saveSurveyService(apiKey, proxyBaseUrl, govId?.toString() || '', answers, authenticationData);
    });

    return (
        <>
            {data?.groups.map((pep, index) => (
                <div key={index} style={{ marginBottom: '45px' }}>
                    <Title text={pep?.title} />
                    <ShowDetailsModal
                        label={translate('dialog.dont-know.label')}
                        dialogTitle={translate('dialog.dont-know.label')}
                        dialogContent={translate('page.pep.dialog.content')}
                    />

                    {pep.questions
                        .filter((question) => shouldRenderQuestion(question, pepAnswerFieldStates))
                        .map((question, i) => {
                            return (
                                <div key={i}>
                                    <Subtitle text={question.label} />
                                    {question.type === 'SINGLE_SELECTION_DROPDOWN' ? (
                                        <Select
                                            name={'pep_' + question.name.toString()}
                                            label={question.label}
                                            options={
                                                question.options?.map((option) => ({
                                                    id: option.id.toString(),
                                                    label: option.label,
                                                })) || []
                                            }
                                        />
                                    ) : question.type === 'SINGLE_SELECTION' ? (
                                        <RadioField
                                            name={'pep_' + question.name.toString()}
                                            options={
                                                question.options?.map((option) => ({
                                                    id: option.id.toString(),
                                                    label: option.label,
                                                })) || []
                                            }
                                        />
                                    ) : question.type === 'FREE_TEXT' ? (
                                        <TextInputField
                                            name={'pep_' + question.name.toString()}
                                            label={question.label}
                                        />
                                    ) : (
                                        <RadioField
                                            name={'pep_' + question.name.toString()}
                                            options={
                                                question.options?.map((option) => ({
                                                    id: option.id.toString(),
                                                    label: option.label,
                                                })) || []
                                            }
                                        />
                                    )}
                                </div>
                            );
                        })}
                </div>
            ))}
        </>
    );
};

export default PepPage;
