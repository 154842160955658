import React from 'react';
import { EditButton, Label, LabelStyles, SummaryRowStyles } from './Summary.styles';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen as faPenSolid } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
    label: string;
    value: string;
};

const SummaryRow = ({ label, value }: Props) => {
    return (
        <SummaryRowStyles>
            <LabelStyles>
                <Label>{label}</Label>
                <span>{value}</span>
            </LabelStyles>
            <Button variant="text" sx={EditButton}>
                <FontAwesomeIcon icon={faPenSolid as IconProp} color={'#1A1E218A'} />
            </Button>
        </SummaryRowStyles>
    );
};

export default SummaryRow;
