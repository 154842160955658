import * as Sentry from '@sentry/react';

export const createAuthenticationCodeService = async (
    oAuthBaseUrl: string,
    apiKey: string,
    username: string,
    password: string,
): Promise<string> => {
    const url = `${oAuthBaseUrl}/code`;
    const request = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            apikey: apiKey,
        },
        body: JSON.stringify({
            username,
            password,
        }),
    };

    try {
        const response = await fetch(url, request);
        if (!response.ok) {
            const error = await response.json();
            if (process.env.NODE_ENV === 'production') {
                Sentry.captureException(
                    new Error(`Create authentication code service failed: ${JSON.stringify(error)}`),
                );
            }
            throw new Error(error);
        }
        return await response.text();
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(
                new Error(
                    `Enrcypt payload service service failed: ${
                        error instanceof Error ? error.message : JSON.stringify(error)
                    }`,
                ),
            );
        }
        throw error;
    }
};
