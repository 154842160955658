import React from 'react';
import { Typography } from '@mui/material';
import useTranslation from '../../hooks/useTranslation';

type Props = {
    text: string;
};

const Title = ({ text }: Props) => {
    const translate = useTranslation();
    return (
        <Typography variant={'h4'} style={{ margin: '20px 0' }}>
            {translate(text)}
        </Typography>
    );
};

export default Title;
