import React from 'react';
import { useAppSelector } from '../../hooks';
import { selectFieldValue } from '@resursbank/react-form-service';

export type FieldFormattingProps = {
    placeholder?: string;
    clean?: (value: string | undefined) => string | undefined;
    format?: (value: string | undefined) => string | undefined;
};

const useFieldFormatting = (name: string, props: FieldFormattingProps | undefined) => {
    const _initialValue = useAppSelector(selectFieldValue)(name);
    const initialValue = (): string | undefined => {
        if (Array.isArray(_initialValue)) {
            return _initialValue[0];
        }
        if (typeof _initialValue === 'number') {
            return _initialValue.toString();
        }
        return _initialValue;
    };

    const formatValue = (value: string | undefined): string | undefined => {
        if (props?.format) {
            return props.format(value);
        }
        return value;
    };

    const cleanValue = (value: string | undefined): string | undefined => {
        if (props?.clean) {
            return props.clean(value);
        }

        return value;
    };

    return { initialValue, formatValue, cleanValue };
};

export default useFieldFormatting;
