import React, { useMemo } from 'react';
import Title from '../components/Title/Title';
import Description from '../components/Description/Description';
import useTranslation from '../hooks/useTranslation';
import MonetaryField from '../components/MonetaryField/MonetaryField';
import SelectField from '../components/SelectField/SelectField';
import Group from '../components/Group/Group';
import useWizard from '../hooks/useWizard';

const LoanAmountPage = () => {
    const translate = useTranslation();
    const { setSection } = useWizard();
    setSection(1, translate('page.how-to-apply.subtitle.about-the-loan'), ['next']);

    const tenorOptions = useMemo(() => {
        const result = [];
        for (let years = 1; years <= 15; years++) {
            result.push({
                label: years + ' ' + (years > 1 ? translate('years') : translate('year')),
                id: years * 12 + '',
            });
        }
        return result;
    }, [translate]);

    return (
        <>
            <Title text={translate('page.loan-amount.title')} />
            <Description text={translate('page.loan-amount.description')} />
            <Group>
                <MonetaryField name="amount" label={translate('field.amount.label')} required={true} />
            </Group>
            <Group>
                <SelectField options={tenorOptions} name="tenor" label={translate('field.tenor.label')} />
            </Group>
        </>
    );
};

export default LoanAmountPage;
