import React from 'react';
import Title from '../components/Title/Title';
import Description from '../components/Description/Description';
import useTranslation from '../hooks/useTranslation';
import AccommodationRadios from '../components/RadioField/AccommodationRadios';
import useWizard from '../hooks/useWizard';
import useWizardValidation from '../hooks/useWizardValidation';

const CoApplicantAccommodationPage = () => {
    const translate = useTranslation();
    const { setSection } = useWizard();
    setSection(1, translate('page.how-to-apply.subtitle.about-the-loan'), ['next']);
    useWizardValidation(['coapplicantHabitationForm']);

    return (
        <>
            <Title text={translate('page.co-applicant-accomodation.label')} />
            <Description text={translate('page.co-applicant-accomodation.description')} />
            <AccommodationRadios name={'coapplicantHabitationForm'} />
        </>
    );
};

export default CoApplicantAccommodationPage;
