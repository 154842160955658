import React, { useEffect } from 'react';
import Title from '../components/Title/Title';
import useTranslation from '../hooks/useTranslation';
import Description from '../components/Description/Description';
import { useAppDispatch } from '../../hooks';
import { IFieldState, addOrUpdateFormField } from '@resursbank/react-form-service';
import ListField from '../components/ListField/ListField';
import Icon from '../components/Icon/Icon';
import { faCoins, faGift, faUser, faBuildingColumns, faShieldAlt, faPen } from '@fortawesome/pro-solid-svg-icons';
import ShowDetailsModal from '../components/ShowDetailsModal/ShowDetailsModal';
import useWizard from '../hooks/useWizard';
import useWizardValidation from '../hooks/useWizardValidation';

const HowToApplyPage = () => {
    const translate = useTranslation();
    const dispatch = useAppDispatch();
    const { setSection } = useWizard();
    setSection(0, translate('page.how-to-apply.subtitle.about-you'), ['next']);
    useWizardValidation([]);

    useEffect(() => {
        dispatch(
            addOrUpdateFormField({
                fieldName: 'version',
                value: '1',
            } as IFieldState),
        );
    }, [dispatch]);

    return (
        <>
            <Title text={translate('page.how-to-apply.title')} />
            <ShowDetailsModal
                label={translate('dialog.how-to-apply.label')}
                dialogTitle={translate('dialog.how-to-apply.label')}
                dialogContent={translate('page.how-to-apply.dialog.content')}
            />
            <Description text={translate('page.how-to-apply.description')} />
            <ListField
                name="howToApplySteps"
                items={[
                    {
                        index: '1',
                        icon: <Icon icon={faUser} />,
                        label: translate('page.how-to-apply.subtitle.about-you'),
                    },
                    {
                        index: '2',
                        icon: <Icon icon={faCoins} />,
                        label: translate('page.how-to-apply.subtitle.about-the-loan'),
                    },
                    {
                        index: '3',
                        icon: <Icon icon={faGift} />,
                        label: translate('page.how-to-apply.subtitle.our-offer'),
                    },
                    {
                        index: '4',
                        icon: <Icon icon={faBuildingColumns} />,
                        label: translate('page.how-to-apply.subtitle.your-account'),
                    },
                    {
                        index: '5',
                        icon: <Icon icon={faShieldAlt} />,
                        label: translate('page.how-to-apply.subtitle.kyc'),
                    },
                    {
                        index: '6',
                        icon: <Icon icon={faPen} />,
                        label: translate('page.how-to-apply.subtitle.finalize'),
                    },
                ]}></ListField>
        </>
    );
};

export default HowToApplyPage;
