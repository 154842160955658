import React from 'react';
import { Theme, useTheme } from '@mui/material';
import useTranslation from '../../hooks/useTranslation';
import { WizardPageAction as PageAction } from '../../../store/wizard/wizard';
import Button from '../Button/Button';
import { BankIDLogo, ResursLogo } from '@resursbank/mui';
import useWizard from '../../hooks/useWizard';
import { Spin } from '@resursbank/react-form-service';

type WizardPageActionProps = {
    action: PageAction;
};

const style = (isNextDisabled: boolean, theme: Theme) => ({
    background: isNextDisabled ? theme.palette.grey['400'] : undefined,
});

const Spinner = () => {
    return (
        <div style={{ marginLeft: '6px' }}>
            <Spin>
                <ResursLogo size="sm" />
            </Spin>
        </div>
    );
};

const WizardPageAction = ({ action }: WizardPageActionProps) => {
    const theme = useTheme();
    const { nextStep, isLoading, isNextDisabled } = useWizard();
    const translate = useTranslation();

    const next = async () => {
        if (isNextDisabled) {
            return;
        }

        await nextStep();
    };

    switch (action) {
        case 'next':
            return (
                <Button onClick={next} fill disabled={isLoading} sx={style(isNextDisabled, theme)}>
                    {translate('page.action.next')}
                    {isLoading && <Spinner />}
                </Button>
            );

        case 'submit':
            return (
                <Button onClick={next} fill disabled={isLoading} sx={style(isNextDisabled, theme)}>
                    {translate('page.action.submit')}
                    {isLoading && <Spinner />}
                </Button>
            );

        case 'collect-details':
            return (
                <Button onClick={next} fill disabled={isLoading} sx={style(isNextDisabled, theme)}>
                    {translate('page.action.verify-income')}{' '}
                    <BankIDLogo size="sm" color={theme.palette.primary.contrastText} style={{ marginLeft: '12px' }} />
                    {isLoading && <Spinner />}
                </Button>
            );
    }
};

export default WizardPageAction;
