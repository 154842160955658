import { SxProps } from '@mui/material';
import styled from 'styled-components';

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 32px 16px;
    background: #ffffff;
    border: 1px solid #ced4da;
    border-radius: 24px;
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px;
    align-items: flex-start;
    min-width: 500px;
    @media only screen and (max-width: 600px) {
        min-width: 100%;
    }
`;

export const CardTitle = styled.p`
    font-family: 'Inter', sans-serif;
    font-size: 26px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    padding-bottom: 24px;
    margin: 0;
    color: #003c3c;
    padding-bottom: 18px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
`;

export const CardText = styled.p`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    margin: 0;
    color: #212529;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
`;

export const InfoCard = styled(Card)<{ marginBottom?: number }>`
    position: relative;
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px` || 0};
    background-color: #00524f;
    border: none;
    overflow: hidden;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 55%;
        height: 100%;

        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" id="a" data-name="Lager 1" viewBox="0 0 440 270"><defs><style> .b { fill: %23002d28; } </style></defs><path class="b" d="m428.1,0c15.6,33.6,14.7,61.3,3.7,92.2-.2.5-.4,1.1-.6,1.7-2.7,7.8-8.9,25.5-22.4,43.5-18,23.8-45.6,70.3-19.2,132.7H0S0,0,0,0h428.1Z"/></svg>');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
    }
`;

export const GridBoxStyles: SxProps = {
    height: '58px',
    lineHeight: '3.5',
    color: '#FFFFFF',
};
export const LoanAmountGridStyles: SxProps = {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '58px',
};
export const GridStyles: SxProps = {
    margin: '15px 0 15px 0',
};

export const TenorGridStyles: SxProps = {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '58px',
    '& .MuiFormControl-fullWidth': {
        maxWidth: '200px',
    },
};
export const MonthlyAmountGridStyles: SxProps = {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '58px',
    lineHeight: '1.5',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: '700',
};

export const TypographyStyles: SxProps = {
    fontSize: '18px',
    lineHeight: '56px',
};
