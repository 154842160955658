import { toEnvironment } from '@resursbank/react-form-service';
import secrets from './secrets';

export const environment = secrets.REACT_APP_ENV;

export interface IEnvironmentConfig {
    consentScope: string | undefined;
    name: string;
    oAuthBaseUrl: string;
    proxyBaseUrl: string;
    apiKey: string;
    applyFormVersion?: string;
    surveyId: string;
}

export interface ICommonConfig {
    maxIdleTime: number;
    logoutWarnTime: number;
}

export const appConfig: ICommonConfig = {
    maxIdleTime: 300,
    logoutWarnTime: 30,
};

const local: IEnvironmentConfig = {
    name: 'local',
    oAuthBaseUrl: 'https://apigw-dev.test.resurs.loc/api/cl_loanapp_proxy_service/auth',
    proxyBaseUrl: 'https://apigw-dev.test.resurs.loc/api/cl_loanapp_proxy_service/v1/{countryCode}',
    apiKey: '4757f7aec1de2d4b97fdd0d30bb68959',
    //applyFormVersion: '2',
    consentScope: 'cl-sbl-NO',
    surveyId: 'a37eb1a5Z6ec2',
};

const development: IEnvironmentConfig = {
    name: 'dev',
    // TODO We should aim to use the one for DEV, but since it is not working we use the one for integration for now
    // oAuthBaseUrl: 'https://apigw-dev.test.resurs.loc/api/oba_auth_service/oauth2'
    oAuthBaseUrl: 'https://apigw-dev.test.resurs.loc/api/cl_loanapp_proxy_service/auth',
    proxyBaseUrl: 'https://apigw-dev.test.resurs.loc/api/cl_loanapp_proxy_service/v1/{countryCode}',
    apiKey: '4757f7aec1de2d4b97fdd0d30bb68959',
    //applyFormVersion: '2',
    consentScope: 'cl-sbl-NO',
    surveyId: 'a37eb1a5Z6ec2',
};

const integration: IEnvironmentConfig = {
    name: 'integration',
    oAuthBaseUrl: 'https://apigw.integration.resurs.com/api/cl_loanapp_proxy_service/auth',
    proxyBaseUrl: 'https://apigw.integration.resurs.com/api/cl_loanapp_proxy_service/v1/{countryCode}',
    apiKey: secrets.apikey,
    consentScope: 'cl-sbl-NO',
    surveyId: 'a37eb1a5Z6ec2',
};

const production: IEnvironmentConfig = {
    name: 'production',
    oAuthBaseUrl: 'https://apigw.resurs.com/api/cl_loanapp_proxy_service/auth',
    proxyBaseUrl: 'https://apigw.resurs.com/api/cl_loanapp_proxy_service/v1/{countryCode}',
    apiKey: secrets.apikey,
    consentScope: 'cl-sbl-NO',
    surveyId: 'a37eb1a5Z6ec2',
};

export const getEnvironmentConfig = (): IEnvironmentConfig => {
    switch (toEnvironment(environment)) {
        case 'Development':
            return development;
        case 'Integration':
            return integration;
        case 'Production':
            return production;
        default:
            return local;
    }
};
