import React from 'react';
import TextInputField from '../TextInputField/TextInputField';

type EmailFieldProps = { name: string; label?: string; required?: boolean };

const EmailField = (props: EmailFieldProps) => {
    return (
        <TextInputField
            type="email"
            validation={{
                required: props.required,
                requiredErrorKey: 'validation.error.email.required',
                format: '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
                formatErrorKey: 'validation.error.email.format',
            }}
            {...props}
        />
    );
};

export default EmailField;
