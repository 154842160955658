import { IFieldState } from '@resursbank/react-form-service';
import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';

export const selectFieldStateByName = createSelector(
    (state: RootState) => state,
    (state) =>
        (fieldName: string): IFieldState | undefined =>
            state.form.formFieldStates.find((fieldState) => fieldState.fieldName === fieldName),
);
