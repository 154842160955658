import { SxProps } from '@mui/material';
import styled from 'styled-components';

interface RootProps {
    countryCode?: string;
}

export const Root = styled.div<RootProps>`
    position: relative;
    min-height: calc(100vh - 266px);
    @media only screen and (max-width: 752px) {
        background-color: ${(props) => (props.countryCode === 'NO' ? 'white' : '#f8f9fa')};
    }
    @media only screen and (max-width: 599px) {
        min-height: ${(props) => (props.countryCode === 'NO' ? undefined : '100vh')};
    }
`;

export const RootBoxStyles = (countryCode?: string): SxProps => ({
    maxWidth: '560px',
    margin: '30px auto',
    '@media (max-width: 752px)': { padding: '16px', paddingBottom: '120px' },
    '@media (min-width: 752px)': { borderRadius: '20px', padding: '48px', paddingBottom: '120px', minHeight: '20vh' },
    '@media (max-height: 900px)': {
        minHeight: ' 40vh',
    },
    backgroundColor: countryCode === 'NO' ? 'white' : '#f8f9fa',
});
