import { goToPage, selectAllPageFieldStates, selectFieldValue } from '@resursbank/react-form-service';
import { getFormPageService } from '../api/applicationForm';
import { getEnvironmentConfig } from '../config';
import { useAppDispatch, useAppSelector } from './hooks';
import useSettings from './useSettings';
import { GetFormPageRequest } from '../domain/types';
import { useCallback } from 'react';

const useGoToInitPage = () => {
    const dispatch = useAppDispatch();
    const config = getEnvironmentConfig();
    const settings = useSettings();

    const currentFields = useAppSelector(selectAllPageFieldStates)();
    const { applicationId, channel } = useAppSelector((state) => state.application);
    const governmentId = useAppSelector(selectFieldValue)('applicantGovernmentId');

    const callback = useCallback(
        (pageNumber: number, action: string) => {
            // TODO dispatch with all form parameters
            // Tenors may be split up with several values with ";", this will take the first value and convert it to a number
            const tenor = +('' + currentFields.find((f) => f.fieldName === 'tenor')?.value).split(';')[0];
            const amount = currentFields.find((f) => f.fieldName === 'amount')?.value;
            const version = config.applyFormVersion;
            if (applicationId && amount && tenor && governmentId) {
                return dispatch(
                    goToPage({
                        service: getFormPageService,
                        payload: {
                            page: pageNumber,
                            version: version,
                            governmentId: governmentId,
                            applicationId: applicationId,
                            tenor: tenor,
                            amount: amount,
                            languageCode: settings.languageCode,
                            countryCode: settings.countryCode,
                            channel,
                        } as GetFormPageRequest,
                        action: action,
                    }),
                );
            }

            return Promise.resolve();
        },
        [governmentId],
    );

    return callback;
};

export default useGoToInitPage;
