import React from 'react';
import { SelectFieldOption } from '@resursbank/mui/components/SelectField/SelectFieldOption';
import useTranslation from '../../hooks/useTranslation';
import { useMemo } from 'react';
import RadioField from '../RadioField/RadioField';

type Props = {
    name: string;
};

const LivingConditionField = ({ name }: Props) => {
    const translate = useTranslation();
    const livingConditionOptions: SelectFieldOption[] = useMemo<SelectFieldOption[]>(
        () => [
            {
                id: 'MARRIED',
                label: translate('field.living-condition.option.married.label'),
            },
            {
                id: 'DOMESTIC_PARTNER',
                label: translate('field.living-condition.option.domestic-partner.label'),
            },
            {
                id: 'SINGLE',
                label: translate('field.living-condition.option.single.label'),
            },
        ],
        [translate],
    );

    return <RadioField name={name} options={livingConditionOptions} />;
};

export default LivingConditionField;
