import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getIntervalsService, getPriceService } from '../../api/price';
import { IFetchIntervalsParams, IFetchPriceParams, IPriceData, IPriceIntervalData, IPriceState } from './priceTypes';
import { RootState } from '../store';

export const fetchPrice = createAsyncThunk<IPriceData, IFetchPriceParams, { state: RootState }>(
    'price/fetch-price',
    async (parameters, { getState }) => {
        const apiKey = getState().settings.apiKey;
        return await getPriceService(apiKey, parameters);
    },
);

export const fetchIntervals = createAsyncThunk<IPriceIntervalData, IFetchIntervalsParams, { state: RootState }>(
    'price/fetch-intervals',
    async (parameters, { getState }) => {
        const apiKey = getState().settings.apiKey;
        return await getIntervalsService(apiKey, parameters);
    },
);

export const initialPriceState = {
    information: undefined,
    intervals: undefined,
    error: undefined,
} as IPriceState;

const priceSlice = createSlice({
    name: 'price',
    initialState: initialPriceState,
    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(fetchPrice.fulfilled, (state, action) => {
            state.information = action.payload;
            state.error = undefined;
        });
        builder.addCase(fetchPrice.pending, (state) => {
            state.information = undefined;
        });
        builder.addCase(fetchPrice.rejected, (state, action) => {
            state.information = undefined;
            state.error = action.error.message;
        });
        builder.addCase(fetchIntervals.fulfilled, (state, action) => {
            state.intervals = action.payload;
            state.error = undefined;
        });
        builder.addCase(fetchIntervals.pending, (state) => {
            state.intervals = undefined;
        });
        builder.addCase(fetchIntervals.rejected, (state, action) => {
            state.intervals = undefined;
            state.error = action.error.message;
        });
    },
});

export default priceSlice.reducer;
