import { useEffect } from 'react';
import { sendGTMPageViewEvent } from '../helpers/gtm';

export const useGTMPageViewEvent = (path: string, title: string, currentPage: number, pageNumber: number) => {
    useEffect(() => {
        if (currentPage === pageNumber) {
            sendGTMPageViewEvent(path, title);
        }
    }, [path, title, currentPage, pageNumber]);
};
