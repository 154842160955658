import { AuthenticationData } from '@resursbank/react-form-service';
import { config } from '../helpers/apiConfig';
import { KycAnswers } from '../v2/types/PepTypes';

export const saveSurveyService = async (
    apiKey: string,
    proxyBaseUrl: string,
    govId: string,
    answers: KycAnswers[],
    authenticationData?: AuthenticationData,
): Promise<string> => {
    const url = `${proxyBaseUrl}/know_your_customer/save_survey_answers`;
    const request = {
        method: 'POST',
        headers: config(apiKey, authenticationData),
        body: JSON.stringify({
            kycDetails: {
                governmentId: {
                    customerType: 'NATURAL',
                    countryCode: 'SE',
                    id: govId,
                },
                kycAnswers: answers,
            },
        }),
    };

    try {
        const response = await fetch(url, request);

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }
        return await response.text();
    } catch (error) {
        console.error(error);
        throw error;
    }
};
