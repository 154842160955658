import React from 'react';
import { FormHelperText, SxProps } from '@mui/material';
import { useAppSelector } from '../../../hooks';
import { selectFieldStateByName } from '../../../store/applicationform/applicationFormSelectors';

const validationErrorStyle: SxProps = {
    marginLeft: 0,
    fontWeight: 700,
    paddingTop: '16px',
};

type Props = {
    fieldName: string;
};

const ValidationError = ({ fieldName }: Props) => {
    const fieldState = useAppSelector(selectFieldStateByName)(fieldName);
    return (
        fieldState?.hasError && (
            <FormHelperText error={true} sx={validationErrorStyle}>
                {fieldState?.errorMessage}
            </FormHelperText>
        )
    );
};

export default ValidationError;
