import React from 'react';
import { Box, Link, useTheme } from '@mui/material';
import styles from './Calculator.styles';
import useTranslation from '../../hooks/useTranslation';

interface MobileActionsProps {
    onClose: () => void;
    onUpdate: () => void;
    block: boolean;
}

const MobileActions: React.FC<MobileActionsProps> = ({ onClose, onUpdate, block }) => {
    const theme = useTheme();
    const translate = useTranslation();

    return (
        <Box sx={styles(theme).mobileBox}>
            <hr style={{ margin: '0' }} />
            <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={onClose}
                sx={styles(theme).mobileCancelLink}>
                {translate('page.calculator.button.cancel')}
            </Link>
            <hr style={{ margin: '0' }} />
            <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={onUpdate}
                disabled={block}
                sx={styles(theme).mobileUpdateLink}>
                {translate('page.calculator.button.update')}
            </Link>
        </Box>
    );
};

export default MobileActions;
