import React from 'react';
import Title from '../components/Title/Title';
import Description from '../components/Description/Description';
import useTranslation from '../hooks/useTranslation';
import LivingConditionField from '../components/LivingConditionField/LivingConditionField';
import CounterField from '../components/CounterField/CounterField';
import ShowDetailsModal from '../components/ShowDetailsModal/ShowDetailsModal';
import Subtitle from '../components/Subtitle/Subtitle';
import useWizard from '../hooks/useWizard';
import useWizardValidation from '../hooks/useWizardValidation';

const LivingConditionPage = () => {
    const translate = useTranslation();
    const { setSection } = useWizard();
    setSection(0, translate('page.how-to-apply.subtitle.about-you'), ['next']);
    useWizardValidation(['applicantMaritalStatus', 'totalChildren']);

    return (
        <>
            <Title text={translate('page.living-condition.title')} />
            <Description text={translate('page.living-condition.description')} />
            <ShowDetailsModal
                label={translate('dialog.dont-understand.label')}
                dialogTitle={translate('dialog.dont-understand.label')}
                dialogContent={translate('page.living-condition.dialog.content')}
            />
            <LivingConditionField name="applicantMaritalStatus" />
            <Subtitle text={translate('page.living-condition.subtitle.children')} />
            <CounterField name="totalChildren" />
        </>
    );
};

export default LivingConditionPage;
