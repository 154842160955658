import SelectField from '../SelectField/SelectField';
import React, { useMemo } from 'react';
import useTranslation from '../../hooks/useTranslation';
import { SelectFieldOption } from '@resursbank/mui/components/SelectField/SelectFieldOption';

interface Props {
    name: string;
    label: string;
}

const MonthField = ({ name, label }: Props) => {
    const translate = useTranslation();
    const monthOptions: SelectFieldOption[] = useMemo<SelectFieldOption[]>(
        () => [
            { id: 'JANUARY', label: translate('field.month.option.jan.label') },
            { id: 'FEBRUARY', label: translate('field.month.option.feb.label') },
            { id: 'MARCH', label: translate('field.month.option.mar.label') },
            { id: 'APRIL', label: translate('field.month.option.apr.label') },
            { id: 'MAY', label: translate('field.month.option.may.label') },
            { id: 'JUNE', label: translate('field.month.option.jun.label') },
            { id: 'JULY', label: translate('field.month.option.jul.label') },
            { id: 'AUGUST', label: translate('field.month.option.aug.label') },
            { id: 'SEPTEMBER', label: translate('field.month.option.sep.label') },
            { id: 'OCTOBER', label: translate('field.month.option.oct.label') },
            { id: 'NOVEMBER', label: translate('field.month.option.nov.label') },
            { id: 'DECEMBER', label: translate('field.month.option.dec.label') },
        ],
        [translate],
    );
    return (
        <SelectField label={label} name={name}>
            {monthOptions}
        </SelectField>
    );
};

export default MonthField;
