import React from 'react';
import { Typography } from '@mui/material';

type Props = {
    text: string;
};

const Description = ({ text }: Props) => {
    return <Typography variant={'body1'} dangerouslySetInnerHTML={{ __html: text }} sx={{ marginBottom: '16px' }} />;
};

export default Description;
