import { ConsumerLoanParameter } from './ConsumerLoanParameter';

export const enum ConsumerLoanTargetPath {
    calculator = `/:${ConsumerLoanParameter.locale}`,
    init = `/:${ConsumerLoanParameter.locale}/init`,
    application = `/:${ConsumerLoanParameter.locale}/app`,
    apply = `/:${ConsumerLoanParameter.locale}/apply`,
    callback = `/:${ConsumerLoanParameter.locale}/app/:${ConsumerLoanParameter.applicationReferenceNumber}/callback`,
    ongoing = `/:${ConsumerLoanParameter.locale}/app/:${ConsumerLoanParameter.applicationReferenceNumber}`,
    error = `/:${ConsumerLoanParameter.locale}/err`,
    logout = '/logout',
    consent = `/:${ConsumerLoanParameter.locale}/consent`,
    ldapLogin = `/:${ConsumerLoanParameter.locale}/ldap_login`,
}
