import { AuthenticationData } from '@resursbank/react-form-service';
import { config } from '../helpers/apiConfig';
import { Kyc } from '../v2/types/PepTypes';

export const getSurveyService = async (
    apiKey: string,
    onlyPep: boolean,
    proxyBaseUrl: string,
    authenticationData?: AuthenticationData,
): Promise<Kyc> => {
    const url = `${proxyBaseUrl}/know_your_customer/get_survey?onlyPep=${onlyPep}`;
    const request = {
        method: 'GET',
        headers: config(apiKey, authenticationData),
    };

    try {
        const response = await fetch(url, request);
        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};
