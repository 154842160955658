import React from 'react';
import { SelectField } from '@resursbank/mui';
import { addOrUpdateFormField, IFieldState, selectFieldValue } from '@resursbank/react-form-service';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { SelectFieldOption } from '@resursbank/mui/components/SelectField/SelectFieldOption';

type SelectProps = {
    name: string;
    label?: string;
    value?: string;
    options?: SelectFieldOption[];
    children?: SelectFieldOption[];
};

const Select = (props: SelectProps) => {
    const dispatch = useAppDispatch();
    const value = useAppSelector(selectFieldValue)(props.name);
    const selectFieldOptions = props.options ? props.options : props.children ? props.children : [];

    function handleChange(value: string | unknown) {
        const option = selectFieldOptions.find((field) => field.id === value);

        dispatch(
            addOrUpdateFormField({
                fieldName: props.name,
                value,
                label: option?.label,
            } as IFieldState),
        );
    }

    return (
        <SelectField
            sx={{ margin: '10px 0' }}
            value={value ? value.toString() : ''}
            label={props.label}
            outline={'single'}
            name={props.name}
            onChange={(e) => handleChange(e.target.value)}
            options={selectFieldOptions}
        />
    );
};
export default Select;
