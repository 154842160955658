import React, { ReactNode, useState } from 'react';
import Subtitle from '../Subtitle/Subtitle';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontIcon } from '@resursbank/mui';
import { FlexStyles, SummaryStyles } from './Summary.styles';

type Props = {
    label: string;
    children: ReactNode;
};

const Summary = ({ label, children }: Props) => {
    const [expanded, setExpanded] = useState(true);
    return (
        <SummaryStyles>
            <FlexStyles expanded={expanded}>
                <Subtitle text={label} />
                <FontIcon
                    icon={expanded ? faChevronUp : faChevronDown}
                    size="xs"
                    onClick={() => setExpanded(!expanded)}
                    cursor={'pointer'}
                />
            </FlexStyles>
            {expanded ? children : null}
        </SummaryStyles>
    );
};

export default Summary;
