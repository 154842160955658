import styled from 'styled-components';
import { Box } from '@mui/material';

export const WarningMessageContainer = styled(Box)<{ errorstyling?: boolean }>`
    margin-bottom: 16px;
    color: ${(props) => (props.errorstyling ? '#930005' : '#785900')};
    padding: 16px;
    background-color: ${(props) => (props.errorstyling ? '#ffdad5' : '#ffdf9e')};
    border-radius: 12px;
`;

export const WarningMessageInfo = styled.div`
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    margin: 0;
`;
