import styled from 'styled-components';

export const ConsumerLoanDocumentLabel = styled.div<{ justify?: string; weight?: string }>`
    font-family: 'Inter';
    font-weight: ${({ weight }) => weight ?? '400'};
    font-size: 14px;
    line-height: 24px;
    color: #006965;
    display: flex;
    justify-content: ${({ justify }) => justify};
    align-items: center;
    padding-top: 10px;

    span {
        cursor: pointer;
    }
    svg {
        cursor: pointer;
    }
    svg path {
        fill: #006965;
    }
`;
