export const enum ApplicationStatus {
    complete = 'complete',
    declined = 'declined',
    inspection = 'inspection',
    waiting = 'waiting',
    processing = 'processing',
    error = 'error',
}

export const applicationStatusFromFormName = (formName?: string): ApplicationStatus => {
    switch (formName) {
        case 'consumerloan-complete':
            return ApplicationStatus.complete;

        case 'consumerloan-declined':
            return ApplicationStatus.declined;

        case 'consumerloan-inspection':
            return ApplicationStatus.inspection;

        case 'consumerloan-waiting-for-main-applicant':
        case 'consumerloan-waiting-for-co-applicant':
            return ApplicationStatus.waiting;

        case 'consumerloan-error-exception':
            return ApplicationStatus.error;

        case 'consumerloan-is-processing-application':
        default:
            return ApplicationStatus.processing;
    }
};

export const doPollForApplicationStatus = (applicationStatus: ApplicationStatus): boolean =>
    applicationStatus === ApplicationStatus.waiting || applicationStatus === ApplicationStatus.processing;
