import { useEffect } from 'react';
import { useAppSelector } from '../../hooks';
import useWizard from './useWizard';

const arrayContainsAll = (a1: string[], a2: string[]) => {
    const missing = a1.filter((f) => a2.indexOf(f) == -1);
    return missing.length === 0;
};

const useWizardValidation = (fieldNames: string[]) => {
    const { setNextDisabled, isNextDisabled } = useWizard();

    const fieldStates = useAppSelector((state) =>
        state.form.formFieldStates.filter((fieldState) => fieldNames.indexOf(fieldState.fieldName) > -1),
    );

    useEffect(() => {
        const disable =
            fieldStates.filter((field) => field.hasError || !field.value).length > 0 ||
            !arrayContainsAll(
                fieldNames,
                fieldStates.map((fieldState) => fieldState.fieldName),
            );

        if (disable != isNextDisabled) {
            setNextDisabled(disable);
        }
    }, [fieldStates, setNextDisabled]);
};

export default useWizardValidation;
