import { AuthenticationData } from '@resursbank/react-form-service';
import { GetDocumentRequest } from '../domain/types';
import * as Sentry from '@sentry/react';

export const getDocument = async (request: GetDocumentRequest) => {
    const url = `${request.apiProxyPath}/document/${request.documentType}/${request.languageCode}`;
    const req = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            apikey: request.apiKey,
        },
        responseType: 'blob' as 'json',
        body: JSON.stringify(request.data),
    };

    try {
        const response = await fetch(url, req);

        if (!response.ok) {
            const error = await response.json();
            if (process.env.NODE_ENV === 'production') {
                Sentry.captureException(new Error(`Get document service failed: ${JSON.stringify(error)}`));
            }
            throw new Error(error);
        }
        const blob = await response.blob();

        downloadFile(blob, `${request.documentType}_terms.pdf`);
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(new Error(`Get document service failed: ${error}`));
        }
        throw error;
    }
};

const downloadFile = (blob: Blob, filename: string) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
};

export const uploadDocumentService = (
    handleDocumentUrl: string,
    apiKey: string,
    authenticationData: AuthenticationData,
    documentType: string,
    file: File,
): Promise<any> => {
    const formData = new FormData();
    formData.append('file', file);

    let headers: any = {
        Authorization: `Bearer ${authenticationData.access_token}`,
    };
    if (apiKey) {
        headers = { ...headers, apikey: apiKey };
    }

    const requestParameters = {
        method: 'POST',
        headers,
        body: formData,
    };

    return fetch(`${handleDocumentUrl}/${documentType}`, requestParameters)
        .then()
        .catch((error) => {
            if (process.env.NODE_ENV === 'production') {
                Sentry.captureException(
                    new Error(
                        `Upload document service failed: ${
                            error instanceof Error ? error.message : JSON.stringify(error)
                        }`,
                    ),
                );
            }
            throw error;
        });
};
