import React from 'react';
import { SelectFieldOption } from '@resursbank/mui/components/SelectField/SelectFieldOption';
import useTranslation from '../../hooks/useTranslation';
import { useMemo } from 'react';
import SelectField from '../SelectField/SelectField';

type Props = {
    name: string;
    label: string;
};

const OccupationField = ({ name, label }: Props) => {
    const translate = useTranslation();
    const occupationOptions: SelectFieldOption[] = useMemo<SelectFieldOption[]>(
        () => [
            { id: 'EMPLOYEE', label: translate('field.occupation.option.employee.label') },
            { id: 'SUBSTITUTE', label: translate('field.occupation.option.substitute.label') },
            { id: 'SELF_EMPLOYED', label: translate('field.occupation.option.self-employed.label') },
            { id: 'PENSIONER', label: translate('field.occupation.option.pensioner.label') },
            { id: 'UNEMPLOYED', label: translate('field.occupation.option.unemployed.label') },
        ],
        [translate],
    );

    return (
        <SelectField label={label} name={name}>
            {occupationOptions}
        </SelectField>
    );
};

export default OccupationField;
