import React, { useMemo } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import GlobalCSS from '../../styles/global.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { themeLight } from '@resursbank/mui';
import {
    IElement,
    ReactFormServiceProvider,
    SessionDialog,
    useTranslationService,
    useValidationService,
    ReactFormServiceContext,
    Action,
    selectFieldValue,
    goToPage,
    IFieldState,
    CookieDialog,
    initAuthentication,
} from '@resursbank/react-form-service';
import { appConfig, getEnvironmentConfig } from '../../config';
import { ConsumerLoanTargetPath, GetFormPageRequest, PatchApplicationRequest } from '../../domain/types';
import useSettings from '../../hooks/useSettings';
import { getPath } from '../../helpers/url';
import { redirectUrl } from '../../store/consent/consent';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ConsumerloanDocument } from '../../domain/ConsumerloanDocument';
import { ReactComponent } from '../../domain/ReactComponent';
import { sendGTMPageStepEvent } from '../../helpers/gtm';
import { getFormPageService, getOngoingFormPageService } from '../../api/applicationForm';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import { Root } from './RootPage.styles';
import ComplementationUploadField from '../../domain/ComplementationUploadField/ComplementationUploadField';
import ImageComponent from '../../domain/ImageComponent/ImageComponent';
import { ConsumerLoanParameter } from '../../domain/types/ConsumerLoanParameter';
import { callbackUrl } from '../../api/util';
import GTMPageView from '../../helpers/GTMPageView';
import { simpleFormService } from '../../api/simpleFormService';
import { TranslationProvider } from '../../v2/context/TranslationContext';

const RootPage = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const translate = useTranslationService();
    const validationService = useValidationService();
    const governmentId = useAppSelector(selectFieldValue)('applicantGovernmentId');
    const openApplicationGovernmentId = useAppSelector(selectFieldValue)('openApplicationGovernmentId');
    const { applicationId } = useAppSelector((state) => state.application);
    const counter = useAppSelector((state) => state.counter);
    const { currentPage: currentPageNumber } = useAppSelector((state) => state.form);
    const { languageCode, apiKey, simpleFormUrl } = useSettings();
    const applicationReferenceNumber = useMemo(
        () => params[ConsumerLoanParameter.applicationReferenceNumber],
        [params],
    );

    const config = getEnvironmentConfig();
    const navigate = useNavigate();
    const settings = useSettings();

    const generatedCallbackUrl = useMemo(() => {
        return callbackUrl(
            ConsumerLoanTargetPath.ongoing,
            settings.languageCode,
            settings.countryCode,
            applicationReferenceNumber ?? '',
        );
    }, [settings.languageCode, settings.countryCode, applicationReferenceNumber]);

    const reactFormServiceProvider = useMemo<ReactFormServiceProvider>(
        () => ({
            handleAction: (actionName: string, field: IElement | IFieldState | undefined) => {
                if (actionName === 'externalConsent') {
                    const correlationId = field as IElement;

                    dispatch(
                        redirectUrl({
                            correlationId: correlationId?.link?.content ?? '',
                            origin: ConsumerLoanTargetPath.ongoing,
                        }),
                    )
                        .unwrap()
                        .then((consentUrl) => window.location.assign(consentUrl as string));
                } else if (actionName === Action.GoToFirstPage) {
                    dispatchGoToPage(1, '');
                } else if (actionName === 'openApplication') {
                    dispatch(
                        goToPage({
                            service: getOngoingFormPageService,
                            payload: {
                                applicationReferenceNumber,
                                languageCode,
                                formFieldStates: [
                                    {
                                        fieldName: 'governmentId',
                                        value: openApplicationGovernmentId,
                                    },
                                    {
                                        fieldName: 'openApplicationGovernmentId',
                                        value: openApplicationGovernmentId,
                                    },
                                ],
                            } as PatchApplicationRequest,
                            action: '',
                        }),
                    );
                } else if (actionName === 'confirmConsent') {
                    dispatch(
                        initAuthentication(openApplicationGovernmentId + '', {
                            scope: getEnvironmentConfig().consentScope,
                            redirectUrl: callbackUrl(
                                ConsumerLoanTargetPath.ongoing,
                                settings.languageCode,
                                settings.countryCode,
                                applicationReferenceNumber ?? '',
                            ),
                        }),
                    );
                } else if (actionName === 'goToEditPage') {
                    const editField = field as IFieldState;
                    const pageNumber = editField.editReference?.pageNumber + '';
                    dispatchGoToPage(Number(pageNumber), '');
                } else if (actionName === 'sblLogin') {
                    dispatch(
                        initAuthentication(governmentId + '', {
                            scope: (field! as any).link!.content,
                            redirectUrl: callbackUrl(
                                ConsumerLoanTargetPath.application,
                                settings.languageCode,
                                settings.countryCode,
                                '',
                            ),
                        }),
                    );
                } else {
                    console.log('Unknown action: ' + name, field);
                }
            },
            validationService,
            createComponent: (type: any) => {
                switch (type) {
                    case 'Document':
                        return ConsumerloanDocument;
                    case 'ReactComponent':
                        return ReactComponent;
                    case 'DocumentUpload':
                        return ComplementationUploadField;
                    case 'Image':
                        return ImageComponent;
                    default:
                        throw Error(`Unknown component type ${type}`);
                }
            },
            getToggles: () => {
                return [];
            },
            translate,
            simpleFormService: (formName: string, fieldStatesToActOn: IFieldState[]) =>
                simpleFormService(
                    simpleFormUrl,
                    apiKey,
                    formName,
                    applicationId,
                    governmentId,
                    generatedCallbackUrl,
                    fieldStatesToActOn,
                ),
        }),
        [validationService, translate, applicationReferenceNumber],
    );

    const dispatchGoToPage = (page: number, action: string) => {
        return dispatch(
            goToPage({
                service: getFormPageService,
                payload: {
                    page,
                    version: config.applyFormVersion,
                    governmentId,
                    applicationId,
                    languageCode: settings.languageCode,
                    countryCode: settings.countryCode,
                } as GetFormPageRequest,
                action,
            }),
        )
            .unwrap()
            .then(() => window.scrollTo({ top: 0 }))
            .catch(() => navigate(getPath(ConsumerLoanTargetPath.error, settings.languageCode, settings.countryCode)))
            .finally(() => sendGTMPageStepEvent(counter.productType, currentPageNumber, applicationId));
    };

    const onLogout = () => {
        navigate(getPath(ConsumerLoanTargetPath.logout, settings.languageCode, settings.countryCode));
    };

    return (
        <Root countryCode={settings.countryCode}>
            <TranslationProvider translate={translate}>
                <ThemeProvider theme={themeLight}>
                    <ReactFormServiceContext.Provider value={reactFormServiceProvider}>
                        <GTMPageView />
                        <SessionDialog
                            maxIdleTime={appConfig.maxIdleTime}
                            logoutWarnTime={appConfig.logoutWarnTime}
                            onLogout={onLogout}
                        />
                        <CookieDialog />
                        <GlobalCSS />
                        <ErrorBoundary>
                            <Outlet />
                        </ErrorBoundary>
                    </ReactFormServiceContext.Provider>
                </ThemeProvider>
            </TranslationProvider>
        </Root>
    );
};
export default RootPage;
