import React, { useEffect } from 'react';
import {
    PARAM_STATE,
    PARAM_ERROR,
    PARAM_CODE,
    getAccessToken,
    selectAccessToken,
    refreshAccessToken,
} from '@resursbank/react-form-service';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from './hooks';
import { useSelector } from 'react-redux';

const REFRESH_TOKEN_INTERVAL = 60000;

/**
 * Certain application flows requries the user to authenticate to get a consent before being able to
 * continue the application. This hook will listen to any authentication callbacks (with code and state)
 * and retreive the auth token. If a access token exists it will also be responsible for refreshing it.
 */
const useConsentAuthentication = () => {
    const dispatch = useAppDispatch();
    const [params, setParams] = useSearchParams();
    const accessToken = useSelector(selectAccessToken);

    // If we have a valid token, remove code and state parameters from url
    useEffect(() => {
        if (accessToken && (params.get(PARAM_STATE) || params.get(PARAM_CODE) || params.get(PARAM_ERROR))) {
            params.delete(PARAM_CODE);
            params.delete(PARAM_STATE);
            params.delete(PARAM_ERROR);
            setParams(params);
        }
    }, [accessToken, params, setParams]);

    useEffect(() => {
        if (params.get(PARAM_STATE) || params.get(PARAM_CODE)) {
            dispatch(
                getAccessToken({
                    state: params.get(PARAM_STATE)!,
                    code: params.get(PARAM_CODE)!,
                }),
            );
        }
    }, [params, setParams, dispatch]);

    // A timer that will refresh the token
    useEffect(() => {
        if (accessToken) {
            const timer = setInterval(() => dispatch(refreshAccessToken()), REFRESH_TOKEN_INTERVAL);
            return () => clearInterval(timer);
        }
    }, [dispatch, accessToken]);

    return accessToken;
};

export default useConsentAuthentication;
