import React from 'react';
import { Icon, IconType, ImageProps } from '@resursbank/react-form-service';
import styled from 'styled-components';

export const DisplayCenter = styled.div`
    display: flex;
    justify-content: center;
`;
const ImageComponent = ({ field }: ImageProps) => {
    const renderIcon = (iconName: string) => (
        <DisplayCenter>
            <Icon icon={iconName} />
        </DisplayCenter>
    );

    switch (field.name) {
        case 'imageInformation':
            return renderIcon(IconType.Envelope);
        case 'imageWork':
            return renderIcon(IconType.UserHelmetSafety);
        case 'imageStatus':
            return renderIcon(IconType.RingsWedding);
        case 'imageChildren':
            return renderIcon(IconType.Children);
        case 'imageEconomic':
            return renderIcon(IconType.Wallet);
        case 'imagePep':
            return renderIcon(IconType.BuildingColumns);
        case 'imageCoApplicant':
            return renderIcon(IconType.PersonCirclePlus);
        case 'imageCollect':
            return renderIcon(IconType.RectangleHistoryCirclePlus);
        case 'imageGovernmentId':
            return renderIcon(IconType.IdCard);
        default:
            return <>{field.name}</>;
    }
};
export default ImageComponent;
