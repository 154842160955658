import React, { ReactNode } from 'react';
import { RootBoxStyles } from '../../pages/RootPage/RootPage.styles';
import { Box } from '@mui/material';
import { ResursLogo } from '@resursbank/mui';
import PageFooter from '../PageFooter/PageFooter';
import useSettings from '../../hooks/useSettings';

type Props = {
    children: ReactNode;
};

const NorwegianStyleWrapper = ({ children }: Props) => {
    const { countryCode } = useSettings();
    return (
        <>
            <Box sx={RootBoxStyles(countryCode)}>
                <ResursLogo variant={'row_black'} height={24} />
                {children}
            </Box>
            <PageFooter />
        </>
    );
};

export default NorwegianStyleWrapper;
