import React, { useEffect, useState } from 'react';
import Description from '../components/Description/Description';
import Title from '../components/Title/Title';
import useTranslation from '../hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectFieldStateByName } from '../../store/applicationform/applicationFormSelectors';
import Summary from '../components/Summary/Summary';
import SummaryRow from '../components/Summary/SummaryRow';
import { prepareForReSubmit, submitApplication } from '../../store/application/application';
import { getPath } from '../../helpers/url';
import { ConsumerLoanTargetPath } from '../../domain/types';
import { sendGTMSubmittedLoan } from '../../helpers/gtm';
import { useNavigate } from 'react-router-dom';
import useSettings from '../../hooks/useSettings';
import { selectFieldValueSelector } from '../../components/LoanInformation/LoanInformation';
import { ErrorMessage } from '@resursbank/react-form-service';
import useWizard from '../hooks/useWizard';

type FieldInformationRowProps = {
    name: string;
    label: string;
};

const FieldSummaryRow = ({ name, label }: FieldInformationRowProps) => {
    const field = useAppSelector(selectFieldStateByName)(name);

    if (!field || !field.value) {
        return;
    }
    return <SummaryRow value={field.label ? field.label : field.value.toString()} label={label} />;
};

const SubmitPage = () => {
    const translate = useTranslation();
    const { handleStep, setSection } = useWizard();
    setSection(1, translate('page.how-to-apply.subtitle.about-the-loan'), ['submit']);
    const hasCoApplicant = useAppSelector(selectFieldStateByName)('coapplicantToggle')?.value === 'true';
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { languageCode, countryCode } = useSettings();
    const counter = useAppSelector((state) => state.counter);
    const loanAmount = Number.parseInt(useAppSelector(selectFieldValueSelector)('amount'));
    const applicationId = useAppSelector((state) => state.application.applicationId);
    const [errorOccurred, setErrorOccurred] = useState<boolean>(false);

    useEffect(() => {
        setErrorOccurred(false);
        dispatch(prepareForReSubmit());
    }, []);

    handleStep(async () => {
        try {
            const response = await dispatch(submitApplication()).unwrap();
            navigate(
                getPath(ConsumerLoanTargetPath.ongoing, languageCode, countryCode, response.applicationReferenceNumber),
            );
        } catch (error) {
            setErrorOccurred(true);
            throw error;
        } finally {
            sendGTMSubmittedLoan(counter.productType, loanAmount, applicationId);
        }
    });

    return (
        <>
            <Title text={translate('page.submit.title')} />
            <Description text={translate('page.submit.description')} />
            {errorOccurred && (
                <ErrorMessage
                    title={translate(`page.submit.error.title`)}
                    description={translate(`page.submit.error.description`)}
                />
            )}
            <Summary label={translate('page.how-to-apply.subtitle.about-the-loan')}>
                <FieldSummaryRow name="amount" label={translate('field.amount.label')} />
                <FieldSummaryRow name="tenor" label={translate('field.tenor.label')} />
                <FieldSummaryRow name="applicantPurposeOfLoan" label={translate('page.submit.purpose-of-loan.label')} />
            </Summary>
            <Summary label={translate('page.how-to-apply.subtitle.about-you')}>
                <FieldSummaryRow
                    name="applicantGovernmentId"
                    label={translate('field.applicant-government-id.label')}
                />
                <FieldSummaryRow
                    name="applicantEmailAddress"
                    label={translate('field.applicant-email-address.label')}
                />
                <FieldSummaryRow
                    name="applicantMobileNumber"
                    label={translate('field.applicant-mobile-number.label')}
                />
                <FieldSummaryRow
                    name="applicantMaritalStatus"
                    label={translate('page.submit.applicant-marital-status.label')}
                />
                <FieldSummaryRow
                    name="applicantNumberChildrenNotOfAge"
                    label={translate('page.submit.applicant-number-children-not-of-age.label')}
                />
                <FieldSummaryRow
                    name="applicantHabitationForm"
                    label={translate('page.submit.applicant-habitation-form.label')}
                />
                <FieldSummaryRow
                    name="applicantEmploymentType"
                    label={translate('page.submit.applicant-employment-type.label')}
                />
                <FieldSummaryRow
                    name="applicantYearlyGrossIncome"
                    label={translate('page.submit.applicant-yearly-income-gross.label')}
                />
                <FieldSummaryRow
                    name="applicantMortgageMonthlyCost"
                    label={translate('page.submit.applicant-mortgage-monthly-cost.label')}
                />
            </Summary>
            {hasCoApplicant && (
                <Summary label={translate('page.submit.about-coapplicant.label')}>
                    <FieldSummaryRow
                        name="coapplicantGovernmentId"
                        label={translate('field.applicant-government-id.label')}
                    />
                    <FieldSummaryRow
                        name="coapplicantEmailAddress"
                        label={translate('field.applicant-email-address.label')}
                    />
                    <FieldSummaryRow
                        name="coapplicantMobileNumber"
                        label={translate('field.applicant-mobile-number.label')}
                    />

                    <FieldSummaryRow
                        name="coapplicantMaritalStatus"
                        label={translate('page.submit.coapplicant-marital-status.label')}
                    />
                </Summary>
            )}
        </>
    );
};

export default SubmitPage;
