import { IFormData, IFormServicePayload } from '@resursbank/react-form-service';
import { callbackUrl, dataFormPage } from './util';
import { ConsumerLoanTargetPath, GetFormPageRequest, GetOngoingFormPageRequest } from '../domain/types';
import { config } from '../helpers/apiConfig';
import * as Sentry from '@sentry/react';

export const getFormPageService = async (request: GetFormPageRequest): Promise<IFormData> => {
    const path = `/application/initiation/${request.page}/${request.languageCode}`;

    const values: any = {
        applicantGovernmentId: request.governmentId,
        callbackUrl: callbackUrl(ConsumerLoanTargetPath.consent, request.languageCode, request.countryCode, ''),
        governmentId: request.governmentId,
        applicationId: request.applicationId,
        tenor: request.tenor,
        amount: request.amount,
        channel: request.channel,
        projectId: request.projectId,
    };

    try {
        return await getFormPage(path, values, request.version, request);
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(
                new Error(
                    `Get Form Page Service failed: ${error instanceof Error ? error.message : JSON.stringify(error)}`,
                ),
            );
        }
        throw error;
    }
};

export const getOngoingFormPageService = async (request: GetOngoingFormPageRequest): Promise<IFormData> => {
    const path = `/application/${request.applicationReferenceNumber}/${request.languageCode}`;
    const values: any = {
        applicationId: request.applicationReferenceNumber,
        callbackUrl: callbackUrl(
            ConsumerLoanTargetPath.consent,
            request.languageCode,
            request.countryCode,
            request.applicationReferenceNumber,
        ),
    };

    try {
        return await getFormPage(path, values, null, request);
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(
                new Error(
                    `Get Ongiong Form Page Service failed: ${
                        error instanceof Error ? error.message : JSON.stringify(error)
                    }`,
                ),
            );
        }
        throw error;
    }
};

const getFormPage = async (
    path: string,
    values: any,
    version: string | undefined | null,
    request: IFormServicePayload,
): Promise<any> => {
    const url = request.proxyBaseUrl + path;
    const payload = dataFormPage(values, version, request.formFieldStates);
    const req = {
        method: 'POST',
        headers: config(request.apiKey, request.authenticationData),
        body: JSON.stringify(payload),
    };

    try {
        const response = await fetch(url, req);
        if (!response.ok) {
            const error = await response.json();
            if (process.env.NODE_ENV === 'production') {
                Sentry.captureException(new Error(`Get Form Page failed: ${JSON.stringify(error)}`));
            }
            throw new Error(error);
        }
        return await response.json();
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(
                new Error(`Get Form Page failed: ${error instanceof Error ? error.message : JSON.stringify(error)}`),
            );
        }
        throw error;
    }
};
