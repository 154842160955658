import React, { ReactNode } from 'react';
import { WarningMessageContainer, WarningMessageInfo } from './WarningMessage.styles';

type WarningMessageProps = {
    children: ReactNode;
    hasError: boolean | undefined;
};

const WarningMessage = ({ children, hasError }: WarningMessageProps) => {
    return (
        <WarningMessageContainer errorstyling={hasError}>
            <WarningMessageInfo>{children}</WarningMessageInfo>
        </WarningMessageContainer>
    );
};

export default WarningMessage;
