import React from 'react';
import Title from '../components/Title/Title';
import Description from '../components/Description/Description';
import useTranslation from '../hooks/useTranslation';
import MonetaryField from '../components/MonetaryField/MonetaryField';
import RadioField from '../components/RadioField/RadioField';
import { useAppSelector } from '../../hooks';
import { selectFieldValue } from '@resursbank/react-form-service';
import ShowDetailsModal from '../components/ShowDetailsModal/ShowDetailsModal';
import useWizard from '../hooks/useWizard';
import useWizardValidation from '../hooks/useWizardValidation';

const PurposeOfLoanPage = () => {
    const translate = useTranslation();
    const { setSection, handleStep } = useWizard();
    setSection(1, translate('page.how-to-apply.subtitle.about-the-loan'), ['next']);
    useWizardValidation(['applicantPurposeOfLoan']);

    const purposeOfLoan = useAppSelector(selectFieldValue)('applicantPurposeOfLoan');
    const additionalConsolidationAmount = useAppSelector(selectFieldValue)('additionalConsolidationAmount');

    const purposeOfLoanOptions = [
        {
            id: 'CONSOLIDATION',
            label: translate('field.purpose-of-loan.option.consolidate.label'),
        },
        {
            id: 'VEHICLE',
            label: translate('field.purpose-of-loan.option.vehicle.label'),
        },
        {
            id: 'HEALTHCARE',
            label: translate('field.purpose-of-loan.option.healthcare.label'),
        },
        {
            id: 'OTHER_CONSUMPTION',
            label: translate('field.purpose-of-loan.option.other-consumption.label'),
        },
    ];

    handleStep(() => {
        if (purposeOfLoan === 'CONSOLIDATION' && !additionalConsolidationAmount) {
            throw new Error('Missing consolidation amount');
        }
    });

    return (
        <>
            <Title text={translate('page.purpose-of-loan.title')} />
            <Description text={translate('page.purpose-of-loan.description')} />
            <ShowDetailsModal
                label={translate('dialog.not-sure-what-to-choose.label')}
                dialogTitle={translate('dialog.not-sure-what-to-choose.label')}
                dialogContent={translate('page.purpose-of-loan.content')}
            />
            <RadioField name="applicantPurposeOfLoan" options={purposeOfLoanOptions} />
            {purposeOfLoan === 'CONSOLIDATION' && (
                <MonetaryField
                    name="additionalConsolidationAmount"
                    label={translate('field.additional-consolidation-amount.label')}
                />
            )}
        </>
    );
};

export default PurposeOfLoanPage;
