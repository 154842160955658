import { useCallback, useState } from 'react';
import { useWizard as useWizardComponent } from 'react-use-wizard';
import {
    WizardPageAction,
    selectPageActions,
    setNextDisabled as setIsNextDisabled,
    setPageActions,
    setSectionLabel,
    setSelectedSection,
} from '../../store/wizard/wizard';
import { useAppDispatch, useAppSelector } from '../../hooks';

const allEqual = (a: any, b: any): boolean => {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
};

const useWizard = () => {
    const { activeStep, stepCount, handleStep, isLoading, nextStep } = useWizardComponent();
    const pageActions = useAppSelector(selectPageActions);
    const isNextDisabled = useAppSelector((state) => state.wizard.isNextDisabled);

    const dispatch = useAppDispatch();

    const setSection = useCallback(
        (section: number, label: string, actions?: WizardPageAction[]) => {
            dispatch(setSelectedSection(section));
            dispatch(setSectionLabel(label));
            if ((actions && pageActions && !allEqual(actions, pageActions)) || (actions && !pageActions)) {
                dispatch(setPageActions(actions));
            }
        },
        [dispatch, pageActions],
    );

    const setNextDisabled = (disable: boolean) => dispatch(setIsNextDisabled(disable));

    return { activeStep, stepCount, handleStep, isLoading, nextStep, setSection, setNextDisabled, isNextDisabled };
};

export default useWizard;
