import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ConsumerLoanParameter } from '../../domain/types/ConsumerLoanParameter';
import { useNavigate, useParams } from 'react-router';
import { getOngoingFormPageService } from '../../api/applicationForm';
import { ConsumerLoanTargetPath, GetOngoingFormPageRequest } from '../../domain/types';
import {
    goToPage,
    FormData,
    ErrorMessage,
    ReactFormServiceContext,
    StatusProcessingPage,
    initAuthentication,
} from '@resursbank/react-form-service';
import { useAppDispatch } from '../../hooks';
import { getPath } from '../../helpers/url';
import useSettings from '../../hooks/useSettings';
import { Box } from '@mui/material';

/**
 * A login form component that will try and fetch the login form from the server and present it.
 */
const LoginForm = () => {
    const params = useParams();
    const [showSpinner, setShowSpinner] = useState(false);
    const dispatch = useAppDispatch();
    const { languageCode, countryCode } = useSettings();
    const navigate = useNavigate();
    const { translate } = useContext(ReactFormServiceContext);

    const callbackStatus = params[ConsumerLoanParameter.callbackStatus];

    const applicationReferenceNumber = useMemo(
        () => params[ConsumerLoanParameter.applicationReferenceNumber],
        [params],
    );

    const errorElement = () => {
        if (callbackStatus === 'failure') {
            return (
                <Box paddingBottom={4}>
                    <ErrorMessage
                        title={translate(`page.authentication.submit.log.in.error.title`)}
                        description={translate(`page.authentication.submit.log.in.error.description`)}
                    />
                </Box>
            );
        }
        return undefined;
    };

    useEffect(() => {
        setShowSpinner(true);

        dispatch(
            goToPage({
                service: getOngoingFormPageService,
                payload: {
                    applicationReferenceNumber,
                } as GetOngoingFormPageRequest,
                action: 'next',
            }),
        )
            .unwrap()
            .catch(() => navigate(getPath(ConsumerLoanTargetPath.error, languageCode, countryCode)))
            .finally(() => setShowSpinner(false));
    }, [setShowSpinner, languageCode, countryCode]);

    if (showSpinner) {
        return <StatusProcessingPage title={''} body={''} />;
    }

    return (
        <>
            <FormData
                isApplicationLoading={false}
                onNextPage={() => dispatch(initAuthentication())}
                onPreviousPage={() => {}}
                applicationError={undefined}
                ErrorElement={errorElement()}
            />
        </>
    );
};

export default LoginForm;
