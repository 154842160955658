import React, { ReactElement, ReactNode } from 'react';
import { Wizard as WizardComponent } from 'react-use-wizard';

type Props = {
    header?: ReactElement;
    footer?: ReactElement;
    onStepChange?: (stepIndex: number) => void;
    startIndex?: number;
    children: ReactNode;
};

const Wizard = ({ header, footer, onStepChange, startIndex, children }: Props) => {
    return (
        <WizardComponent header={header} footer={footer} onStepChange={onStepChange} startIndex={startIndex}>
            {children}
        </WizardComponent>
    );
};

export default Wizard;
