import { useContext } from 'react';
import { TranslationContext } from '../context/TranslationContext';

/**
 * A translate function that will return a translation for the given key. The naming convention for translation keys are as following:
 *
 * Pages can have translation as follows:
 *      page.[page-name].title
 *      page.[page-name].description
 *      page.[page-name].subtitle.[subtitle-name]
 *      page.[page-name].button.[button-name]
 *
 *  Fields can have translations as follows
 *      field.[field-name].label
 *      field.[field-name].placeholder
 *      field.[field-name].error.required
 *      field.[field-name].error.format(.[no|se|fi|dk])
 *      field.[field-name].error.length
 *      field.[field-name].error.range
 *      field.[field-name].option.[option-name].label
 */
type TranslateFunction = (key: string, data?: any) => string;

/**
 * A hook for using the translation function
 *
 * @returns a translate function that will accept a key and return a string
 */
const useTranslation = (): TranslateFunction => {
    const { translate } = useContext(TranslationContext);
    return translate;
};

export default useTranslation;
