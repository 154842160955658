import React from 'react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontIcon } from '@resursbank/mui';

type Props = {
    icon: FontAwesomeIconProps['icon'] | IconDefinition;
};
const Icon = ({ icon }: Props) => {
    return (
        <FontIcon
            icon={icon}
            style={{
                color: '#00807B',
                backgroundColor: '#D6EBEA',
                padding: '6px',
                borderRadius: '10px',
            }}
        />
    );
};

export default Icon;
