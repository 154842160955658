import ConsumerLoanAuthenticationScope from '../types/ConsumerLoanAuthenticationScope';
import { CountryCode, Settings, LanguageCode } from '@resursbank/react-form-service';
import { getEnvironmentConfig } from '../../config';

const getAuthenticationScope = (countryCode: CountryCode): ConsumerLoanAuthenticationScope | undefined => {
    switch (countryCode) {
        case 'SE':
            return ConsumerLoanAuthenticationScope.SE;
        case 'NO':
            return ConsumerLoanAuthenticationScope.NO;
        case 'DK':
            return ConsumerLoanAuthenticationScope.DK;
        case 'FI':
            return ConsumerLoanAuthenticationScope.FI;
        default:
            return undefined;
    }
};

const getOriginationUrlFromCountryCode = (countryCode: CountryCode) => {
    switch (countryCode) {
        case 'NO':
            return 'https://resursbank.no';
        case 'FI':
            return 'https://resursbank.fi';
        case 'DK':
            return 'https://resursbank.dk';
        default:
            return 'https://resursbank.se';
    }
};

export const getSettings = (countryCode: CountryCode, languageCode: LanguageCode): Settings => {
    const environmentConfig = getEnvironmentConfig();
    const defaultOriginatingUrl = getOriginationUrlFromCountryCode(countryCode);
    const proxyBaseUrlForCountryCode = environmentConfig.proxyBaseUrl.replace('{countryCode}', countryCode);
    return {
        countryCode,
        languageCode,
        authenticationScope: getAuthenticationScope(countryCode),
        originatingUrl: defaultOriginatingUrl,
        validationUrl: `${proxyBaseUrlForCountryCode}/validation`,
        translationUrl: `${proxyBaseUrlForCountryCode}/translation/${languageCode}`,
        handleDocumentUrl: '',
        ...environmentConfig,
        proxyBaseUrl: proxyBaseUrlForCountryCode,
        simpleFormUrl: `${proxyBaseUrlForCountryCode}/simple_form/${languageCode}`,
    };
};
