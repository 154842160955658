import { IFetchPriceParams, IFetchIntervalsParams, IPriceIntervalData } from '../store/price/priceTypes';
import { IPriceData } from '../domain/types';
import * as Sentry from '@sentry/react';

export const getIntervalsService = async (
    apiKey: string,
    params: IFetchIntervalsParams,
): Promise<IPriceIntervalData> => {
    const url = `${params.apiProxyPath}/price/query/intervals?partner=${params.partner}&product_type=${params.productType}`;
    const request = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            apiKey,
        },
    };

    try {
        const response = await fetch(url, request);
        if (!response.ok) {
            const error = await response.json();
            if (process.env.NODE_ENV === 'production') {
                Sentry.captureException(new Error(`Get intervals service failed: ${JSON.stringify(error)}`));
            }
            throw new Error(error);
        }
        return await response.json();
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(
                new Error(
                    `Get intervals service failed: ${error instanceof Error ? error.message : JSON.stringify(error)}`,
                ),
            );
        }
        throw error;
    }
};

export const getPriceService = async (apiKey: string, params: IFetchPriceParams): Promise<IPriceData> => {
    const url = `${params.apiProxyPath}/price/query/price?partner=${params.partner}&product_type=${params.productType}&loan_amount=${params.loanAmount}&currency_unit=${params.currencyUnit}&tenor=${params.tenorInMonths}`;
    const request = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            apiKey,
        },
    };

    try {
        const response = await fetch(url, request);
        if (!response.ok) {
            const error = await response.json();
            if (process.env.NODE_ENV === 'production') {
                Sentry.captureException(new Error(`Get price service failed: ${JSON.stringify(error)}`));
            }
            throw new Error(error);
        }
        return await response.json();
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(
                new Error(
                    `Get price service failed: ${error instanceof Error ? error.message : JSON.stringify(error)}`,
                ),
            );
        }
        throw error;
    }
};
