import React, { useState } from 'react';
import { FontIcon } from '@resursbank/mui';
import { faCirclePlus, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { ShowDetailsModalStyles, HeaderStyles, IconStyles, Label } from './ShowDetailsModal.styles';

type Props = {
    label: string;
    dialogTitle: string;
    dialogContent: string;
};
const ShowDetailsModal = ({ label, dialogTitle, dialogContent }: Props) => {
    const [open, setOpen] = useState(false);
    return (
        <ShowDetailsModalStyles>
            <Label onClick={() => setOpen(true)}>
                <span>{label}</span>
                <FontIcon icon={faCirclePlus} type={'outline'} size="sm" />
            </Label>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                    <HeaderStyles>
                        <DialogTitle>{dialogTitle}</DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={() => setOpen(false)}
                            disableRipple
                            disableFocusRipple
                            sx={IconStyles}>
                            <FontIcon icon={faXmark} type={'outline'} size="md" />
                        </IconButton>
                    </HeaderStyles>
                    <span dangerouslySetInnerHTML={{ __html: dialogContent }} />
                </DialogContent>
            </Dialog>
        </ShowDetailsModalStyles>
    );
};

export default ShowDetailsModal;
