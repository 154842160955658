import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type WizardPageAction = 'next' | 'submit' | 'collect-details';

type WizardState = {
    pageName: string;
    selectedPage: number;
    sectionCount: number;
    selectedSection: number;
    sectionLabel: string;
    pageActions: WizardPageAction[];
    isNextDisabled: boolean;
};

export const selectPageIndex = createSelector(
    (state: RootState) => state,
    (state) => state.wizard.selectedPage,
);

export const selectPageActions = createSelector(
    (state: RootState) => state,
    (state) => state.wizard.pageActions,
);

export const initialWizardState: WizardState = {
    pageName: 'init',
    selectedPage: 0,
    sectionCount: 0,
    selectedSection: 0,
    sectionLabel: '',
    pageActions: [],
    isNextDisabled: false,
};

export const wizardSlice = createSlice({
    name: 'application',
    initialState: initialWizardState,
    reducers: {
        reset: () => initialWizardState,
        setSelectedPage: (state: WizardState, action: PayloadAction<number>) => {
            state.selectedPage = action.payload;
            state.pageActions = [];
        },
        setPageName: (state: WizardState, action: PayloadAction<string>) => {
            state.pageName = action.payload;
        },
        setSectionCount: (state: WizardState, action: PayloadAction<number>) => {
            state.sectionCount = action.payload;
        },
        setSelectedSection: (state: WizardState, action: PayloadAction<number>) => {
            state.selectedSection = action.payload;
        },
        setSectionLabel: (state: WizardState, action: PayloadAction<string>) => {
            state.sectionLabel = action.payload;
        },
        setPageActions: (state: WizardState, action: PayloadAction<WizardPageAction[]>) => {
            state.pageActions = action.payload;
        },
        setNextDisabled: (state: WizardState, action: PayloadAction<boolean>) => {
            state.isNextDisabled = action.payload;
        },
    },
});

export const {
    reset,
    setSelectedPage,
    setPageActions,
    setSectionCount,
    setSelectedSection,
    setSectionLabel,
    setNextDisabled,
} = wizardSlice.actions;

export default wizardSlice.reducer;
