import { SxProps } from '@mui/material';
import { styled } from '@mui/system';

export const SummaryStyles = styled('div')`
    border: 1px solid #e9ecef;
    border-radius: 20px;
    padding: 16px;
    margin-bottom: 32px;
    margin-top: 32px;
    background-color: #fff;
`;

export const FlexStyles = styled('div')<{ expanded?: boolean }>(
    ({ expanded }) => `
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: ${expanded ? '16px' : '0'};
    `,
);

export const EditButton: SxProps = {
    padding: 0,
    minWidth: 0,
    marginLeft: '16px',
};

export const SummaryRowStyles = styled('div')`
    display: flex;
    justify-content: space-between;
`;

export const LabelStyles = styled('div')`
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    gap: 8px;
`;

export const Label = styled('span')`
    font-size: 14px;
    color: #212529b2;
`;
