import { useLocation } from 'react-router';

export const useLocalePath = () => {
    const location = useLocation();

    // Get the first path segment which should be the locale
    const { language, countryCode } = extractLocaleFromPath(location.pathname);

    return { language, countryCode };
};

const DEFAULT_LOCALE = {
    language: 'sv',
    countryCode: 'SE',
};

export const extractLocaleFromPath = (path: string) => {
    const locale = path.split('/').filter((p) => p !== '')?.[0] ?? 'sv_SE';
    const localeParts = locale.split('_');

    if (localeParts.length < 2) {
        return DEFAULT_LOCALE;
    }

    const language = localeParts?.[0].length === 2 ? localeParts?.[0].toLocaleLowerCase() : 'sv';
    const countryCode = localeParts?.[1].length === 2 ? localeParts?.[1].toUpperCase() : 'SE';
    return { language, countryCode };
};
