import { SxProps } from '@mui/material';

const styles: { [key: string]: SxProps } = {
    wizardHeaderBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '16px',
        marginBottom: '16px',
    },
};

export default styles;
