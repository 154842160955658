import React, { ReactNode } from 'react';
import { Button as MuiButton, SxProps, Theme, useTheme } from '@mui/material';

type Props = {
    children: ReactNode;
    onClick?: () => void;
    variant?: 'text' | 'outlined' | 'contained' | undefined;
    icon?: JSX.Element;
    disabled?: boolean;
    fill?: boolean;
    sx?: SxProps;
};

const Button = ({ onClick, children, variant, icon, disabled, fill, sx }: Props) => {
    return (
        <MuiButton
            variant={variant}
            onClick={() => onClick && onClick()}
            disabled={disabled}
            endIcon={icon}
            sx={{ ...sx, ...{ width: fill ? '100%' : undefined } }}>
            {children}
        </MuiButton>
    );
};

export default Button;
