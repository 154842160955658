import React from 'react';
import Title from '../components/Title/Title';
import Group from '../components/Group/Group';
import EmailField from '../components/EmailField/EmailField';
import PhoneField from '../components/PhoneField/PhoneField';
import Description from '../components/Description/Description';
import useTranslation from '../hooks/useTranslation';
import useWizard from '../hooks/useWizard';
import useWizardValidation from '../hooks/useWizardValidation';

const ContactDetails = () => {
    const translate = useTranslation();
    const { setSection, handleStep } = useWizard();
    setSection(0, translate('page.how-to-apply.subtitle.about-you'), ['next']);
    useWizardValidation(['applicantEmailAddress', 'applicantMobileNumber']);

    return (
        <>
            <Title text={translate('page.contact-details.title')} />
            <Description text={translate('page.contact-details.description')} />
            <Group>
                <EmailField
                    name={'applicantEmailAddress'}
                    label={translate('field.applicant-email-address.label')}
                    required={true}
                />
                <PhoneField
                    name={'applicantMobileNumber'}
                    label={translate('field.applicant-mobile-number.label')}
                    required={true}
                />
            </Group>
        </>
    );
};

export default ContactDetails;
