import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

type Props = {
    children: ReactNode;
};

const VisibleOnMobile = ({ children }: Props) => {
    return <Box sx={{ display: { xs: 'block', sm: 'none' } }}>{children}</Box>;
};

export default VisibleOnMobile;
