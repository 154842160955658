import React, { useState, useEffect, useMemo } from 'react';
import { Dialog, Link, useMediaQuery, useTheme } from '@mui/material';
import { BottomModal } from '@resursbank/mui';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import useTranslation from '../../hooks/useTranslation';
import useSettings from '../../../hooks/useSettings';
import useDebounce from '../../hooks/useDebounce';
import { fetchPrice } from '../../../store/price/price';
import { RootState } from '../../../store';
import { IPriceData } from '../../../domain/types';
import { IFieldState } from '@resursbank/react-form-service';
import CalculatorContent from './CalculatorContent';
import MobileActions from './MobileActions';
import DesktopActions from './DesktopActions';
import styles from './Calculator.styles';

interface CalculatorProps {
    isMobileProp?: boolean;
}

export const selectFieldValueSelector = (state: RootState) => (fieldName: string) => {
    return (
        (state.form.formFieldStates.find((field: IFieldState) => field.fieldName === fieldName)?.value as string) || ''
    );
};

const Calculator: React.FC<CalculatorProps> = ({ isMobileProp }) => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const translate = useTranslation();
    const isMediumScreenOrLarger = useMediaQuery('(max-width:752px)');
    const isMobile = isMobileProp ?? isMediumScreenOrLarger;
    const dispatch = useAppDispatch();
    const { proxyBaseUrl, countryCode, apiKey, languageCode } = useSettings();
    const loanAmount = Number.parseInt(useAppSelector(selectFieldValueSelector)('amount') || '0');
    const tenorInMonths = Number.parseInt(useAppSelector(selectFieldValueSelector)('tenor') || '0');
    const counter = useAppSelector((state) => state.counter);
    const priceData = useAppSelector((state: RootState) => state.price.information) as IPriceData | undefined;
    const debouncedLoanAmount = useDebounce(loanAmount, 500);
    const debouncedTenorInMonths = useDebounce(tenorInMonths, 500);
    const [isBlocked, setIsBlocked] = useState(false);
    const [priceWarningMessage, setPriceWarningMessage] = useState<string | undefined>();

    useEffect(() => {
        if (open) {
            const payload = {
                apiProxyPath: proxyBaseUrl,
                countryCode,
                languageCode,
                partner: counter.partner,
                productType: counter.productType,
                loanAmount,
                currencyUnit: counter.currencyUnit,
                tenorInMonths,
                apiKey,
            };
            dispatch(fetchPrice(payload));
        }
    }, [debouncedLoanAmount, debouncedTenorInMonths, open]);

    useEffect(() => {
        const key = priceData?.message?.key;
        if (key) {
            if (key === 'block.low.amount.with.high.years') {
                return setPriceWarningMessage(translate('block.low.amount.with.high.years'));
            } else if (key === 'block.high.amount.with.low.years') {
                return setPriceWarningMessage(translate('block.high.amount.with.low.years'));
            } else if (key === 'low.amount.with.high.years') {
                return setPriceWarningMessage(translate('low.amount.with.high.years'));
            } else if (key === 'medium.amount.with.high.years') {
                return setPriceWarningMessage(translate('medium.amount.with.high.years'));
            } else if (key === 'high.amount.with.low.years') {
                return setPriceWarningMessage(translate('high.amount.with.low.years'));
            } else if (key === 'information.consolidation') {
                return setPriceWarningMessage(translate('information.consolidation'));
            }
        } else {
            setPriceWarningMessage(undefined);
        }
    }, [priceData]);

    useEffect(() => {
        setIsBlocked(!!priceData?.block);
    }, [priceData]);

    const tenorOptions = useMemo(() => {
        const result = [];
        for (let years = 1; years <= 15; years++) {
            result.push({
                label: years + ' ' + (years > 1 ? translate('years') : translate('year')),
                id: years * 12 + '',
            });
        }
        return result;
    }, [translate]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdate = () => {};

    if (isMobile) {
        return (
            <>
                <Link
                    component="button"
                    color="secondary"
                    underline="none"
                    onClick={handleOpen}
                    sx={{ fontWeight: 'bold' }}>
                    {translate('page.calculator.button.open')}
                </Link>
                <BottomModal
                    PaperProps={{
                        sx: {
                            '& .MuiBox-root': {
                                paddingLeft: '0',
                                paddingRight: '0',
                            },
                        },
                    }}
                    open={open}
                    onClose={handleClose}
                    disableRestoreFocus={true}>
                    <CalculatorContent
                        priceData={priceData}
                        loanAmount={loanAmount}
                        tenorOptions={tenorOptions}
                        priceWarningMessage={priceWarningMessage}
                    />
                    <MobileActions onClose={handleClose} onUpdate={handleUpdate} block={isBlocked} />
                </BottomModal>
            </>
        );
    }

    return (
        <>
            <Link
                component="button"
                color="secondary"
                underline="none"
                onClick={handleOpen}
                sx={{ fontWeight: 'bold' }}>
                {translate('page.calculator.button.open')}
            </Link>
            <Dialog
                sx={styles(theme).dialogContainer}
                PaperProps={{ sx: styles(theme).dialogPaper }}
                open={open}
                onClose={handleClose}
                disableRestoreFocus={true}>
                <CalculatorContent
                    priceData={priceData}
                    loanAmount={loanAmount}
                    tenorOptions={tenorOptions}
                    priceWarningMessage={priceWarningMessage}
                />
                <DesktopActions onClose={handleClose} onUpdate={handleUpdate} block={isBlocked} />
            </Dialog>
        </>
    );
};

export default Calculator;
