import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const CounterFieldStyles = styled('div')({
    marginBottom: '20px',
});

export const CounterLabel = styled('div')<{ labelcolor?: string }>(({ labelcolor }) => ({
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    fontFamily: "'Inter', sans-serif",
    color: labelcolor,
}));

export const CounterBox = styled(Box)({
    display: 'flex',
    marginTop: '15px',
});

export const CounterInput = styled('input')({
    width: '64px',
    height: '56px',
    textAlign: 'center',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
    fontSize: '17px',
    border: '1px solid #adb5bd',
    borderRadius: '4px',
    marginLeft: '10px',
    marginRight: '10px',
    boxSizing: 'border-box',
    appearance: 'textfield',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
    },
    '&:disabled': {
        color: '#adb5bd',
    },
    '&:focus': {
        outline: '1px solid #003c3c',
    },
});

export const CounterButton = styled(Button)({
    height: '56px',
    width: '56px',
    padding: '0 20px',
    background: '#ffffff',
    border: '1px solid #ced4da',
    borderRadius: '12px',
    textAlign: 'center',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 'bold',
    lineHeight: '48px',
    fontSize: '24px',
    color: '#003c3c',
    cursor: 'pointer',
    boxShadow: '4px 4px 10px 0px #f3f4f6, -4px -4px 10px 0px #ffffff inset',
    '&:hover': {
        color: '#fff',
    },
    '&:disabled': {
        color: '#adb5bd',
        cursor: 'default',
    },
});
