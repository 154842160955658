import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    RouterProvider,
    createBrowserRouter,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from 'react-router-dom';
import { ConsumerLoanTargetPath } from './domain/types';
import LogoutPage from './pages/LogoutPage/LogoutPage';
import Authenticator from './components/Authenticator/Authenticator';
import OngoingPage from './pages/OngoingPage/OngoingPage';
import ApplicationPage from './pages/ApplicationPage/ApplicationPage';
import { CalculatorPage } from './pages/CalculatorPage/CalculatorPage';
import RootPage from './pages/RootPage/RootPage';
import { persistStore } from 'redux-persist';
import { store } from './store';
import { Provider } from 'react-redux';
import InitPage from './pages/InitPage/InitPage';
import ApplyPage from './pages/ApplyPage/ApplyPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import ConsentPage from './pages/ConsentPage/ConsentPage';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import CallbackPage from './pages/CallbackPage/CallbackPage';
import 'rc-slider/assets/index.css';
import './styles/global.css';
import * as Sentry from '@sentry/react';
import { LdapLoginPage } from './pages/LdapLoginPage/LdapLoginPage';
import Switcharoo from './v2/components/Switcharoo/Switcharoo';
import ApplicationPageV2 from './v2/pages/ApplicationPage';
import NorwegianStyleWrapper from './components/NorwegianStyleWrapper/NorwegianStyleWrapper';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        environment: process.env.NODE_ENV,
        dsn:
            process.env.NODE_ENV === 'production'
                ? 'https://cc4b2bcc9a723e8bfc5713c83221ec43@o566388.ingest.us.sentry.io/4507300094083072'
                : '',
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
        ],

        tracesSampleRate: 1.0,
    });
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
    {
        element: <RootPage />,
        children: [
            {
                path: '/*',
                element: (
                    <NorwegianStyleWrapper>
                        <NotFoundPage />
                    </NorwegianStyleWrapper>
                ),
            },
            {
                path: ConsumerLoanTargetPath.calculator,
                element: (
                    <NorwegianStyleWrapper>
                        <CalculatorPage />
                    </NorwegianStyleWrapper>
                ),
            },
            {
                path: ConsumerLoanTargetPath.init,
                element: (
                    <NorwegianStyleWrapper>
                        <InitPage />
                    </NorwegianStyleWrapper>
                ),
            },
            {
                path: ConsumerLoanTargetPath.application,
                element: (
                    <Switcharoo
                        legacyForm={
                            <NorwegianStyleWrapper>
                                <ApplicationPage />
                            </NorwegianStyleWrapper>
                        }
                        newForm={<ApplicationPageV2 />}
                    />
                ),
            },
            {
                path: ConsumerLoanTargetPath.apply,
                element: (
                    <NorwegianStyleWrapper>
                        <Authenticator autoLogin={true} fetchLoginForm={false}>
                            <ApplyPage />
                        </Authenticator>
                    </NorwegianStyleWrapper>
                ),
            },
            {
                path: ConsumerLoanTargetPath.ongoing,
                element: (
                    <NorwegianStyleWrapper>
                        <Authenticator autoLogin={false} fetchLoginForm={true}>
                            <OngoingPage />
                        </Authenticator>
                    </NorwegianStyleWrapper>
                ),
            },
            {
                path: ConsumerLoanTargetPath.callback,
                element: (
                    <NorwegianStyleWrapper>
                        <Authenticator autoLogin={true} fetchLoginForm={false}>
                            <CallbackPage />
                        </Authenticator>
                    </NorwegianStyleWrapper>
                ),
            },
            {
                path: ConsumerLoanTargetPath.consent,
                element: (
                    <NorwegianStyleWrapper>
                        <ConsentPage />
                    </NorwegianStyleWrapper>
                ),
            },
            {
                path: ConsumerLoanTargetPath.logout,
                element: (
                    <NorwegianStyleWrapper>
                        <LogoutPage />
                    </NorwegianStyleWrapper>
                ),
            },
            {
                path: ConsumerLoanTargetPath.error,
                element: (
                    <NorwegianStyleWrapper>
                        <ErrorPage />
                    </NorwegianStyleWrapper>
                ),
            },
            {
                path: ConsumerLoanTargetPath.ldapLogin,
                element: (
                    <NorwegianStyleWrapper>
                        <LdapLoginPage />
                    </NorwegianStyleWrapper>
                ),
            },
        ],
    },
]);

export const persistedStore = persistStore(store);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>,
);
