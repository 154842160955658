import { createSlice } from '@reduxjs/toolkit';
import { ICounterState } from './counterTypes';

export const initialCounterState = {
    partner: 'RESURS',
    productType: 'UL',
    currencyUnit: 'NOK',
    amountStep: 500,
    minAmount: 1500,
    maxAmount: 1000000,
    minTenorYears: 1,
    maxTenorYears: 10,
} as ICounterState;

const counterSlice = createSlice({
    name: 'counter',
    initialState: initialCounterState,
    reducers: {},
});

export default counterSlice.reducer;
