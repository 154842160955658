import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
    font-family: 'Inter', sans-serif;
    background: url('/bg.jpg') no-repeat center center fixed;
    background-size: cover;
    position: relative;
    margin: 0;
    @media only screen and (max-width: 752px) {
      background: white;
    }
  }
`;
