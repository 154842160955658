import React, { ReactElement, ReactNode, useState } from 'react';
import useFieldFormatting, { FieldFormattingProps } from '../../hooks/useFieldFormatting';
import useFieldValidation, { FieldValidationProps, ValidationResultType } from '../../hooks/useFieldValidation';
import { useAppDispatch } from '../../../hooks';
import { IFieldState, addOrUpdateFormField } from '@resursbank/react-form-service';
import { ChangeEvent } from '@resursbank/react-form-service/dist/components';
import { SelectFieldOption } from '@resursbank/mui/components/SelectField/SelectFieldOption';

type InputFieldProps = {
    name: any;
    children: ReactNode;
    /**
     * Validation properties
     */
    validation?: FieldValidationProps;
    /**
     * Formatting properties
     */
    formatting?: FieldFormattingProps;
    /**
     * When the text is changed this function will be invoked after the value has been updated in the store.
     */
    postOnChange?: (event: ChangeEvent) => void;
    preOnChange?: (event: ChangeEvent) => void;
    isFromCounterField?: boolean; // New prop to check the source
    radioFieldoOptions?: SelectFieldOption[];
};

const InputField = (props: InputFieldProps) => {
    const dispatch = useAppDispatch();
    const { validate } = useFieldValidation();
    const { initialValue, formatValue, cleanValue } = useFieldFormatting(props.name, props.formatting);
    const [fieldValue, setFieldValue] = useState(formatValue(initialValue()));

    const onChangeProxy = (event: ChangeEvent) => {
        if (props?.preOnChange) {
            props.preOnChange(event);
        }

        // Clean the input value
        let value = cleanValue(event.target.value);
        // Convert to number
        const numericValue = Number(value);
        //find right option for the chosen value to get the label
        const option = props.radioFieldoOptions?.find((field) => field.id === value);

        // Apply auto-correction logic only if it's from CounterField
        if (props.isFromCounterField) {
            // Auto-correct the value within the range of 0 to 20
            if (numericValue < 0) {
                value = '0';
            } else if (numericValue > 20) {
                value = '20';
            }
        }

        (props.validation
            ? validate(props.name, value, props.validation)
            : Promise.resolve({ valid: true } as ValidationResultType)
        ).then((validationResult) =>
            dispatch(
                addOrUpdateFormField({
                    fieldName: props.name,
                    label: option?.label,
                    value,
                    hasError: !validationResult.valid,
                    errorMessage: validationResult.errorMessageKey,
                } as IFieldState),
            ),
        );

        // Set the corrected value
        setFieldValue(formatValue(value));
        if (props?.postOnChange) {
            props.postOnChange(event);
        }
    };

    return React.Children.map(props.children, (child) => {
        if (!React.isValidElement(child)) {
            return <>Element {child} cannot be cloned</>;
        }

        return React.cloneElement(child as ReactElement, { value: fieldValue, onChange: onChangeProxy }, null);
    });
};

export default InputField;
