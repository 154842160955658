import { SxProps } from '@mui/material';
import styled from 'styled-components';

export const Card = styled.div`
    margin: 32px 0 16px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 32px 16px;
    border-radius: 12px;
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px;
    align-items: flex-start;
    min-width: 500px;
    @media only screen and (max-width: 600px) {
        min-width: 100%;
    }
`;

export const InfoCard = styled.div<{ marginBottom?: number; open: boolean; padding: boolean }>`
    margin: 32px 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: ${({ padding }) => (padding ? '32px 16px' : '0')};
    border-radius: 12px;
    position: relative;
    background-color: #f5fafa;
    overflow: hidden;
    max-height: ${({ open }) => (open ? '100vh' : '60px')};
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
`;

export const FoldedCalculator = styled.div<{ open: boolean }>`
    display: ${({ open }) => (open ? 'none' : 'flex')};
    gap: 10px;
    align-items: center;
`;

export const SliderStyles = styled.div`
    .rc-slider-with-marks {
        margin: 20px 0 20px 0;

        .rc-slider-rail {
            width: 100%;
            height: 5px;
            background-color: #adb5bd;
        }

        .rc-slider-track {
            height: 5px;
            background-color: #00807b;
        }

        .rc-slider-handle {
            opacity: 1;
            margin-top: -12px;
            width: 32px;
            height: 32px;
            border: none;
            background-color: white;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
            &:focus {
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
            }
        }

        .rc-slider-mark {
            position: unset;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                span {
                    transform: none !important;
                    left: auto !important;
                }
            }
        }

        .rc-slider-mark-text {
            display: none;
        }

        .rc-slider-dot {
            display: none;
        }
    }
`;

export const ArrowBox = styled.div`
    font-size: 30px;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
`;

export const GridBoxStyles: SxProps = {
    height: '58px',
    lineHeight: '3.5',
    color: '#212529',
};

export const LoanAmountGridStyles: SxProps = {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '58px',
};

export const GridStyles: SxProps = {
    margin: '15px 0 15px 0',
};

export const TenorGridStyles: SxProps = {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '58px',
    '& .MuiFormControl-fullWidth': {
        maxWidth: '200px',
    },
};

export const MonthlyAmountGridStyles: SxProps = {
    display: 'flex',
    justifyContent: 'flex-end',
};

export const MonthlyAmountStyles: SxProps = {
    fontSize: '16px',
    lineHeight: '56px',
    fontWeight: '700',
};

export const TypographyStyles: SxProps = {
    fontSize: '16px',
    lineHeight: '56px',
};
