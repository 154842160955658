import React from 'react';
import Title from '../components/Title/Title';
import Group from '../components/Group/Group';
import Description from '../components/Description/Description';
import useTranslation from '../hooks/useTranslation';
import MonetaryField from '../components/MonetaryField/MonetaryField';
import ShowDetailsModal from '../components/ShowDetailsModal/ShowDetailsModal';
import useWizard from '../hooks/useWizard';
import useWizardValidation from '../hooks/useWizardValidation';

const FinancePage = () => {
    const translate = useTranslation();
    const { setSection } = useWizard();
    setSection(0, translate('page.how-to-apply.subtitle.about-you'), ['next']);
    useWizardValidation(['applicantYearlyGrossIncome', 'applicantMortgageMonthlyCost', 'applicantOtherLoans']);

    return (
        <>
            <Title text={translate('page.finance.title')} />
            <Description text={translate('page.finance.description')} />
            <ShowDetailsModal
                label={translate('dialog.dont-understand.label')}
                dialogTitle={translate('dialog.dont-understand.label')}
                dialogContent={translate('page.finance.dialog.content')}
            />
            <Group>
                <MonetaryField
                    name="applicantYearlyGrossIncome"
                    label={translate('field.applicant-yearly-gross-income.label')}
                />
                <MonetaryField
                    name="applicantMortgageMonthlyCost"
                    label={translate('field.applicant-mortage-monthly-cost.label')}
                />
                <MonetaryField
                    name="applicantOtherLoans"
                    label={translate('field.applicant-other-loans-monthly-cost.label')}
                />
            </Group>
        </>
    );
};

export default FinancePage;
