import { AuthenticationData } from '@resursbank/react-form-service';
import { config } from '../helpers/apiConfig';
import * as Sentry from '@sentry/react';

export const ongoingApplicationUrlService = async (
    apiKey: string,
    apiProxyPath: string,
    authenticationData?: AuthenticationData,
): Promise<string> => {
    const url = `${apiProxyPath}/application/ongoing_application_url`;
    const request = {
        method: 'GET',
        headers: config(apiKey, authenticationData),
    };
    try {
        const response = await fetch(url, request);

        if (!response.ok) {
            const error = await response.text();
            if (process.env.NODE_ENV === 'production') {
                Sentry.captureException(new Error(`Get url service failed: ${JSON.stringify(error)}`));
            }
            throw new Error(error);
        }
        return response.text();
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(
                new Error(`Get url service failed: ${error instanceof Error ? error.message : JSON.stringify(error)}`),
            );
        }
        throw error;
    }
};
