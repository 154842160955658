import { SxProps } from '@mui/material';

export const TextFieldStyles: SxProps = {
    color: '#000000',
    backgroundColor: '#FFFFFF',
    border: '1px solid #ADB5BD',
    height: '54px',
    maxWidth: '200px',
    '& .MuiInput-input': {
        textAlign: 'right',
        padding: '8px !important',
    },
};
