import React from 'react';
import { useWizard } from 'react-use-wizard';
import useTranslation from '../../hooks/useTranslation';
import { Box, Link } from '@mui/material';
import { FontIcon } from '@resursbank/mui';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import Calculator from '../Calculator/Calculator';
import styles from './WizardHeader.styles';

const WizardHeader = () => {
    const { previousStep, activeStep } = useWizard();
    const translate = useTranslation();

    return (
        activeStep !== 0 && (
            <Box sx={styles.wizardHeaderBox}>
                <Link
                    component="button"
                    color="secondary"
                    underline="none"
                    onClick={previousStep}
                    sx={{
                        fontWeight: 'bold',
                    }}>
                    <FontIcon icon={faChevronLeft} size="xs" /> {translate('page.action.back')}
                </Link>
                <Calculator />
            </Box>
        )
    );
};

export default WizardHeader;
