import React from 'react';
import { Box, DialogTitle, DialogContent, DialogContentText, useTheme } from '@mui/material';
import MonetaryField from '../MonetaryField/MonetaryField';
import SelectField from '../SelectField/SelectField';
import PriceInformation from './PriceInformation';
import styles from './Calculator.styles';
import useTranslation from '../../hooks/useTranslation';
import { IPriceData } from '../../../domain/types';

interface CalculatorContentProps {
    priceData: IPriceData | undefined;
    tenorOptions: { label: string; id: string }[];
    loanAmount: number;
    priceWarningMessage: any;
}

const CalculatorContent: React.FC<CalculatorContentProps> = ({
    priceData,
    tenorOptions,
    loanAmount,
    priceWarningMessage,
}) => {
    const theme = useTheme();
    const translate = useTranslation();

    return (
        <Box sx={styles(theme).calculatorBox}>
            <DialogTitle sx={styles(theme).dialogTitle}>{translate('page.calculator.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={styles(theme).dialogContentText}>
                    {translate('page.calculator.description')}
                </DialogContentText>
                <Box sx={{ padding: '8px 0' }} />
                <MonetaryField name="amount" label={translate('field.amount.label')} required={true} />
                <Box sx={{ padding: '8px 0' }} />
                <SelectField options={tenorOptions} name="tenor" label={translate('field.tenor.label')} />
                <Box sx={{ padding: '8px 0' }} />
                {priceWarningMessage && <Box sx={styles(theme).warningMessage}>{priceWarningMessage}</Box>}
                <PriceInformation priceData={priceData} loanAmount={loanAmount} />
            </DialogContent>
        </Box>
    );
};

export default CalculatorContent;
