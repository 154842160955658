import React, { ReactElement, ReactNode } from 'react';
import Box from '@mui/system/Box';
import ValidationError from '../ValidationError/ValidationError';

type GroupProps = {
    children: ReactNode;
};

const Group = ({ children }: GroupProps) => {
    const array = React.Children.toArray(children);
    const childLength = array.length;
    const fieldNames = array.map((child) => (child as any).props['name']).filter((name) => !!name);

    const childrenWithProps = React.Children.map(children, (child, index) => {
        let outline: string;

        if (React.isValidElement(child)) {
        }
        if (childLength < 2) {
            outline = 'single';
        } else if (index === 0) {
            outline = 'top';
        } else if (index === childLength - 1) {
            outline = 'bottom';
        } else {
            outline = 'middle';
        }

        return React.cloneElement(child as ReactElement, { outline }, null);
    });

    return (
        <Box style={{ margin: '10px 0' }}>
            {childrenWithProps}
            {fieldNames.map((fieldName) => (
                <ValidationError key={fieldName} fieldName={fieldName} />
            ))}
        </Box>
    );
};

export default Group;
