import React, { useRef } from 'react';
import { CounterBox, CounterButton, CounterInput } from './CounterField.styles';
import { FieldValidationProps } from '../../hooks/useFieldValidation';
import InputField from '../InputField/InputField';
import { useAppSelector } from '../../../hooks';
import { selectFieldStateByName } from '../../../store/applicationform/applicationFormSelectors';
import { Box } from '@mui/material';

type CounterFieldProps = {
    name: string;
    validation?: FieldValidationProps;
};

export default function CounterField(props: CounterFieldProps) {
    const fieldValue = Number(useAppSelector(selectFieldStateByName)(props.name)?.value ?? 0);
    const step = 1;
    const maxValue = 20;
    const minValue = 0;
    const inputRef = useRef<HTMLInputElement | null>(null);

    const triggerChangeEvent = (newValue: number) => {
        if (inputRef.current) {
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')
                ?.set;
            nativeInputValueSetter?.call(inputRef.current, newValue.toString());

            const event = new Event('input', { bubbles: true });
            inputRef.current.dispatchEvent(event);
        }
    };

    const increase = () => {
        if (fieldValue < maxValue) {
            const newValue = fieldValue + step;
            triggerChangeEvent(newValue);
        }
    };

    const decrease = () => {
        if (fieldValue > minValue) {
            const newValue = fieldValue - step;
            triggerChangeEvent(newValue);
        }
    };

    return (
        <CounterBox>
            <Box>
                <CounterButton type="button" onClick={decrease}>
                    -
                </CounterButton>
            </Box>
            <Box>
                <InputField {...props} isFromCounterField={true}>
                    <CounterInput ref={inputRef} type="number" min={minValue} max={maxValue} {...props} />
                </InputField>
            </Box>
            <Box>
                <CounterButton type="button" onClick={increase}>
                    +
                </CounterButton>
            </Box>
        </CounterBox>
    );
}
