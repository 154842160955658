import React from 'react';
import { IFieldState } from '@resursbank/react-form-service';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../hooks';
import { useGTMPageViewEvent } from '../hooks/useGTMPageViewEvent';
import { RootState } from '../store';

const GTMPageView: React.FC = () => {
    const location = useLocation();
    const formFieldStates = useAppSelector((state: RootState) => state.form.formFieldStates);
    const { currentPage } = useAppSelector((state) => state.form);

    const getLastField = (fieldStates: IFieldState[]): IFieldState | undefined => {
        return fieldStates[fieldStates.length - 1];
    };

    const lastField = getLastField(formFieldStates);
    const lastFieldName = lastField?.fieldName || '';
    const lastFieldPageNumber = lastField?.editReference?.pageNumber || 0;

    useGTMPageViewEvent(location.pathname, lastFieldName, currentPage, lastFieldPageNumber);

    return null;
};

export default GTMPageView;
