import React, { useContext, useEffect, useState } from 'react';
import {
    FormData,
    goToPage,
    ErrorMessage,
    PARAM_STATE,
    PARAM_ERROR,
    selectFieldValue,
    ReactFormServiceContext,
    selectValidAccessToken,
} from '@resursbank/react-form-service';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useSearchParams } from 'react-router-dom';
import { getFormPageService } from '../../api/applicationForm';
import { ConsumerLoanTargetPath, GetFormPageRequest } from '../../domain/types';
import { prepareForReSubmit } from '../../store/application/application';
import { Box } from '@mui/material';
import { sendGTMPageStepEvent } from '../../helpers/gtm';
import { useNavigate } from 'react-router-dom';
import { getPath } from '../../helpers/url';
import { getEnvironmentConfig } from '../../config';
import useConsentAuthentication from '../../hooks/useConsentAuthentication';
import OngoingApplicationDialog from '../../components/OngoingApplicationDialog/OngoingApplicationDialog';
import { ongoingApplicationUrlService } from '../../api/ongoingApplicationUrl';
import useSettings from '../../hooks/useSettings';
import useGoToInitPage from '../../hooks/useGoToInitPage';

const ApplicationPage = () => {
    const dispatch = useAppDispatch();
    const [params, setParams] = useSearchParams();
    const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
    const { currentPage: currentPageNumber, totalPages } = useAppSelector((state) => state.form);
    const { applicationId, submitErrorOccurred, channel } = useAppSelector((state) => state.application);
    const governmentId = useAppSelector(selectFieldValue)('applicantGovernmentId');
    const settings = useSettings();
    const counter = useAppSelector((state) => state.counter);
    const navigate = useNavigate();
    const { translate } = useContext(ReactFormServiceContext);
    const config = getEnvironmentConfig();
    const [applicationUrl, setApplicationUrl] = useState<string | undefined>(undefined);
    const accessToken = useAppSelector(selectValidAccessToken);
    const { authenticationData } = useAppSelector((state) => state.authentication);
    const [isReturningFromLastPage, setIsReturningFromLastPage] = useState<boolean>(false);
    const goToInitPage = useGoToInitPage();
    useConsentAuthentication();

    const dispatchGoToPage = (page: number, action: string) => {
        dispatch(
            goToPage({
                service: getFormPageService,
                payload: {
                    page,
                    version: config.applyFormVersion,
                    governmentId,
                    applicationId,
                    languageCode: settings.languageCode,
                    countryCode: settings.countryCode,
                    channel,
                } as GetFormPageRequest,
                action,
            }),
        )
            .unwrap()
            .then(() => window.scrollTo({ top: 0 }))
            .catch(() => navigate(getPath(ConsumerLoanTargetPath.error, settings.languageCode, settings.countryCode)));
        sendGTMPageStepEvent(counter.productType, currentPageNumber, applicationId);
    };

    const onSelectPage = (page: number, action: string) => {
        setErrorOccurred(false);
        if (page <= totalPages) {
            dispatchGoToPage(page, action);
        } else {
            navigate(getPath(ConsumerLoanTargetPath.apply, settings.languageCode, settings.countryCode));
        }
    };

    useEffect(() => {
        if (errorOccurred) {
            params.delete(PARAM_ERROR);
            params.delete(PARAM_STATE);
            setParams(params);
        }
    }, [errorOccurred, params, setParams]);

    useEffect(() => {
        if (submitErrorOccurred && !errorOccurred) {
            setErrorOccurred(true);
            dispatch(prepareForReSubmit());
        }
    }, []);

    // This effect will reload the form by posting the known values to the backend again
    useEffect(() => {
        goToInitPage(currentPageNumber, '').catch(() =>
            navigate(getPath(ConsumerLoanTargetPath.error, settings.languageCode, settings.countryCode)),
        );
    }, [config.applyFormVersion]);

    const errorElement = () => {
        if (!errorOccurred) {
            return undefined;
        }
        return (
            <Box paddingBottom={4}>
                <ErrorMessage
                    title={translate(`page.submit.error.title`)}
                    description={translate(`page.submit.error.description`)}
                />
            </Box>
        );
    };

    useEffect(() => {
        if (accessToken && authenticationData?.scope !== 'LDAP') {
            const getUrl = async () => {
                let url = await ongoingApplicationUrlService(
                    settings.apiKey,
                    settings.proxyBaseUrl,
                    authenticationData,
                );
                url = url.trim().replace(/^"|"$/g, '');
                setApplicationUrl(url);
            };
            getUrl();
        }
    }, [accessToken, settings]);

    const handleNextPage = () => {
        if (currentPageNumber === totalPages) {
            onSelectPage(currentPageNumber + 1, 'next');
        }

        if (isReturningFromLastPage) {
            onSelectPage(totalPages, 'next');
        } else {
            if (currentPageNumber === totalPages - 1) {
                setIsReturningFromLastPage(true);
            }
            if (currentPageNumber < totalPages) {
                onSelectPage(currentPageNumber + 1, 'next');
            }
        }
    };

    return (
        <>
            {currentPageNumber <= totalPages ? (
                <FormData
                    isApplicationLoading={false}
                    onNextPage={handleNextPage}
                    onPreviousPage={() => onSelectPage(currentPageNumber - 1, 'previous')}
                    applicationError={undefined}
                    ErrorElement={errorElement()}
                    showPreviousButton={!isReturningFromLastPage}
                />
            ) : null}
            <OngoingApplicationDialog url={applicationUrl} />
        </>
    );
};

export default ApplicationPage;
