import { ConsumerLoanInitPayload } from '../domain/types/ConsumerLoanInitPayload';
import * as Sentry from '@sentry/react';

export const encryptInitPayloadService = async (
    apiProxyPath: string,
    apiKey: string,
    initPayload: ConsumerLoanInitPayload,
): Promise<string> => {
    const url = `${apiProxyPath}/encrypt`;
    const request = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            apikey: apiKey,
        },
        body: JSON.stringify(initPayload),
    };

    try {
        const response = await fetch(url, request);
        if (!response.ok) {
            const error = await response.json();
            if (process.env.NODE_ENV === 'production') {
                Sentry.captureException(new Error(`Enrcypt payload service service failed: ${JSON.stringify(error)}`));
            }
            throw new Error(error);
        }
        return await response.text();
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(
                new Error(
                    `Enrcypt payload service service failed: ${
                        error instanceof Error ? error.message : JSON.stringify(error)
                    }`,
                ),
            );
        }
        throw error;
    }
};

export const decryptInitPayloadService = async (
    apiProxyPath: string,
    apiKey: string,
    encryptedApplicationState: string,
): Promise<ConsumerLoanInitPayload> => {
    const url = `${apiProxyPath}/decrypt`;
    const request = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            apikey: apiKey,
        },
        body: encryptedApplicationState,
    };

    try {
        const response = await fetch(url, request);
        if (!response.ok) {
            const error = await response.json();
            if (process.env.NODE_ENV === 'production') {
                Sentry.captureException(new Error(`Decrypt payload service failed: ${JSON.stringify(error)}`));
            }
            throw new Error(error);
        }
        const data = await response.json();
        return data as ConsumerLoanInitPayload;
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(
                new Error(
                    `Decrypt payload service failed: ${error instanceof Error ? error.message : JSON.stringify(error)}`,
                ),
            );
        }
        throw error;
    }
};
