import * as Sentry from '@sentry/react';

export const validateGovernmentIdService = async (apiProxyPath: string, governmentId: any) => {
    const url = `${apiProxyPath}/validation/government_id`;
    const request = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataValidation(governmentId)),
    };
    try {
        const response = await fetch(url, request);

        if (!response.ok) {
            const error = await response.json();
            if (process.env.NODE_ENV === 'production') {
                Sentry.captureException(new Error(`Validate government id service failed: ${JSON.stringify(error)}`));
            }
            throw new Error(error);
        }
        const responseData = await response.json();

        return responseData.status === 'VALID';
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(
                new Error(
                    `Validate government id service failed: ${
                        error instanceof Error ? error.message : JSON.stringify(error)
                    }`,
                ),
            );
        }
        throw error;
    }
};

const dataValidation = (governmentId: any) => {
    return {
        governmentId,
    };
};
