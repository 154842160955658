import React, { useEffect } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { StatusProcessingPage, useAuthentication } from '@resursbank/react-form-service';
import { useAppSelector } from '../../hooks';
import { ConsumerLoanParameter } from '../../domain/types/ConsumerLoanParameter';
import { getPath } from '../../helpers/url';
import { ConsumerLoanTargetPath } from '../../domain/types';
import { afterSignApplication as triggerNextStateAfterSigning } from '../../api/application';
import useSettings from '../../hooks/useSettings';

const CallbackPage = () => {
    const { languageCode, countryCode, apiKey, proxyBaseUrl } = useSettings();
    useAuthentication();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        const callbackStatus = searchParams.get(ConsumerLoanParameter.callbackStatus);
        const applicationReferenceNumber = params[ConsumerLoanParameter.applicationReferenceNumber] ?? '';

        if (!applicationReferenceNumber) {
            return;
        }

        if (callbackStatus === 'success') {
            triggerNextStateAfterSigning({
                countryCode: countryCode,
                languageCode: languageCode,
                apiKey: apiKey,
                proxyBaseUrl: proxyBaseUrl,
                formFieldStates: [],
                applicationReferenceNumber,
            }).then(() => {
                navigate(
                    getPath(ConsumerLoanTargetPath.ongoing, languageCode, countryCode, applicationReferenceNumber),
                );
            });
        } else {
            navigate(
                getPath(
                    ConsumerLoanTargetPath.ongoing,
                    languageCode,
                    countryCode,
                    applicationReferenceNumber ?? undefined,
                ),
            );
        }
    }, [params, searchParams]);

    return <StatusProcessingPage title={''} body={''} />;
};

export default CallbackPage;
