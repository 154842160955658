import { IFieldState } from '@resursbank/react-form-service';
import { ConsumerLoanTargetPath } from '../domain/types';
import { getPath } from '../helpers/url';

export const dataFormPage = (values: any, version: string | undefined | null, formFieldsStates?: IFieldState[]) => {
    formFieldsStates?.forEach((fieldState) => {
        const name = fieldState.fieldName;
        const value = fieldState.value;
        if (value) {
            if (values[name] && values[name].split) {
                // Do not add duplicate values
                if (values[name].split(';').indexOf(value) === -1) {
                    values[name] = `${values[name]};${value}`;
                }
            } else {
                values[name] = value;
            }
        }
    });

    return {
        version,
        values,
    };
};

export const callbackUrl = (
    target: ConsumerLoanTargetPath,
    language: string,
    countryCode: string,
    applicationReferenceNumber: string,
) => {
    const callbackUrl = window.location.origin;
    return `${callbackUrl}${getPath(target, language, countryCode, applicationReferenceNumber)}`;
};
