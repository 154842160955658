import React, { useEffect } from 'react';
import { StatusProcessingPage, useAuthentication } from '@resursbank/react-form-service';
import { submitApplication } from '../../store/application/application';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ConsumerLoanTargetPath } from '../../domain/types';
import { sendGTMSubmittedLoan } from '../../helpers/gtm';
import { selectFieldValueSelector } from '../../components/LoanInformation/LoanInformation';
import { useNavigate } from 'react-router-dom';
import { getPath } from '../../helpers/url';
import useSettings from '../../hooks/useSettings';

const ApplyPage = () => {
    const dispatch = useAppDispatch();
    const { applicationId, submitted } = useAppSelector((state) => state.application);
    const { languageCode, countryCode } = useSettings();
    const token = useAuthentication();
    const loanAmount = Number.parseInt(useAppSelector(selectFieldValueSelector)('amount'));
    const counter = useAppSelector((state) => state.counter);
    const navigate = useNavigate();

    useEffect(() => {
        if (!submitted && token) {
            dispatch(submitApplication())
                .unwrap()
                .then((response) => {
                    navigate(
                        getPath(
                            ConsumerLoanTargetPath.ongoing,
                            languageCode,
                            countryCode,
                            response.applicationReferenceNumber,
                        ),
                    );
                })
                .catch((error) => {
                    navigate(getPath(ConsumerLoanTargetPath.application, languageCode, countryCode));
                    return error;
                })
                .finally(() => {
                    sendGTMSubmittedLoan(counter.productType, loanAmount, applicationId);
                });
        }
    }, [submitted, token]);

    return <StatusProcessingPage title={''} body={''} />;
};

export default ApplyPage;
