import { SxProps, Theme } from '@mui/material';

const styles = (theme: Theme): { [key: string]: SxProps } => ({
    container: {},
    header: {
        fontWeight: 'bold',
        marginBottom: '8px',
        fontSize: '14px',
        lineHeight: '24px',
    },
    content: {
        marginBottom: '4px',
        lineHeight: '16px',
        fontSize: '12px',
    },
    monthlyCostContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '8px',
    },
    monthlyCostTitle: {
        fontSize: '16px',
        lineHeight: '24px',
    },
    monthlyCost: {
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
});

export default styles;
